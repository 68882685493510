import { useState } from "react";
import Form from "../Form";
//Update user
import { useAppSelector } from "../../../app/hooks";
import { updateUser } from "../../../features/user/userSlice";
//Delete user
import { deleteUser } from "../../../features/user/userSlice";
import { UserType } from "../../../features/user/userSlice";

const UserForm = (props: { id: string | undefined; data: UserType }) => {
  const { user, isLoading } = useAppSelector((state) => state.user);
  const [formData, setFormData] = useState();

  const loading = isLoading || user.id === 0;

  if (loading) {
    return <div>Loading...</div>;
  }

  const { id, data } = props;

  return (
    <Form
      updateButtonAction={updateUser}
      deleteButtonAction={deleteUser}
      updateButtonLabel="Update user"
      deleteButtonLabel="Delete user"
      buttonWidth="40%"
      buttonDisabled={loading}
      onDataChanged={setFormData}
      id={id}
      data={data}
      structure={{
        label: "Contract",
        items: [
          {
            required: true,
            type: "text",
            name: "name",
            label: "Name",
            defaultValue: "",
            css: "w-full md:w-1/1 md:mb-4",
          },

          {
            required: true,
            type: "number",
            name: "remaining_reports",
            label: "Remaining reports",
            defaultValue: 0,
            css: "w-full md:w-1/2 md:mb-4",
          },
          {
            required: true,
            type: "number",
            name: "prompt_credit",
            label: "Prompt credits",
            defaultValue: 0,
            css: "w-full md:w-1/2 md:mb-4",
          },
          {
            type: "selector",
            name: "is_business",
            label: "Is business",
            items: [
              {
                label: "Yes",
                value: true,
              },
              {
                label: "No",
                value: false,
              },
            ],
            css: "w-full md:w-1/2 md:mb-4",
          },
          {
            type: "selector",
            name: "is_affiliate",
            label: "Is affilliate",
            items: [
              {
                label: "Yes",
                value: true,
              },
              {
                label: "No",
                value: false,
              },
            ],
            css: "w-full md:w-1/2 md:mb-4",
          },
          {
            type: "selector",
            name: "is_active",
            label: "Is active",
            items: [
              {
                label: "Yes",
                value: true,
              },
              {
                label: "No",
                value: false,
              },
            ],
            css: "w-full md:w-1/2 md:mb-4",
          },
          {
            required: false,
            type: "text",
            name: "phone_number",
            label: "Phone number",
            defaultValue: "",
            css: "w-full md:w-1/2 md:mb-4",
          },
        ],
      }}
    />
  );
};

export default UserForm;
