import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "../Button";

export default function Form(props: any) {
  const dispatch = useAppDispatch();

  // const history = useHistory();

  const [helperText, setHelperText] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  const { structure, noShadow, onDataChanged } = props;

  const registeredName = {};

  function getValue(obj: any, path: string): any {
    return path.split(".").reduce((o, i) => o && o[i], obj);
  }

  type Structure = {
    name: string;
    type?: string | undefined;
    defaultValue?: any;
    items: {
      required?: boolean | undefined;
      type: string;
      name: string;
      label: string;
      defaultValue?: any;
      items?: Structure | undefined;
      value?: any;
    }[];
  };

  type State = {
    [key: string]: any;
  };

  function structureToFormData(
    structure:
      | Structure
      | {
          required?: boolean | undefined;
          type: string;
          name: string;
          label: string;
          defaultValue?: any;
          items?: Structure | undefined;
          value?: any;
        },
    parent?: string | undefined,
    state: State = {},
    registeredName: { [key: string]: boolean } = {}
  ): State {
    if (structure.items && Array.isArray(structure.items)) {
      structure.items.forEach((item) => {
        const name = parent ? `${parent}.${item.name}` : item.name;
        if (registeredName[name]) {
          throw new Error(
            "There exists a duplicated name value used in Form. Check the values passed to Form and ensure each name property is unique"
          );
        }
        registeredName[name] = true;
        if (item.type === "object" && item.items) {
          structureToFormData(item, name, state, registeredName);
        } else {
          if (props.data) {
            state[name] = getValue(props.data, name);
          } else {
            state[name] =
              typeof item.defaultValue !== "undefined" ? item.defaultValue : "";
          }
        }
      });
    }
    return state;
  }

  const initialState = structureToFormData(structure);

  const [data, setData] = useState(initialState);

  type OnDataChangedFn = (data: Data) => void;

  function setDataAndNotifyOnDataChanged(data: Data) {
    setData(data);
    if (onDataChanged) {
      onDataChanged(cleanData(data));
    }
  }

  useEffect(() => {
    setDataAndNotifyOnDataChanged(data);
  }, [props.data]);

  const [disabled, setDisabled] = useState(props.data ? true : false);

  function parseInteger(event: { target: { name: string; value: any } }): any {
    const { name, value } = event.target;
    if (!value) {
      return value;
    } else {
      try {
        const parseValue = parseInt(value || 0);
        return parseValue;
      } catch (err) {
        console.error(err);
        return value;
      }
    }
  }

  function parsePercent(event: { target: { name: string; value: any } }): any {
    const { name, value } = event.target;
    if (!value) {
      return value;
    } else {
      try {
        const parseValue = parseFloat(value || 0);
        if (parseValue > 100) {
          return 1;
        } else if (parseValue < 0) {
          return 0;
        } else {
          return parseValue / 100;
        }
      } catch (err) {
        console.error(err);
        return;
      }
    }
  }

  type OnChangeFn = (value: any, name: string, data: Data) => any;
  type ParserFn = (event: React.ChangeEvent<HTMLInputElement>) => any;

  //Handle change for all input types and store the value in state (data) with the name as the key and the value as the value
  function handleInputChange(
    onChange?: OnChangeFn | undefined,
    parser?: ParserFn | undefined
  ) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      const parsedValue = parser ? parser(event) : value;
      const extras =
        typeof onChange !== "undefined"
          ? onChange(parsedValue, name, data)
          : {};
      const newData = { ...data, [name]: parsedValue, ...extras };
      setDataAndNotifyOnDataChanged(newData);
    };
  }
  function parseCheckbox(event: { target: { checked: boolean } }): boolean {
    return event.target.checked;
  }

  //const parseAutocomplete = (event) => event.target.value?.value;

  interface StructureItem {
    name: string;
    type: string;
    defaultValue?: any;
    items?: Structure;
    required?: boolean;
    validate?: (value: any) => boolean;
  }

  interface ValidationResult {
    isValid: boolean;
    invalidItems: StructureItem[];
  }

  function isValid(structure: Structure, data: any): boolean {
    let invalidItems: StructureItem[] = [];
    structure.items.forEach((item: StructureItem) => {
      if (item.type === "object" && Array.isArray(item.items)) {
        item.items.forEach((subitem: StructureItem) => {
          const value = data[`${item.name}.${subitem.name}`];
          if (
            (subitem.required &&
              (value === undefined || value === null || value === "")) ||
            (subitem.validate && !subitem.validate(value))
          ) {
            invalidItems.push(subitem);
          }
        });
      } else {
        const value = data[item.name];
        if (
          (item.required &&
            (value === undefined || value === null || value === "")) ||
          (item.validate && !item.validate(value))
        ) {
          invalidItems.push(item);
        }
      }
    });
    return invalidItems.length === 0;
  }

  type Cleaned = {
    [key: string]: any;
  };

  function cleanPath(path: string[], value: any, cleaned: Cleaned): void {
    const [root, ...childPath] = path;
    const type = typeof value;
    if (childPath.length > 0) {
      if (!cleaned[root]) {
        cleaned[root] = {};
      }
      return cleanPath(childPath, value, cleaned[root]);
    } else if (type !== "undefined") {
      if (type === "string") {
        //if (value !== "") {
        cleaned[root] = value.trim();
        //}
      } else {
        cleaned[root] = value;
      }
    }
  }

  type Data = {
    [key: string]: any;
  };

  function cleanData(data: Data): Data {
    const cleaned: Data = {};
    const dataCopy = JSON.parse(JSON.stringify(data));

    Object.keys(dataCopy).forEach((key) => {
      const path = key.split(".");
      const value = dataCopy[key];
      cleanPath(path, value, cleaned);
    });

    return cleaned;
  }

  type Item = {
    type:
      | "text"
      | "number"
      | "selector"
      | "switch"
      | "percent"
      | "checkbox"
      | "radio"
      | "object";
    name: string;
    disabled?: boolean;
    required?: boolean;
    onChange?: (value: any, name: string, data: Data) => any;
    label?: string;
    items?: Item[];
    id?: string;
    value?: string;
    validate?: (value: any) => boolean;
    css?: string;
  };
  type HandleInputChangeFn = (
    onChange?: (value: any, name: string, data: Data) => any,
    parser?: (event: any) => any
  ) => (event: any) => void;

  //Create form items from structure object
  const buildItem = (
    item: Item,
    data: Data,
    disabled?: boolean,
    handleInputChange?: HandleInputChangeFn
  ) => {
    const itemDisabled = disabled || item.disabled;

    if (item.type === "text") {
      return (
        <div className={`${item.css} px-3 mb-6`} key={item.name}>
          <label
            className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-700"
            htmlFor={item.name}
          >
            {item.label}
          </label>
          <input
            className={`block w-full px-4 py-3 leading-tight text-grey-700 border rounded appearance-none  focus:outline-none focus:bg-white 
            ${data[item.name] === "" ? "border-red-500" : "border-grey-600"}`}
            name={item.name}
            id={item.name}
            required={item.required}
            onChange={
              handleInputChange ? handleInputChange(item.onChange) : undefined
            }
            value={data[item.name]}
          />
        </div>
      );
    }
    if (item.type === "number") {
      return (
        <div className={`${item.css} px-3 mb-6`} key={item.name}>
          <label
            className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-700"
            htmlFor={item.name}
          >
            {item.label}
          </label>
          <input
            className={`block w-full px-4 py-3 leading-tight text-grey-700 border rounded appearance-none  focus:outline-none focus:bg-white
            ${data[item.name] === "" ? "border-red-500" : "border-grey-600"}
            `}
            id={item.name}
            name={item.name}
            required={item.required}
            onChange={
              handleInputChange
                ? handleInputChange(item.onChange, parseInteger)
                : undefined
            }
            value={data[item.name]}
          />
        </div>
      );
    }
    if (item.type === "selector") {
      return (
        <div className={`${item.css} px-3 mb-6`} key={item.name}>
          <label
            className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-700"
            htmlFor={item.name}
          >
            {item.label}
          </label>
          <div className="relative">
            <select
              className="block w-full px-4 py-3 pr-8 leading-tight border rounded appearance-none text-grey-700 border-grey-600 focus:outline-none focus:bg-white focus:border-grey-700"
              id={item.name}
              // disabled={itemDisabled}
              name={item.name}
              value={
                typeof data[item.name] !== "undefined" ? data[item.name] : ""
              }
              required={item.required}
              onChange={
                handleInputChange ? handleInputChange(item.onChange) : undefined
              }
            >
              {item.items?.map((subitem) => {
                return (
                  <option
                    key={`${item.name}.${subitem.value}`}
                    value={subitem.value}
                  >
                    {subitem.label}
                  </option>
                );
              })}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none text-grey-700">
              <svg
                className="w-4 h-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
      );
    }
    if (item.type === "object") {
      return (
        <div key={item.name}>
          <div>
            <p>{item.label}</p>
          </div>
          {JSON.parse(JSON.stringify(item.items)).map((subitem: Item) => {
            subitem.name = `${item.name}.${subitem.name}`;
            subitem.id = `${item.name}.${subitem.name}`;
            return buildItem(subitem, data, undefined, handleInputChange);
          })}
        </div>
      );
    }
  };

  // const handleValidationErrors = (error) => {
  //   const { details } = error;
  //   const errorDetails = Array.isArray(details) ? details : [details];
  //   const newHelperText = errorDetails.reduce(
  //     (helperText, item) => ({
  //       ...helperText,
  //       [item.context.label]: item.message, //.replace(/".+"/, "");
  //     }),
  //     {}
  //   );
  //   setHelperText(newHelperText);
  // };

  const { id } = props;
  return (
    <div>
      <form className="w-full max-w-lg">
        <div className="flex flex-wrap mb-6 -mx-3">
          {structure.items.map((item: Item) => {
            return buildItem(item, data, undefined, handleInputChange);
          })}
        </div>
      </form>
      <div className="flex justify-around w-full max-w-lg mb-6">
        <Button
          padding="0.5rem 1rem"
          label={props.updateButtonLabel}
          width={props.buttonWidth}
          disabled={!isValid(structure, data) || props.buttonDisabled}
          onClick={() =>
            dispatch(props.updateButtonAction({ id, ...cleanData(data) }))
          }
        />
        <Button
          padding="0.5rem 1rem"
          label={props.deleteButtonLabel}
          width={props.buttonWidth}
          onClick={() => dispatch(props.deleteButtonAction({ id }))}
        />
      </div>
    </div>
  );
}
