import React from "react";
import UserItem from "./UserItem";
import { User } from "./User";
import { useNavigate } from "react-router-dom";
import { useGetTopUsersQuery } from "../../app/services/top_users/top_users";
import Spinner from "../Spinner";

interface UsersListProps {
  startDate?: Date | null | undefined | string;
  endDate?: Date | null | undefined | string;
  height?: number;
}

const TopUsersList: React.FC<UsersListProps> = ({
  startDate,
  endDate,
  height,
}) => {
  const navigate = useNavigate();

  const {
    data: users,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetTopUsersQuery({ startDate, endDate });

  //Handle click on user and navigate to user details page
  const handleUserClick = (user: User) => {
    navigate(`/users/${user.id}`);
  };

  const wrapperHeight = height ? height + 50 : 350;

  return (
    <div className="relative mt-8 mb-8">
      <h1 className="py-4 pl-4 text-lg font-medium">Top users</h1>
      <div
        className="grid mb-8 ml-3 overflow-hidden shadow-xl"
        style={{ height: wrapperHeight }}
      >
        {isFetching || isLoading ? (
          <Spinner height="100px" width="100px" />
        ) : (
          <>
            <div className="grid grid-cols-4 p-2 items-center font-semibold text-grey-700 min-h-[60px] leading-tight text-center border-0 bg-grey-200 border-grey-100">
              {/* <div className="flex flex-row justify-between p-4 pt-0 pb-0 mr-6 text-base font-semibold text-grey-500"> */}
              <p className="inline ">User Email</p>
              <p className="inline ">Num of created reports</p>
              <p className="inline ">Num of written words</p>
              <p className="inline">Total income from user (LTV)</p>
            </div>

            <div
              style={{ height: `${height}px` }}
              className="grid grid-cols-1 gap-4 pt-4 pl-2 overflow-hidden overflow-y-scroll"
            >
              {users?.results.map((user: User) => (
                <UserItem key={user.id} user={user} onClick={handleUserClick} />
              ))}
            </div>
          </>
        )}
        {isError && error && (
          <div className="grid grid-cols-4 grid-rows-2 min-h-[60px] text-center border-0 shadow-xl border-grey-100">
            <p className="col-start-1 col-end-5">Couldn't fetch data</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopUsersList;
