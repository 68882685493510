import { PayloadAction } from "@reduxjs/toolkit";
import React from "react";

type Props = {
  title: string;
  children: React.ReactNode;
  onClick: () => Promise<PayloadAction>;
};

const Tab: React.FC<Props> = ({ children }) => {
  return <div className="h-full mt-6">{children}</div>;
};

export default Tab;
