import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//
// import { RootState } from "../../app/store";
import { RootState } from "../../app/reducers";
//Import the user service
import userService from "./userService";

//
export type UserType = {
  name: string;
  avatar: string;
  id: number;
  email: string;
  date_registered: string;
  remaining_reports: number;
  is_affiliate: boolean;
  is_active: boolean;
  generatedReports: number;
  is_business: string;
  user_plan: {
    description: string | null;
    name?: string | null;
  };
};

//State object
export interface UserStateInterface {
  user: UserType;
  customer: {
    id: string | undefined;
  };
  customerSubscriptions: {
    count: number;
    links: {
      next: number | null;
      previous: number | null;
    };
    results: any[];
  };
  subscriptionsPageNumber: number;
  customerInvoices: {
    count: number;
    links: {
      next: number | null;
      previous: number | null;
    };
    results: any[];
  };
  invoicesPageNumber: number;
  userArticles: {
    count: number;
    links: {
      next: number | null;
      previous: number | null;
    };
    results: any[];
  };
  articlesPageNumber: number;
  userReports: {
    count: number;
    links: {
      next: number | null;
      previous: number | null;
    };
    results: any[];
  };
  reportsPageNumber: number;
  users: UserType[];
  pageCount: number;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  message: string | any;
}

const initialState: UserStateInterface = {
  user: {
    name: "",
    avatar: "",
    id: 0,
    email: "",
    date_registered: "",
    remaining_reports: 0,
    is_affiliate: false,
    is_active: false,
    generatedReports: 0,
    is_business: "",
    user_plan: {
      description: null,
    },
  },
  customer: {
    id: undefined,
  },
  customerSubscriptions: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    results: [],
  },
  subscriptionsPageNumber: 1,
  customerInvoices: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    results: [],
  },
  invoicesPageNumber: 1,
  userArticles: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    results: [],
  },
  articlesPageNumber: 1,
  userReports: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    results: [],
  },
  reportsPageNumber: 1,
  users: [],
  pageCount: 0,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

//Get user
export const getUser = createAsyncThunk(
  "user/getSingleUser",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await userService.getUser(id);

      return response.data;
      // try {
      //   const state = thunkAPI.getState() as RootState;
      //   const response = await userService.getUserReports(id, {
      //     params: {
      //       page: state.user.reportsPageNumber,
      //     },
      //   });
      //   return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Get user reports
export const getUserReports = createAsyncThunk(
  "user/getSingleUserReports",
  async (payload: { id: string | undefined; page: number }, thunkAPI) => {
    const { id, page } = payload;
    try {
      const state = thunkAPI.getState() as RootState;

      const response = await userService.getUserReports(id, {
        params: {
          page: page,
        },
      });
      //const response = await userService.getUserReports(id, page);
      return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Get user articles
export const getUserArticles = createAsyncThunk(
  "user/getSingleUserArticles",
  async (payload: { id: string | undefined; page: number }, thunkAPI) => {
    const { id, page } = payload;
    try {
      const response = await userService.getUserArticles(id, {
        params: {
          page: page,
        },
      });
      return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Get customer
export const getCustomer = createAsyncThunk(
  "user/getCustomer",
  async (payload: { id: string | undefined; page: number }, thunkAPI) => {
    const { id, page } = payload;

    try {
      const response = await userService.getCustomer({
        params: {
          subscriber_id: id,
          page: page,
        },
      });
      return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Get customer invoices
export const getCustomerInvoices = createAsyncThunk(
  "user/getCustomerInvoices",
  async (payload: { id: string | undefined; page: number }, thunkAPI) => {
    const { id, page } = payload;

    await thunkAPI.dispatch(getCustomer({ id: id, page: page }));

    const state = thunkAPI.getState() as RootState;

    try {
      const response = await userService.getCustomerInvoices({
        params: {
          customer_id: state.user.customer.id,
          page: page,
        },
      });
      return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Get customer subscriptions
export const getCustomerSubscriptions = createAsyncThunk(
  "user/getCustomerSubscriptions",
  async (payload: { id: string | undefined; page: number }, thunkAPI) => {
    const { id, page } = payload;

    await thunkAPI.dispatch(getCustomer({ id: id, page: page }));

    const state = thunkAPI.getState() as RootState;

    try {
      const response = await userService.getCustomerSubscriptions({
        params: {
          customer_id: state.user.customer.id,
          page: page,
        },
      });
      return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Update user
export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (payload: { id: string; data: UserType }, thunkAPI) => {
    const { id, ...data } = payload;
    try {
      const response = await userService.updateUser(id, data);
      return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Delete user
export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (payload: { id: string }, thunkAPI) => {
    const { id } = payload;
    try {
      const response = await userService.deleteUser(id);
      return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Create store slice
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateCustomer: (state, action) => {
      state.customer = action.payload;
    },
    resetMessage: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //Get single user
    builder.addCase(getUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
    //Get user reports
    builder.addCase(getUserReports.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserReports.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userReports = action.payload;
      state.userReports.count = Math.ceil(action.payload.count / 10);
    });
    builder.addCase(getUserReports.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
    //Get user articles
    builder.addCase(getUserArticles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserArticles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userArticles = action.payload;
      state.userArticles.count = Math.ceil(action.payload.count / 10);
    });
    builder.addCase(getUserArticles.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
    //Get customer
    builder.addCase(getCustomer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomer.fulfilled, (state, action) => {
      state.customer = action.payload;
    });
    builder.addCase(getCustomer.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
    //Get customer invoices
    builder.addCase(getCustomerInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomerInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customerInvoices = action.payload;
      state.customerInvoices.count = Math.ceil(action.payload.count / 10);
    });
    builder.addCase(getCustomerInvoices.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
    //Get customer subscriptions
    builder.addCase(getCustomerSubscriptions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomerSubscriptions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customerSubscriptions = action.payload;
      state.customerSubscriptions.count = Math.ceil(action.payload.count / 10);
    });
    builder.addCase(getCustomerSubscriptions.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
    //Update user
    builder.addCase(updateUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.isSuccess = true;
      state.message = "User updated successfully";
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
  },
});
/////updateCustomer ???
export const { reset, resetMessage, updateCustomer } = userSlice.actions;

export default userSlice.reducer;
