import React from "react";
import Spinner from "../Spinner";
import { useGetLtvQuery } from "../../app/services/lifetime_value/lifetime_value";
import { is } from "date-fns/locale";

export default function Ltv() {
  const { data, isFetching, isLoading, isError, error } = useGetLtvQuery("ltv");

  let value;
  if (data) {
    value = data["the average lifetime value"];
  }

  return (
    <div className="grid grid-rows-[min-content_1fr] relative">
      <h1 className="py-4 text-lg font-medium">Lifetime value ($)</h1>
      {/* <div className="flex font-bold h-[100%] text-center shadow-xl bg-grey-200 justify-center"> */}
      {isLoading || isFetching ? (
        <Spinner height="50px" width="50px" />
      ) : (
        <div className="flex font-bold h-[100%] text-center shadow-xl bg-grey-200 items-center justify-center">
          <div>{value?.toFixed(2)}</div>
        </div>
      )}
      {isError && error && (
        <div className="grid grid-cols-4 grid-rows-2 min-h-[60px] text-center border-0 shadow-xl border-grey-100">
          <p className="col-start-1 col-end-5">Couldn't fetch data</p>
        </div>
      )}
    </div>
  );
}
