import { createApi } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../../reducers";
import { axiosBaseQuery } from "../../../axiosBaseQuery";

export const totalIncomePerMonth = createApi({
  reducerPath: "totalIncomePerMonth",
  baseQuery: (args, api) => {
    const state = api.getState() as RootState;

    const token = state.auth.access;

    let headers: Record<string, string> = {};
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    return axiosBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL,
    })({ ...args, headers });
  },
  endpoints: (builder) => ({
    getTotalIncomePerMonth: builder.query({
      query: ({
        startDate = null,
        endDate = null,
      }: {
        startDate?: Date | null | undefined | string;
        endDate?: Date | null | undefined | string;
      }) => ({
        url: `auth/chart_income_date_range?start_date=${startDate}&end_date=${endDate}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetTotalIncomePerMonthQuery } = totalIncomePerMonth;
