import React, { useState } from "react";
import MenuItem from "./MenuItem";
import {
  Dashboard,
  Reports,
  Users,
  Articles,
  Payments,
  Invoices,
  Plans,
  Subscriptions,
  ExpandMore,
} from "../../assets/icons";

const Menu = ({ isSidebarOpen }: { isSidebarOpen: boolean }) => {
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const menu = [
    { name: "Dashboard", link: "/", icon: Dashboard, submenu: false },
    { name: "Users", link: "/users", icon: Users, submenu: false },
    {
      name: "Reports",
      link: "/reports",
      icon: Reports,
      submenu: false,
    },
    { name: "Articles", link: "/articles", icon: Articles, submenu: false },
    {
      name: "Payments",
      link: "#",
      submenu: true,
      margin: true,
      submenuItems: [
        {
          name: "Invoices",
          link: "/invoices",
          icon: Invoices,
        },
        { name: "Plans", link: "/plans", icon: Plans, margin: true },
        {
          name: "Subscriptions",
          link: "/subscriptions",
          icon: Subscriptions,
          margin: true,
        },
      ],
      icon: Payments,
      expandMenuIcon: ExpandMore,
      onClick: () => setSubmenuOpen(!submenuOpen),
    },
  ];

  return (
    <>
      {menu.map((item) => (
        <MenuItem
          key={item.name}
          item={item}
          submenuOpen={submenuOpen}
          isSidebarOpen={isSidebarOpen}
        />
      ))}
    </>
  );
};

export default Menu;
