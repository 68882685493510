import { createApi } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../reducers";
import { axiosBaseQuery } from "../../axiosBaseQuery";

export const LtvApi = createApi({
  reducerPath: "LtvApi",
  baseQuery: (args, api) => {
    const state = api.getState() as RootState;

    const token = state.auth.access;

    let headers: Record<string, string> = {};
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    return axiosBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL,
    })({ ...args, headers });
  },
  endpoints: (builder) => ({
    getLtv: builder.query({
      query: () => ({
        url: "payments/admin_invoice/get_average_LTV/",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetLtvQuery } = LtvApi;
