import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import { toast } from "react-toastify";
import { getInvoices, reset } from "../../features/invoices/invoicesSlice";
import Table from "../../components/Table/Table";
import { ColumnDef } from "@tanstack/react-table";
import {
  InvoicesDataInterface,
  InvoicesColumns,
} from "../../components/Table/Columns/Invoices/InvoicesColumns";
import { Button } from "../../components/Button";
import TableLoadingSkeleton from "../../components/Table/TableLoadingSkeleton";

function Invoices() {
  const dispatch = useAppDispatch();

  const { invoices, pageNumber, isLoading, isError, message } = useAppSelector(
    (state) => state.invoices
  );

  useEffect(() => {
    // if (isError) {
    //   toast.error(message);
    // }

    dispatch(getInvoices({ page: 1 }));

    return () => {
      dispatch(reset());
    };
  }, [isError, message, dispatch]);

  window.scrollTo({ top: 0, behavior: "smooth" });

  //InvoicesColumns
  const InvoicesCols = React.useMemo<ColumnDef<InvoicesDataInterface>[]>(
    () => InvoicesColumns,
    []
  );

  //TS
  interface State {
    searchTerm: string;
    activeFilter: string;
    placeholder: string;
  }
  //set params for dispatch action based on filter
  const [activeParams, setActiveParams] = useState<any>(null);

  //set active filter option
  const [activeFilter, setActiveFilter] = useState<State["activeFilter"]>("");

  //Input filter
  const [searchTerm, setSearchTerm] = useState<State["searchTerm"]>("");

  //Input placeholder
  const [placeholder, setPlaceholder] = useState<State["placeholder"]>("");

  //change role filter
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "") {
      dispatch(getInvoices({ page: 1 }));
      setActiveParams(null);
    }

    setPlaceholder(
      e.target.options[e.target.selectedIndex].dataset.placeholder || ""
    );
    setSearchTerm("");

    setActiveFilter(e.target.value);
  };

  // const dispatchAction = () => {
  //   if (placeholder === "Customer email" && searchTerm !== "") {
  //     //Set prams for dispatch action
  //     setActiveParams({
  //       filterUrl: "get_by_customer_email/",
  //       customer_email: searchTerm,
  //     });
  //     //Dispatch action
  //     return dispatch(
  //       getInvoices({
  //         page: pageNumber,
  //         filterUrl: "get_by_customer_email/",
  //         customer_email: searchTerm,
  //       })
  //     );
  //   } else if (placeholder === "Customer id" && searchTerm !== "") {
  //     //Set prams for dispatch action
  //     setActiveParams({
  //       filterUrl: "get_by_customer_id/",
  //       customer_id: searchTerm,
  //     });
  //     //Dispatch action
  //     return dispatch(
  //       getInvoices({
  //         page: pageNumber,
  //         filterUrl: "get_by_customer_id/",
  //         customer_id: searchTerm,
  //       })
  //     );
  //   } else if (placeholder === "Subscription id" && searchTerm !== "") {
  //     //Set prams for dispatch action
  //     setActiveParams({
  //       filterUrl: "get_by_subscription_id/",
  //       subscription_id: searchTerm,
  //     });

  //     //Dispatch action
  //     return dispatch(
  //       getInvoices({
  //         page: pageNumber,
  //         filterUrl: "get_by_subscription_id/",
  //         subscription_id: searchTerm,
  //       })
  //     );
  //   } else {
  //     //Set prams for dispatch action
  //     setActiveParams({
  //       filterUrl: "",
  //     });

  //     //Dispatch action
  //     return dispatch(
  //       getInvoices({
  //         page: pageNumber,
  //         filterUrl: "",
  //       })
  //     );
  //   }
  // };
  const dispatchAction = () => {
    let filterUrl = "";
    let activeParams: any = {};
    let searchParam: string | undefined;

    switch (placeholder) {
      case "Customer email":
        filterUrl = "get_by_customer_email/";
        activeParams.customer_email = searchTerm;
        searchParam = "customer_email";
        break;
      case "Customer id":
        filterUrl = "get_by_customer_id/";
        activeParams.customer_id = searchTerm;
        searchParam = "customer_id";
        break;
      case "Subscription id":
        filterUrl = "get_by_subscription_id/";
        activeParams.subscription_id = searchTerm;
        searchParam = "subscription_id";
        break;
      default:
        break;
    }

    activeParams.filterUrl = filterUrl;

    if (searchTerm !== undefined && searchTerm !== "") {
      activeParams[searchParam!] = searchTerm;
    }

    const payload = {
      page: pageNumber,
      ...activeParams,
    };

    setActiveParams(activeParams);
    return dispatch(getInvoices(payload));
  };

  return (
    <section>
      <h1 className="font-semibold">INVOICES</h1>
      <hr className="my-4" />
      <div className="flex items-end justify-between ">
        <div className="grid grid-cols-[8fr,1fr,3fr] gap-4 items-center justify-between w-full mt-4">
          <div
            className={`w-full items-center ${
              activeFilter !== "" ? "flex" : "hidden"
            }`}
          >
            {/* Input field */}
            <div className={`w-full mr-4`}>
              <div className="">
                <input
                  className={`block w-full px-4 py-3 mb-0 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:border-2 focus:border-robinizeGreen-light focus:bg-white ${
                    searchTerm === "" ? "border-red-500" : "border-grey-600"
                  }`}
                  name="searchTerm"
                  id="searchTerm"
                  placeholder={placeholder}
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
          {/* Submit button */}
          <Button
            label="Submit"
            width={"8rem"}
            margin={"0 0 0 0"}
            onClick={() => dispatchAction()}
            disabled={!searchTerm}
            requestPending={isLoading}
            style={{
              gridColumnStart: 2,
              display: activeFilter === "" ? "none" : "flex",
            }}
          />
          {/* Filter select */}
          <div className="col-start-3 mx-4">
            <div className="relative">
              <select
                className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 border rounded appearance-none border-grey-600 focus:outline-none focus:bg-white focus:border-gray-700"
                value={activeFilter}
                onChange={(e) => handleFilterChange(e)}
              >
                <option value="" data-placeholder="">
                  All
                </option>
                <option
                  value="customer_email"
                  data-placeholder="Customer email"
                >
                  Customer email
                </option>
                <option value="customer_id" data-placeholder="Customer id">
                  Customer id
                </option>
                <option
                  value="subscription_id"
                  data-placeholder="Subscription id"
                >
                  Subscription id
                </option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                <svg
                  className="w-4 h-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        // <Loader loading={true} />
        <TableLoadingSkeleton />
      ) : (
        <Table
          data={invoices.results}
          columns={InvoicesCols}
          showFilters={false}
          actionName={getInvoices}
          actionParams={activeParams}
          next={invoices.links.next}
          previous={invoices.links.previous}
          pageCount={invoices.count}
        />
      )}
    </section>
  );
}

export default Invoices;
