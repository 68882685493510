import axios, { AxiosError, AxiosRequestConfig } from "axios";

interface BaseQueryArgs extends AxiosRequestConfig {
  url: string;
  headers?: any;
}

interface QueryError {
  status?: number;
  data?: any;
}

export const axiosBaseQuery =
  ({ baseUrl }: { baseUrl: string | undefined }) =>
  async ({ url, method = "GET", data, headers }: BaseQueryArgs) => {
    try {
      const response = await axios({
        url: baseUrl + url,
        method,
        data,
        headers,
      });
      return { data: response.data };
    } catch (axiosError) {
      let err: QueryError = {};

      if (axiosError && (axiosError as AxiosError).response) {
        const error: AxiosError = axiosError as AxiosError;
        err = {
          status: error.response?.status,
          data: error.response?.data,
        };
      }

      return { error: err };
    }
  };
