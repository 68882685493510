import React from "react";
import { useAppSelector } from "../../src/app/hooks";

import { Button } from "./Button";

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

export const Modal: React.FC<Props> = ({ isOpen, onClick }) => {
  const { sessionDialogModal } = useAppSelector((state) => state.auth);

  if (!sessionDialogModal) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-opacity-75 bg-grey-300">
      <div className="grid w-1/2 xl:w-1/3 grid-cols-1 grid-rows-[2fr,7fr,3fr] gap-1 items-center bg-white rounded-md h-1/4">
        <div
          className={`bg-robinizeGreen text-white font-semibold p-3 w-full h-[3rem] drop-shadow-[0_5px_10px_rgba(175,247,218,1)]
      `}
        >
          Session expired!
        </div>
        <div className="text-lg font-medium text-grey-500 justify-self-center">
          Please login to extend the session.
        </div>
        <Button label="OK" width="6rem" margin="0 0 1rem 0" onClick={onClick} />
      </div>
    </div>
  );
};
