import React from "react";
import { NavLink } from "react-router-dom";

interface MenuItemProps {
  item: {
    name: string;
    link: string;
    icon: any;
    submenu: boolean;
    submenuItems?: {
      name: string;
      link: string;
      icon: any;
    }[];
    expandMenuIcon?: any;
    margin?: boolean;
    onClick?: () => void;
  };
  submenuOpen: boolean;
  isSidebarOpen: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({
  item,
  isSidebarOpen,
  submenuOpen,
}) => {
  if (!item.submenu) {
    return (
      <NavLink
        to={item.link}
        className={({ isActive }) =>
          [
            `${item?.margin && "mt-3.5"}
            group flex items-center text-sm gap-3.5 font-medium p-2 hover:bg-grey-300 rounded-md`,
            isActive ? "bg-grey-300" : "bg-transparent",
          ]
            .filter(Boolean)
            .join(" ")
        }
      >
        <div>{React.createElement(item.icon, { size: "20" })}</div>
        <h2
          className={`whitespace-pre transition duration-700  ${
            !isSidebarOpen && "opacity-0 overflow-hidden"
          }`}
        >
          {item.name}
        </h2>
        <h2
          className={`${
            isSidebarOpen && "hidden"
          } absolute left-4 bg-white font-semibold whitespace-pre text-robinizeBlack-light rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-10 group-hover:duration-300 group-hover:w-fit  `}
        >
          {item.name}
        </h2>
      </NavLink>
    );
  }

  return (
    <>
      <div
        className={`group flex items-center text-sm gap-3.5 font-medium p-2 hover:bg-grey-300 rounded-md`}
        onClick={item.onClick}
      >
        <div>{React.createElement(item.icon, { size: "20" })}</div>
        <h2
          className={`whitespace-pre transition duration-700  ${
            !isSidebarOpen && "opacity-0 overflow-hidden"
          }`}
        >
          {item.name}
        </h2>
        {item.expandMenuIcon &&
          React.createElement(item.expandMenuIcon, { rotate: submenuOpen })}
        <h2
          className={`${
            isSidebarOpen && "hidden"
          } absolute left-4 bg-white font-semibold whitespace-pre text-robinizeBlack-light rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-10 group-hover:duration-300 group-hover:w-fit  `}
        >
          {item.name}
        </h2>
      </div>
      {item.submenuItems && submenuOpen && (
        <div
          className={`transition-all duration-500 ${isSidebarOpen && "ml-8"}`}
        >
          {item.submenuItems.map((submenuItem) => (
            <MenuItem
              key={submenuItem.name}
              //@ts-ignore
              item={submenuItem}
              isSidebarOpen={isSidebarOpen}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default MenuItem;
