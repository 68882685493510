import api from "../../api/api";

const authBaseUrl = "auth/";

//Get reports
const getUsers = async (
  options: {
    params: {
      page: number | undefined;
      start_date?: Date | string | null;
      end_date?: Date | string | null;
      email?: string | null;
      name?: string | null;
      min?: number | null;
      max?: number | null;
      bussiness?: string | null;
      affiliate?: string | null;
      active?: string | null;
      user_plan?: string | null;
    };
  },
  filterUrl?: string
) => {
  if (filterUrl === undefined) {
    filterUrl = "";
  }

  const response = await api.get(`${authBaseUrl}${filterUrl}`, options);

  return response;
};
//Get all users filtered by status, role, sort by remaining reports
const getAllUsersFilter = async (
  payload: any,
  options: {
    params: {
      page: number | null;
      status: number | null | string;
      role: string | null;
      "sort by remaining reports": string | null;
    };
  }
) => {
  if (options.params.status === 2) {
    options.params.status = 0;
  }
  const response = await api.get(`${authBaseUrl}users_filter`, options);
  return response;
};

const usersService = {
  getUsers,
  getAllUsersFilter,
};

export default usersService;
