import { combineReducers, Reducer, AnyAction } from "@reduxjs/toolkit";

import authReducer from "../features/auth/authSlice";
import sidebarReducer from "../features/sidebar/sidebarSlice";
import userReducer from "../features/user/userSlice";
import usersReducer from "../features/users/usersSlice";
import tabsReducer from "../features/tabs/tabsSlice";
import reportsReducer from "../features/reports/reportsSlice";
import articlesReducer from "../features/articles/articlesSlice";
import invoicesReducer from "../features/invoices/invoicesSlice";
import subscriptionsReducer from "../features/subscriptions/subscriptionsSlice";
import { authApi } from "./services/auth/auth";
import { plansApi } from "./services/plans/plans";
import { LtvApi } from "./services/lifetime_value/lifetime_value";
import { topUsersApi } from "./services/top_users/top_users";
import { usersFunnelApi } from "./services/users_funnel/users_funnel";
import { usersDateRegistration } from "./services/charts/date_registration/date_registration";
import { newSubscribersApi } from "./services/charts/new_subscribers/new_subscribers";
import { newSubscriptionsAmount } from "./services/charts/new_subscriptions_amount/new_subscriptions_amount";
import { percentageRegisteredUsers } from "./services/charts/percentage_registered_user/percentage_registered_user";
import { totalIncomePerMonth } from "./services/charts/total_income_per_month/total_income_per_month";

const combinedReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
  user: userReducer,
  users: usersReducer,
  tabs: tabsReducer,
  reports: reportsReducer,
  articles: articlesReducer,
  invoices: invoicesReducer,
  subscriptions: subscriptionsReducer,
  [authApi.reducerPath]: authApi.reducer,
  [plansApi.reducerPath]: plansApi.reducer,
  [LtvApi.reducerPath]: LtvApi.reducer,
  [topUsersApi.reducerPath]: topUsersApi.reducer,
  [usersFunnelApi.reducerPath]: usersFunnelApi.reducer,
  [usersDateRegistration.reducerPath]: usersDateRegistration.reducer,
  [newSubscribersApi.reducerPath]: newSubscribersApi.reducer,
  [newSubscriptionsAmount.reducerPath]: newSubscriptionsAmount.reducer,
  [percentageRegisteredUsers.reducerPath]: percentageRegisteredUsers.reducer,
  [totalIncomePerMonth.reducerPath]: totalIncomePerMonth.reducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "auth/sessionDialog" && action.payload === false) {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
