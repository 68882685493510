import React, { useMemo } from "react";
import { useGetNewSubscribersQuery } from "../../../app/services/charts/new_subscribers/new_subscribers";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

import Spinner from "../../Spinner";

interface ChartProps {
  startDate?: Date | null | undefined | string;
  endDate?: Date | null | undefined | string;
  width?: number;
  height?: number;
}

const NewSubscribers: React.FC<ChartProps> = ({
  startDate,
  endDate,
  width,
  height,
}) => {
  const { data, isLoading, isFetching, isError, error } =
    useGetNewSubscribersQuery({ startDate, endDate });

  ////
  // type ApiResponse = {
  //   month: string;
  //   total: number;
  // }[];
  type SubscriberEntry = {
    month: string;
    total: number;
  };

  type ApiResponse = {
    [key: string]: SubscriberEntry[];
  };

  //Replace month number from api with abbreviation
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function transformDataForChart(data: ApiResponse) {
    const mergedData: { [key: string]: any } = {};

    const formatMonthYear = (dateString: string) => {
      const date = new Date(dateString);
      const monthStr = months[date.getUTCMonth()];
      const year = date.getUTCFullYear();
      return `${monthStr} (${year})`;
    };

    for (let entry of data["new subscribers"]) {
      const monthYearStr = formatMonthYear(entry.month);

      if (!mergedData[monthYearStr]) {
        mergedData[monthYearStr] = {
          name: monthYearStr,
          newSubscribers: 0,
          allSubscribers: 0,
        };
      }
      mergedData[monthYearStr].newSubscribers = entry.total;
    }

    for (let entry of data["all subscribers"]) {
      const monthYearStr = formatMonthYear(entry.month);

      if (!mergedData[monthYearStr]) {
        mergedData[monthYearStr] = {
          name: monthYearStr,
          newSubscribers: 0,
          allSubscribers: 0,
        };
      }
      mergedData[monthYearStr].allSubscribers = entry.total;
    }

    return Object.values(mergedData);
  }

  const processedData = useMemo(() => {
    if (data) {
      return transformDataForChart(data as ApiResponse);
    }
  }, [data]);

  const wrapperHeight = height ? height + 50 : 300;

  return (
    <div className="relative mt-8">
      <h1 className="py-4 text-lg font-medium">
        Total vs. new paying users by month
      </h1>
      <div
        className="py-8 border-2 shadow-xl border-grey-100"
        style={{ height: wrapperHeight }}
      >
        {isLoading || isFetching ? (
          <Spinner height="100px" width="100px" />
        ) : (
          <ResponsiveContainer width="100%" height={height}>
            <BarChart
              data={processedData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                dataKey="allSubscribers"
                type="number"
                domain={[0, "dataMax"]}
                tickCount={10}
              />
              <Tooltip />
              <Bar
                dataKey="allSubscribers"
                fill="#33cca0"
                background={{ fill: "#eee" }}
                barSize={20}
              />
              <Bar
                dataKey="newSubscribers"
                fill="#8884d8"
                background={{ fill: "#eee" }}
                barSize={20}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
        {isError && error && (
          <div className="grid grid-cols-4 grid-rows-2 min-h-[60px] text-center border-0 shadow-xl border-grey-100">
            <p className="col-start-1 col-end-5">Couldn't fetch data</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewSubscribers;
