import { ColumnDef } from "@tanstack/react-table";
import { convertTimestamp } from "../../../../common/utils";

export interface ReportsDataInterface {
  created: string;
  user_email: string;
  country: string;
  language: string;
  word_count: string;
  readability: string;
  content_grade: string;
  keyword: string;
}

export const ReportsColumns: ColumnDef<ReportsDataInterface>[] = [
  {
    accessorFn: (row) => `${row.created}`,
    id: "created",
    header: "Created at",
    cell: (props) => {
      return (
        <p className="my-1 whitespace-nowrap">
          {convertTimestamp(props.row.original.created)}
        </p>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => `${row.user_email}`,
    id: "user_email",
    header: "Creator",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.country}`,
    id: "country",
    header: "Country",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => row.language,
    id: "language",
    header: "Language",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.word_count}`,
    id: "word_count",
    header: "Word count",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.keyword}`,
    id: "keyword",
    header: "Keyword",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => row.readability,
    id: "readability",
    header: "Readability",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => row.content_grade,
    id: "content_grade",
    header: "Content grade",
    cell: (info) => info.getValue(),
  },

  // {
  //   accessorFn: (row) => `${row.status}`,
  //   id: "status",
  //   header: "Status",
  //   cell: (props) => {
  //     if (props.row.original.status === "Completed") {
  //       return (
  //         <p className="my-1 text-center">
  //           <span className="inline-block px-4 py-2 rounded-lg w-[8rem] bg-opacity-20 bg-robinizeGreen-light">
  //             <span className="font-bold text-robinizeGreen-dark">
  //               {props.row.original.status}
  //             </span>
  //           </span>
  //         </p>
  //       );
  //     } else {
  //       return (
  //         <p className="my-1 text-center">
  //           <span className="inline-block w-[8rem] px-4 py-2 rounded-lg bg-opacity-20 bg-robinizeOrange-light">
  //             <span className="font-bold text-robinizeOrange-dark">
  //               {props.row.original.status}
  //             </span>
  //           </span>
  //         </p>
  //       );
  //     }
  //   },
  // },
];
