import React, { useEffect, useState, ChangeEventHandler } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import DatePicker from "react-datepicker";
import { format, isValid, parse } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import {
  getSubscriptions,
  reset,
} from "../../features/subscriptions/subscriptionsSlice";
import Table from "../../components/Table/Table";
import { ColumnDef } from "@tanstack/react-table";
import {
  SubscriptionsDataInterface,
  SubscriptionsColumns,
} from "../../components/Table/Columns/Subscriptions/SubscriptionsColumns";
import Loader from "../../components/Loader";
import { Button } from "../../components/Button";
import TableLoadingSkeleton from "../../components/Table/TableLoadingSkeleton";
import { convertTimestamp } from "../../common/utils";

function Subscriptions() {
  const dispatch = useAppDispatch();

  const { subscriptions, pageNumber, isLoading, isError, message } =
    useAppSelector((state) => state.subscriptions);

  useEffect(() => {
    if (isError) {
      // toast.error(message);
      setActiveURL("");
      setActiveFilter("");
      setActiveParams(null);
    }
    dispatch(getSubscriptions({ page: 1 }));

    return () => {
      dispatch(reset());
    };
  }, [isError, message, dispatch]);

  window.scrollTo({ top: 0, behavior: "smooth" });
  //SubscriptionsColumns

  const planNames: { [key: string]: string } = {
    "1": "Free",
    "2": "Individual Monthly",
    "3": "Individual Annual",
    "4": "Team Monthly",
    "5": "Team Annual",
  };

  const getSubscriptionsTableData = () => {
    return subscriptions.results.map(
      (subscription: SubscriptionsDataInterface) => {
        const planName = planNames[subscription.plan_id] || "Unknown";

        return {
          ...subscription,
          plan_name: planName,
        };
      }
    );
  };

  const dataForTable = getSubscriptionsTableData();

  const SubscriptionsCols = React.useMemo<
    ColumnDef<SubscriptionsDataInterface>[]
  >(() => SubscriptionsColumns, []);

  //TS
  interface State {
    customerId: string;
    activeFilter: string;
    activeURL: string;
    placeholder: string;
    statusSelectValue: string;
    error: string;
  }
  //set params for dispatch action based on filter
  const [activeParams, setActiveParams] = useState<any>(null);

  //set active filter option
  const [activeFilter, setActiveFilter] =
    useState<State["activeFilter"]>("all");

  //set active filter url
  const [activeURL, setActiveURL] = useState<State["activeURL"]>("");

  //Input filter
  const [customerId, setCustomerId] = useState<State["customerId"]>("");

  //Input placeholder
  const [placeholder, setPlaceholder] = useState<State["placeholder"]>("");
  //status select value
  const [statusSelectValue, setStatusSelectValue] =
    useState<State["statusSelectValue"]>("active");

  //error if search term is empty
  const [error, setError] = useState<State["error"]>("");

  //change role filter
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "") {
      dispatch(getSubscriptions({ page: 1 }));
      setActiveParams(null);
    }
    setActiveURL(e.target.value);
    setActiveFilter(
      e.target.options[e.target.selectedIndex].dataset.type || ""
    );
    setPlaceholder(
      e.target.options[e.target.selectedIndex].dataset.placeholder || ""
    );
    setCustomerId("");
    setStartDate(null);
    setEndDate(null);
  };
  ///Date
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [fromError, setFromError] = useState<string | null>("");
  const [toError, setToError] = useState<string | null>("");

  const handleStartChange = (date: Date | null) => {
    setStartDate(date);
    setFromError(null);
  };

  const handleEndChange = (date: Date | null) => {
    setEndDate(date);
    setToError(null);
  };
  const handleStartBlur: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value;

    const parsedDateSlash = parse(inputValue, "yyyy/MM/dd", new Date());
    const parsedDateDash = parse(inputValue, "yyyy-MM-dd", new Date());

    // If the date is invalid or the format doesn't match exactly
    if (
      (!isValid(parsedDateSlash) ||
        format(parsedDateSlash, "yyyy/MM/dd") !== inputValue) &&
      (!isValid(parsedDateDash) ||
        format(parsedDateDash, "yyyy-MM-dd") !== inputValue)
    ) {
      setFromError("Invalid date format. Please use YYYY-MM-DD.");
      setStartDate(null);
    } else {
      setFromError(null); // clear the error if the format is correct
    }
  };
  const handleEndBlur: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value;

    const parsedDateSlash = parse(inputValue, "yyyy/MM/dd", new Date());
    const parsedDateDash = parse(inputValue, "yyyy-MM-dd", new Date());

    // If the date is invalid or the format doesn't match exactly
    if (
      (!isValid(parsedDateSlash) ||
        format(parsedDateSlash, "yyyy/MM/dd") !== inputValue) &&
      (!isValid(parsedDateDash) ||
        format(parsedDateDash, "yyyy-MM-dd") !== inputValue)
    ) {
      setToError("Invalid date format. Please use YYYY-MM-DD.");
      setEndDate(null);
    } else {
      setToError(null); // clear the error if the format is correct
    }
  };
  const handleStartFocus: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFromError(null);
  };
  const handleEndFocus: ChangeEventHandler<HTMLInputElement> = (event) => {
    setToError(null);
  };

  interface SubscriptionParams {
    filterUrl: string;
    page: number;
    customer_id?: string;
    status?: string;
    canceled_start_date?: Date | string | null | undefined;
    canceled_end_date?: Date | string | null | undefined;
    created_start_date?: Date | string | null | undefined;
    created_end_date?: Date | string | null | undefined;
    start_date?: Date | string | null | undefined;
    end_date?: Date | string | null | undefined;
  }
  //This function is called on submit and is used to dispatch action based on selected option
  //checks activeURL value and adds that vale to base url together with other params

  const dispatchAction = () => {
    let params: SubscriptionParams = { filterUrl: activeURL, page: pageNumber };

    switch (activeURL) {
      case "get_by_creation_date/":
        params.created_start_date = convertTimestamp(startDate) ?? undefined;
        params.created_end_date = convertTimestamp(endDate) ?? undefined;
        break;

      case "get_by_canceled_at/":
        params.canceled_start_date = convertTimestamp(startDate) ?? undefined;
        params.canceled_end_date = convertTimestamp(endDate) ?? undefined;
        break;

      case "get_by_current_period_start/":
      case "get_by_current_period_end/":
      case "get_by_ended_at/":
        params.start_date = convertTimestamp(startDate) ?? undefined;
        params.end_date = convertTimestamp(endDate) ?? undefined;
        break;

      case "get_by_customer_id/":
        if (!customerId) {
          return setError("Customer ID is required");
        } else {
          params.customer_id = customerId;
          break;
        }

      case "get_by_status/":
        params.status = statusSelectValue;
        break;

      default:
        break;
    }

    setActiveParams(params);

    //Dispatch action
    return dispatch(getSubscriptions(params));
  };

  return (
    <section>
      <h1 className="font-semibold">SUBSCRIPTIONS</h1>
      <hr className="my-4" />
      <div className="flex items-end justify-between ">
        <div className="grid grid-cols-[8fr,1fr,3fr] gap-4 items-center justify-between w-full mt-4 mb-8">
          <div
            className={`w-full items-center ${
              activeFilter === "input" ? "flex" : "hidden"
            }`}
          >
            <div className={`w-full mr-4`}>
              <div className="relative">
                <input
                  className={`block w-full px-4 py-3 mb-0 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:border-2 focus:border-robinizeGreen-light focus:bg-white ${
                    customerId === "" ? "border-red-500" : "border-grey-600"
                  }`}
                  name="customerId"
                  id="customerId"
                  placeholder={placeholder}
                  type="text"
                  onChange={(e) => {
                    setCustomerId(e.target.value);
                    setError("");
                  }}
                  value={customerId}
                />
                {error && (
                  <span className="absolute left-0 text-sm text-red-500">
                    {error}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div
            className={`w-full items-center ${
              activeFilter === "select" ? "flex" : "hidden"
            }`}
          >
            <div className={`w-full mr-4`}>
              <div className="">
                <select
                  className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 border rounded appearance-none border-grey-600 focus:outline-none focus:bg-white focus:border-gray-700"
                  value={statusSelectValue}
                  onChange={(e) => setStatusSelectValue(e.target.value)}
                >
                  <option value="active">Active</option>
                  <option value="canceled">Canceled</option>
                </select>
              </div>
            </div>
          </div>
          <div
            className={`w-full items-center ${
              activeFilter === "date" ? "flex" : "hidden"
            }`}
          >
            <div className="flex">
              <div className="relative mr-2 datepicker">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartChange}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Start date: YYYY-MM-DD"
                  maxDate={endDate ?? new Date()}
                  onBlur={handleStartBlur}
                  onFocus={handleStartFocus}
                  startDate={startDate}
                  endDate={endDate}
                />
                {fromError && (
                  <span className="absolute top-[50px] block text-sm font-medium text-red-400">
                    Invalid date format.
                    <br />
                    Please use YYYY-MM-DD.
                  </span>
                )}
              </div>

              <div className="relative mr-4 datepicker">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndChange}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="End date: YYYY-MM-DD"
                  maxDate={new Date()}
                  onBlur={handleEndBlur}
                  onFocus={handleEndFocus}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
                {toError && (
                  <span className="absolute top-[50px] block text-sm font-medium text-red-400">
                    Invalid date format.
                    <br />
                    Please use YYYY-MM-DD.
                  </span>
                )}
              </div>
            </div>
          </div>
          <Button
            label="Submit"
            width={"8rem"}
            margin={"0 0 0 0"}
            onClick={() => dispatchAction()}
            disabled={
              (!customerId && activeFilter === "input") ||
              (!startDate && activeFilter === "date") ||
              (!endDate && activeFilter === "date")
            }
            requestPending={isLoading}
            style={{
              gridColumnStart: 2,
              display: activeFilter === "all" ? "none" : "flex",
            }}
          />
          {/* Filter select */}
          <div className="col-start-3 mx-4">
            <div className="relative">
              <select
                className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 border rounded appearance-none border-grey-600 focus:outline-none focus:bg-white focus:border-gray-700"
                // value={activeFilter}
                value={activeURL}
                onChange={(e) => handleFilterChange(e)}
              >
                <option value="" data-placeholder="" data-type="all">
                  All
                </option>
                <option
                  value="get_by_creation_date/"
                  data-type="date"
                  data-placeholder="Created start date - Created end date"
                >
                  Creation date
                </option>
                <option
                  value="get_by_canceled_at/"
                  data-type="date"
                  data-placeholder="Canceled start date - Canceled end date"
                >
                  Cancellation date
                </option>
                <option
                  value="get_by_current_period_start/"
                  data-type="date"
                  data-placeholder="Current period start date - Current period end date"
                >
                  Current period start
                </option>

                <option
                  value="get_by_current_period_end/"
                  data-type="date"
                  data-placeholder="Current period end date - Current period end date"
                >
                  Current period end
                </option>
                <option
                  value="get_by_ended_at/"
                  data-type="date"
                  data-placeholder="Ended at start date - Ended at end date"
                >
                  Ended at
                </option>

                <option
                  value="get_by_customer_id/"
                  data-type="input"
                  data-placeholder="Customer id"
                >
                  Customer id
                </option>
                <option
                  value="get_by_status/"
                  data-type="select"
                  data-placeholder="Status"
                >
                  Status
                </option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                <svg
                  className="w-4 h-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        // <Loader loading={true} />
        <TableLoadingSkeleton />
      ) : (
        <Table
          data={dataForTable}
          columns={SubscriptionsCols}
          showFilters={false}
          actionName={getSubscriptions}
          actionParams={activeParams}
          next={subscriptions.links?.next}
          previous={subscriptions.links?.previous}
          pageCount={subscriptions.count}
        />
      )}
    </section>
  );
}

export default Subscriptions;
