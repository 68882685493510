import React, { useMemo } from "react";
import { useGetTotalIncomePerMonthQuery } from "../../../app/services/charts/total_income_per_month/total_income_per_month";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

import Spinner from "../../Spinner";

interface ChartProps {
  startDate?: Date | null | undefined | string;
  endDate?: Date | null | undefined | string;
  width?: number;
  height?: number;
}

const TotalIncome: React.FC<ChartProps> = ({
  startDate,
  endDate,
  height,
  width,
}) => {
  const { data, isLoading, isFetching, isError, error } =
    useGetTotalIncomePerMonthQuery({ startDate, endDate });

  ////
  type ApiResponse = {
    month: string;
    total: number;
  }[];

  //Replace month number from api with abbreviation
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function replaceMonthWithAbbreviation(data: ApiResponse) {
    return data?.map((item) => {
      // Extract month number (0-based) and year from the date string
      const date = new Date(item.month);
      const monthNum = date.getUTCMonth();
      const year = date.getUTCFullYear();

      // Replace 'month' property with the formatted string
      return {
        ...item,
        month: `${months[monthNum]} (${year})`,
      };
    });
  }

  const processedData = useMemo(() => {
    if (data) {
      return replaceMonthWithAbbreviation(data as ApiResponse);
    }
  }, [data]);

  const wrapperHeight = height ? height + 50 : 300;

  return (
    <div className="relative mt-8">
      <h1 className="py-4 text-lg font-medium">Total income by month ($)</h1>
      <div
        className="py-8 border-2 shadow-xl border-grey-100"
        style={{ height: wrapperHeight }}
      >
        {isLoading || isFetching ? (
          <Spinner height="100px" width="100px" />
        ) : (
          <ResponsiveContainer width="100%" height={height}>
            <BarChart
              data={processedData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis
                dataKey="total"
                type="number"
                domain={[0, "dataMax"]}
                tickCount={10}
              />
              <Tooltip />
              <Bar
                dataKey="total"
                fill="#33cca0"
                background={{ fill: "#eee" }}
                barSize={20}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
        {isError && error && (
          <div className="grid grid-cols-4 grid-rows-2 min-h-[60px] text-center border-0 shadow-xl border-grey-100">
            <p className="col-start-1 col-end-5">Couldn't fetch data</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TotalIncome;
