import { PayloadAction } from "@reduxjs/toolkit";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setActiveTab } from "../../features/tabs/tabsSlice";

type Props = {
  title: string;
  index: number;
  onClick: () => Promise<PayloadAction>;
};

const TabTitle: React.FC<Props> = ({ title, index, onClick = () => {} }) => {
  const dispatch = useAppDispatch();
  const { selectedTab } = useAppSelector((state) => state.tabs);

  return (
    <li className="mr-4">
      <button
        className={`${
          selectedTab === index ? "text-grey-800" : "text-grey-400"
        } py-4 font-bold hover:text-grey-800`}
        onClick={() => {
          dispatch(setActiveTab(index));
          onClick();
        }}
      >
        {title}
      </button>
    </li>
  );
};

export default TabTitle;
