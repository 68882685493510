import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/reducers";
import invoicesService from "./invoicesService";

export interface InvoicesState {
  invoices: {
    count: number;
    links: {
      next: number | null;
      previous: number | null;
    };
    results: any[];
  };
  pageNumber: number;
  pageCount: number;
  isLoading: boolean;
  isError: boolean;
  message: string | any;
}
const initialState: InvoicesState = {
  invoices: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    results: [],
  },
  pageNumber: 1,
  pageCount: 0,
  isLoading: false,
  isError: false,
  message: "",
};

//Get users invoices
export const getInvoices = createAsyncThunk(
  "invoices/getInvoices",
  async (
    payload: {
      filterUrl?: string;
      page: number | null;
      customer_email?: string;
      customer_id?: string;
      subscription_id?: string;
    },
    thunkAPI
  ) => {
    const { filterUrl, page, customer_email, customer_id, subscription_id } =
      payload;
    try {
      const response = await invoicesService.getInvoices(
        {
          params: {
            page: page,
            customer_email: customer_email,
            customer_id: customer_id,
            subscription_id: subscription_id,
          },
        },
        filterUrl
      );
      return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Create store slice
export const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //Get invoices
    builder.addCase(getInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.invoices = action.payload;
      state.invoices.count = Math.ceil(action.payload.count / 10);
    });
    builder.addCase(getInvoices.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
  },
});

export const { reset } = invoicesSlice.actions;

export default invoicesSlice.reducer;
