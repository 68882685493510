import api from "../../api/api";

const authUrl = "auth/";
const reportsUrl = "admin_reports/";
const articlesUrl = "admin_articles/";
const customerUrl = "payments/admin_customer/";
const invoiceUrl = "payments/admin_invoice/";
const subscriptionUrl = "payments/admin_subscriptions/";

//Get single user
const getUser = async (id: string | undefined) => {
  const response = await api.get(`${authUrl}users/${id}`);
  return response;
};

//Get single user reports
const getUserReports = async (
  id: string | undefined,
  options: { params: { page: number } }
) => {
  const response = await api.get(`${reportsUrl}user/${id}`, options);
  return response;
};
//Get single user articles
const getUserArticles = async (
  id: string | undefined,
  options: { params: { page: number } }
) => {
  const response = await api.get(`${articlesUrl}user/${id}`, options);
  return response;
};
// Get customer
const getCustomer = async (options: {
  params: { page: number; subscriber_id: string | undefined };
}) => {
  const response = await api.get(
    `${customerUrl}get_customer_by_subscriber_id/`,
    options
  );
  return response;
};
// Get customer
const getCustomerInvoices = async (options: {
  params: { page: number; customer_id: string | undefined };
}) => {
  const response = await api.get(`${invoiceUrl}get_by_customer_id/`, options);
  return response;
};
//Get customer subscriptions
const getCustomerSubscriptions = async (options: {
  params: { page: number; customer_id: string | undefined };
}) => {
  const response = await api.get(
    `${subscriptionUrl}get_by_customer_id/`,
    options
  );
  return response;
};

//Update user
const updateUser = async (id: string | undefined, data: any) => {
  const response = await api.patch(`${authUrl}users/${id}/`, data);
  return response;
};

//Delete user
const deleteUser = async (id: string | undefined) => {
  const response = await api.delete(`${authUrl}users/${id}`);
  return response;
};

const userService = {
  getUser,
  getUserReports,
  getUserArticles,
  getCustomer,
  getCustomerInvoices,
  getCustomerSubscriptions,
  updateUser,
  deleteUser,
};

export default userService;
