import React from "react";

const TableLoadingSkeleton = () => {
  const data = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    name: `Item ${i + 1}`,
    column1: `Data ${i + 1}`,
    column2: `Data ${i + 2}`,
    column3: `Data ${i + 3}`,
    column4: `Data ${i + 4}`,
    column5: `Data ${i + 5}`,
    column6: `Data ${i + 6}`,
    column7: `Data ${i + 7}`,
    column8: `Data ${i + 8}`,
    column9: `Data ${i + 9}`,
    column10: `Data ${i + 10}`,
  }));

  return (
    <div className="pt-4 w-[100%]">
      <div className="h-2" />
      <div className="w-full overflow-x-scroll">
        <table className="w-full border border-grey-400">
          <thead className="bg-grey-200">
            <tr className="text-sm text-gray-600 bg-gray-200 border border-grey-200">
              <th className="p-5 animate-pulse"></th>
              <th className="p-2 animate-pulse"></th>
              <th className="p-2 animate-pulse"></th>
              <th className="p-2 animate-pulse"></th>
              <th className="p-2 animate-pulse"></th>
              <th className="p-2 animate-pulse"></th>
              <th className="p-2 animate-pulse"></th>
              <th className="p-2 animate-pulse"></th>
              <th className="p-2 animate-pulse"></th>
              <th className="p-2 animate-pulse"></th>
              <th className="p-2 animate-pulse"></th>
              <th className="p-2 animate-pulse"></th>
            </tr>
          </thead>
          <tbody className="border border-grey-400 animate-pulse">
            {data.map((item) => (
              <tr
                key={item.id}
                className="text-center border-bodd:bg-white even:bg-grey-200"
              >
                <td className="p-[1.5rem] text-center animate-pulse"></td>
                <td className="p-2 text-center animate-pulse"></td>
                <td className="p-2 text-center animate-pulse"></td>
                <td className="p-2 text-center animate-pulse"></td>
                <td className="p-2 text-center animate-pulse"></td>
                <td className="p-2 text-center animate-pulse"></td>
                <td className="p-2 text-center animate-pulse"></td>
                <td className="p-2 text-center animate-pulse"></td>
                <td className="p-2 text-center animate-pulse"></td>
                <td className="p-2 text-center animate-pulse"></td>
                <td className="p-2 text-center animate-pulse"></td>
                <td className="p-2 text-center animate-pulse"></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="h-2" />
      <div className="flex items-center gap-2 mt-4">
        <button
          className={`flex justify-center justify-self-center p-2 text-white h-min bg-grey-300 tracking-widest shadow-md shadow-grey-200 dark:shadow-lg dark:shadow-grey-400 font-semibold rounded-xl text-md  text-center`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            fill="white"
          >
            <path d="M10.925 18.45 4.475 12l6.45-6.45 1.85 1.85-4.6 4.6 4.6 4.6Zm6.75 0L11.225 12l6.45-6.45 1.85 1.85L14.95 12l4.575 4.6Z" />
          </svg>
        </button>
        <button
          className={`flex justify-center justify-self-center p-2 text-white h-min bg-grey-300 tracking-widest shadow-md shadow-grey-200 dark:shadow-lg dark:shadow-grey-400 font-semibold rounded-xl text-md  text-center`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            fill="white"
          >
            <path d="M14 18.45 7.55 12 14 5.55l1.85 1.85-4.6 4.6 4.6 4.6Z" />
          </svg>
        </button>
        <button
          className={`flex justify-center justify-self-center p-2 text-white h-min bg-grey-300 tracking-widest shadow-md shadow-grey-200 dark:shadow-lg dark:shadow-grey-400 font-semibold rounded-xl text-md  text-center`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            fill="white"
          >
            <path d="M9.4 18.45 7.55 16.6l4.6-4.6-4.6-4.6L9.4 5.55 15.85 12Z" />
          </svg>
        </button>
        <button
          className={`flex justify-center justify-self-center p-2 text-white h-min bg-grey-300 tracking-widest shadow-md shadow-grey-200 dark:shadow-lg dark:shadow-grey-400 font-semibold rounded-xl text-md  text-center`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            fill="white"
          >
            <path d="m6.325 18.45-1.85-1.85L9.05 12 4.475 7.4l1.85-1.85 6.45 6.45Zm6.75 0-1.85-1.85 4.6-4.6-4.6-4.6 1.85-1.85 6.45 6.45Z" />
          </svg>
        </button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>1 of 1</strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            className={` focus:border-robinizeGreen-light w-16 p-1 border rounded focus:outline-none focus:border-2  $
                  `}
          />
        </span>
      </div>
    </div>
  );
};

export default TableLoadingSkeleton;
