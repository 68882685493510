import axiosInstance from "../../api/api";
import { saveAccessToken, sessionExp } from "../../features/auth/authSlice";

//import store type from redux store
import { Store } from "redux";

let store: Store;

export const injectStore = (_store: Store) => {
  store = _store;
};

interface User {
  access: string;
  refresh: string;
}

axiosInstance.interceptors.request.use(
  (config) => {
    let user = localStorage.getItem("user");
    if (typeof user === "string") {
      user = JSON.parse(user);
    }

    let access;

    if (user && typeof user === "object") {
      let castUser = user as User;
      access = castUser.access;
    }

    const token = access;
    if (token) {
      config.headers = config.headers || {};
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    let user = localStorage.getItem("user");
    if (typeof user === "string") {
      user = JSON.parse(user);
    }

    let refresh;

    if (user && typeof user === "object") {
      let castUser = user as User;
      refresh = castUser.refresh;
    }

    if (originalConfig.url !== "/auth/adminlogin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const refreshToken = await axiosInstance.post("/auth/jwt/refresh", {
            refresh: refresh,
          });
          const accessToken = refreshToken.data.access;

          // Save new access token via dispatch action
          store.dispatch(saveAccessToken({ accessToken }) as any);

          return axiosInstance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else if (err.response.status === 403 && !originalConfig._retry) {
        store.dispatch(sessionExp() as any);
      }
    }

    return Promise.reject(err);
  }
);
