import moment from "moment";

var currentDate = getCurrentDate();

//Calculation for table footer
function itemsSpan() {
  if (this.data.results.length === 0) return "No data available";

  let dataLength = this.data.results.length;
  let dataCount = this.data.count;
  let currentdataPage = this.data.links.previous + 1;

  let lastItem = currentdataPage * dataLength;
  let firstItem = lastItem - dataLength + 1;

  if (this.data.links.next === null)
    return `${dataCount - dataLength + 1} - ${dataCount} of ${dataCount}`;

  return `${firstItem} - ${lastItem} of ${dataCount}`;
}

//Get color for status chip
function getColor(status) {
  switch (status) {
    case "Completed":
      return "primary";

    case "active":
      return "primary";

    case "Pending":
      return "#a4a4a4";

    case "Failed":
      return "secondary";

    case "canceled":
      return "secondary";

    default:
      return "a4a4a4";
  }
}

//Get color by content grade
function getContentGradeColor(grade) {
  if (grade >= 0 && grade < 2) {
    return "veryPoorStatus";
  } else if (grade > 1 && grade < 4) {
    return "poorStatus";
  } else if (grade > 3 && grade < 6) {
    return "averageStatus";
  } else if (grade > 5 && grade < 8) {
    return "goodStatus";
  } else if (grade > 7) {
    return "excellentStatus";
  }
}

//Get text color by content grade
function getTextContentGradeColor(grade) {
  if (grade >= 0 && grade < 2) {
    return "veryPoorStatusText";
  } else if (grade > 1 && grade < 4) {
    return "veryPoorStatusText";
  } else if (grade > 3 && grade < 6) {
    return "averageStatusText";
  } else if (grade > 5 && grade < 8) {
    return "veryGoodStatusText";
  } else if (grade > 7) {
    return "amazingStatusText";
  }
}

//Get current date yyyy-mm-dd
function getCurrentDate() {
  return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
}

//Clean vuex module data
function cleanVuexData(state, attributes) {
  //Cleaning date time forms
  attributes.dateTime.forEach((item) => {
    if (attributes.currentItem !== item) {
      state[item] = getCurrentDate();
    }
  });

  //Cleaning text inputs
  attributes.textInput.forEach((item) => {
    if (attributes.currentItem !== item) {
      state[item] = "";
    }
  });

  //Setting default value for selects
  attributes.select.forEach((item) => {
    if (attributes.currentItem !== item.name) {
      state[item.name] = item.default;
    }
  });
}

//converts timestamp from DB to human readable date format
function convertTimestamp(timestamp) {
  // return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
  return moment(timestamp).format("YYYY-MM-DD");
}

function initialize() {
  //Initialize moment JS
  this.moment = moment;
}

export {
  itemsSpan,
  getColor,
  getContentGradeColor,
  getTextContentGradeColor,
  getCurrentDate,
  cleanVuexData,
  convertTimestamp,
  currentDate,
  initialize,
};
