import React, { useState, ChangeEventHandler } from "react";
import { useGetLtvQuery } from "../app/services/lifetime_value/lifetime_value";
import { useGetTopUsersQuery } from "../app/services/top_users/top_users";
import { useGetUsersFunnelQuery } from "../app/services/users_funnel/users_funnel";
import { useGetUsersByDateRegistrationQuery } from "../app/services/charts/date_registration/date_registration";

import { convertTimestamp } from "../common/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, isValid, parse } from "date-fns";
//Components
import { Button } from "../components/Button";
import LTV from "../components/Dashboard/Ltv";
import TopUsersList from "../components/Dashboard/TopUsersList";
import UsersFunnel from "../components/Dashboard/UsersFunnel";
import DateRegistration from "../components/Dashboard/Charts/DateRegistration";
//Spinner component
//import Spinner from "../components/Spinner";
import NewSubscribers from "../components/Dashboard/Charts/NewSubscribers";
import NewSubscriptions from "../components/Dashboard/Charts/NewSubscriptions";
import TotalIncome from "../components/Dashboard/Charts/TotalIncome";
import RegisteredUsersPercentage from "../components/Dashboard/Charts/RegisteredUserPercentage";
//
import { useWindowSize } from "../hooks/useWindowSize";

function Dashboard() {
  ///Date
  type ParamsDateRange = {
    startDate: Date | null | undefined | string;
    endDate: Date | null | undefined | string;
  };
  const currentDate = new Date();
  const twoMonthsAgo = new Date(currentDate);

  twoMonthsAgo.setMonth(currentDate.getMonth() - 6);

  const [startDate, setStartDate] = useState<Date | null>(twoMonthsAgo);
  const [endDate, setEndDate] = useState<Date | null>(currentDate);
  const [startDateString, setStartDateString] = useState<string | null>(
    convertTimestamp(twoMonthsAgo)
  );
  const [endDateString, setEndDateString] = useState<string | null>(
    convertTimestamp(currentDate)
  );
  const [fromError, setFromError] = useState<string | null>("");
  const [toError, setToError] = useState<string | null>("");

  const handleStartChange = (date: Date | null) => {
    setStartDate(date);
    setStartDateString(date ? format(date, "yyyy-MM-dd") : null);
    setFromError(null);
  };

  const handleEndChange = (date: Date | null) => {
    setEndDate(date);
    setEndDateString(date ? format(date, "yyyy-MM-dd") : null);
    setToError(null);
  };
  const handleStartBlur: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value;

    const parsedDateSlash = parse(inputValue, "yyyy/MM/dd", new Date());
    const parsedDateDash = parse(inputValue, "yyyy-MM-dd", new Date());

    // If the date is invalid or the format doesn't match exactly
    if (
      (!isValid(parsedDateSlash) ||
        format(parsedDateSlash, "yyyy/MM/dd") !== inputValue) &&
      (!isValid(parsedDateDash) ||
        format(parsedDateDash, "yyyy-MM-dd") !== inputValue)
    ) {
      setFromError("Invalid date format. Please use YYYY-MM-DD.");
      setStartDate(null);
      setStartDateString(null);
    } else {
      setFromError(null); // clear the error if the format is correct
    }
  };
  const handleEndBlur: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value;

    const parsedDateSlash = parse(inputValue, "yyyy/MM/dd", new Date());
    const parsedDateDash = parse(inputValue, "yyyy-MM-dd", new Date());

    // If the date is invalid or the format doesn't match exactly
    if (
      (!isValid(parsedDateSlash) ||
        format(parsedDateSlash, "yyyy/MM/dd") !== inputValue) &&
      (!isValid(parsedDateDash) ||
        format(parsedDateDash, "yyyy-MM-dd") !== inputValue)
    ) {
      setToError("Invalid date format.Please use YYYY-MM-DD.");
      setEndDate(null);
      setEndDateString(null);
    } else {
      setToError(null); // clear the error if the format is correct
    }
  };
  const handleStartFocus: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFromError(null);
  };
  const handleEndFocus: ChangeEventHandler<HTMLInputElement> = (event) => {
    setToError(null);
  };

  /////////////////
  const getUsers = () => {
    const newParams = {
      ...params,
      startDate: startDateString,
      endDate: endDateString,
    };
    setParams(newParams);
  };

  const [params, setParams] = useState<ParamsDateRange>({
    startDate: startDateString,
    endDate: endDateString,
  });

  //Detect window size for charts
  const { height } = useWindowSize();

  const chartHeight = height * 0.4;

  const {
    data: ltv,
    isLoading: isLtvLoading,
    isFetching: isLtvFetching,
  } = useGetLtvQuery("ltv");

  const { isFetching: isTopUsersFetching } = useGetTopUsersQuery({
    startDate: params.startDate,
    endDate: params.endDate,
  });

  const { isFetching: isDateRegistrationFetching } =
    useGetUsersByDateRegistrationQuery({
      startDate: params.startDate,
      endDate: params.endDate,
    });

  const { data, isFetching: isUsersFunnelFetching } = useGetUsersFunnelQuery({
    startDate: params.startDate,
    endDate: params.endDate,
  });

  const isFetching =
    isLtvFetching ||
    isTopUsersFetching ||
    isDateRegistrationFetching ||
    isUsersFunnelFetching;

  return (
    <section className="heading">
      <h1 className="font-semibold">ADMIN DASHBOARD</h1>
      <hr className="my-4" />
      <div className="flex mt-12 ">
        <div className="relative mr-2 datepicker">
          <span className="block">Start date</span>
          <DatePicker
            selected={startDate}
            onChange={handleStartChange}
            dateFormat="yyyy/MM/dd"
            placeholderText="Format: YYYY-MM-DD"
            maxDate={endDate}
            onBlur={handleStartBlur}
            onFocus={handleStartFocus}
            startDate={startDate}
            endDate={endDate}
          />
          {fromError && (
            <span className="absolute top-[70px] block text-sm font-medium text-red-400">
              Invalid date format.
              <br />
              Please use YYYY-MM-DD.
            </span>
          )}
        </div>

        <div className="relative mr-4 datepicker">
          <span className="block">End date</span>
          <DatePicker
            selected={endDate}
            onChange={handleEndChange}
            dateFormat="yyyy/MM/dd"
            placeholderText="Format: YYYY-MM-DD"
            maxDate={new Date()}
            onBlur={handleEndBlur}
            onFocus={handleEndFocus}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
          {toError && (
            <span className="absolute top-[70px] block text-sm font-medium text-red-400">
              Invalid date format.
              <br />
              Please use YYYY-MM-DD.
            </span>
          )}
        </div>

        <div className="ml-4">
          <span className="text-transparent">holder</span>
          <Button
            label="Submit"
            width={"8rem"}
            margin={"2px 0 0 0"}
            onClick={getUsers}
            disabled={
              !startDate || !endDate || fromError || toError ? true : false
            }
            requestPending={isFetching}
          />
        </div>
      </div>
      {/* <div className="grid grid-cols-4 gap-6 my-10"> */}
      <div className="grid grid-cols-[6fr_6fr] gap-6 my-10">
        <LTV />
        <UsersFunnel
          startDate={params.startDate}
          endDate={params.endDate}
          height={chartHeight.toString()}
        />
      </div>
      <DateRegistration
        startDate={params.startDate}
        endDate={params.endDate}
        height={chartHeight}
      />
      <NewSubscribers
        startDate={params.startDate}
        endDate={params.endDate}
        height={chartHeight}
      />
      <NewSubscriptions
        startDate={params.startDate}
        endDate={params.endDate}
        height={chartHeight}
      />
      <TotalIncome
        startDate={params.startDate}
        endDate={params.endDate}
        height={chartHeight}
      />

      <div className="grid grid-cols-1 gap-4 2xl:grid-cols-2">
        <RegisteredUsersPercentage
          startDate={params.startDate}
          endDate={params.endDate}
          height={chartHeight}
        />
        <TopUsersList
          startDate={params.startDate}
          endDate={params.endDate}
          height={chartHeight}
        />
      </div>
    </section>
  );
}

export default Dashboard;
