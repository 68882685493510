interface Props {
  onClick?: () => void;
  rotate?: boolean;
}

export const ExpandMore: React.FC<Props> = ({ onClick, rotate }) => (
  <svg
    onClick={onClick}
    className={`cursor-pointer fill-robinizeBlack-light ${
      rotate ? "rotate-180 transition" : "rotate-0 transition"
    }`}
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    width="24"
  >
    <path className="p-2" d="m12 15.375-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4Z" />
  </svg>
);

export const CircleArrow: React.FC<Props> = ({ rotate }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    width="24"
    className={`cursor-pointer fill-robinizeBlack-light ${
      rotate ? "rotate-180 transition" : "rotate-0 transition"
    }`}
  >
    <path d="m12 16 1.4-1.4-1.6-1.6H16v-2h-4.2l1.6-1.6L12 8l-4 4Zm0 6q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z" />
  </svg>
);
export const Logout: React.FC<Props> = ({ onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    width="24"
    onClick={onClick}
    className="inline cursor-pointer"
    fill="white"
  >
    <path d="M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h7v2H5v14h7v2Zm11-4-1.375-1.45 2.55-2.55H9v-2h8.175l-2.55-2.55L16 7l5 5Z" />
  </svg>
);
export const Reports = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
    <path d="M7 17h2v-5H7Zm8 0h2V7h-2Zm-4 0h2v-3h-2Zm0-5h2v-2h-2Zm-6 9q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h14q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21Zm0-2h14V5H5v14ZM5 5v14V5Z" />
  </svg>
);

export const Users = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
    <path d="M1 20v-2.8q0-.85.438-1.563.437-.712 1.162-1.087 1.55-.775 3.15-1.163Q7.35 13 9 13t3.25.387q1.6.388 3.15 1.163.725.375 1.162 1.087Q17 16.35 17 17.2V20Zm18 0v-3q0-1.1-.612-2.113-.613-1.012-1.738-1.737 1.275.15 2.4.512 1.125.363 2.1.888.9.5 1.375 1.112Q23 16.275 23 17v3ZM9 12q-1.65 0-2.825-1.175Q5 9.65 5 8q0-1.65 1.175-2.825Q7.35 4 9 4q1.65 0 2.825 1.175Q13 6.35 13 8q0 1.65-1.175 2.825Q10.65 12 9 12Zm10-4q0 1.65-1.175 2.825Q16.65 12 15 12q-.275 0-.7-.062-.425-.063-.7-.138.675-.8 1.037-1.775Q15 9.05 15 8q0-1.05-.363-2.025Q14.275 5 13.6 4.2q.35-.125.7-.163Q14.65 4 15 4q1.65 0 2.825 1.175Q19 6.35 19 8ZM3 18h12v-.8q0-.275-.137-.5-.138-.225-.363-.35-1.35-.675-2.725-1.013Q10.4 15 9 15t-2.775.337Q4.85 15.675 3.5 16.35q-.225.125-.362.35-.138.225-.138.5Zm6-8q.825 0 1.413-.588Q11 8.825 11 8t-.587-1.412Q9.825 6 9 6q-.825 0-1.412.588Q7 7.175 7 8t.588 1.412Q8.175 10 9 10Zm0 8ZM9 8Z" />
  </svg>
);

export const Dashboard = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
    <path d="M13 9V3h8v6ZM3 13V3h8v10Zm10 8V11h8v10ZM3 21v-6h8v6Zm2-10h4V5H5Zm10 8h4v-6h-4Zm0-12h4V5h-4ZM5 19h4v-2H5Zm4-8Zm6-4Zm0 6Zm-6 4Z" />
  </svg>
);
export const Articles = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
    <path d="M8 18h8v-2H8Zm0-4h8v-2H8Zm-2 8q-.825 0-1.412-.587Q4 20.825 4 20V4q0-.825.588-1.413Q5.175 2 6 2h8l6 6v12q0 .825-.587 1.413Q18.825 22 18 22Zm7-13V4H6v16h12V9ZM6 4v5-5 16V4Z" />
  </svg>
);
export const Payments = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
    <path d="M15 21q-2.95 0-5.25-1.675T6.5 15H3v-2h3.05Q6 12.725 6 12.5v-1q0-.225.05-.5H3V9h3.5q.95-2.65 3.25-4.325Q12.05 3 15 3q1.725 0 3.263.6Q19.8 4.2 21 5.3l-1.75 1.75q-.875-.725-1.962-1.138Q16.2 5.5 15 5.5q-1.875 0-3.412.963Q10.05 7.425 9.25 9H15v2H8.6q-.05.275-.075.5-.025.225-.025.5t.025.5q.025.225.075.5H15v2H9.25q.8 1.575 2.338 2.538 1.537.962 3.412.962 1.2 0 2.312-.413 1.113-.412 1.938-1.137L21 18.7q-1.2 1.1-2.737 1.7-1.538.6-3.263.6Z" />
  </svg>
);
export const Invoices = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
    <path d="M6 22q-1.25 0-2.125-.875T3 19v-3h3V2l1.5 1.5L9 2l1.5 1.5L12 2l1.5 1.5L15 2l1.5 1.5L18 2l1.5 1.5L21 2v17q0 1.25-.875 2.125T18 22Zm12-2q.425 0 .712-.288Q19 19.425 19 19V5H8v11h9v3q0 .425.288.712.287.288.712.288ZM9 9V7h6v2Zm0 3v-2h6v2Zm8-3q-.425 0-.712-.288Q16 8.425 16 8t.288-.713Q16.575 7 17 7t.712.287Q18 7.575 18 8t-.288.712Q17.425 9 17 9Zm0 3q-.425 0-.712-.288Q16 11.425 16 11t.288-.713Q16.575 10 17 10t.712.287Q18 10.575 18 11t-.288.712Q17.425 12 17 12ZM6 20h9v-2H5v1q0 .425.287.712Q5.575 20 6 20Zm-1 0v-2 2Z" />
  </svg>
);
export const Plans = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
    <path d="M4 21q-1.25 0-2.125-.875T1 18q0-1.25.875-2.125T4 15q.225 0 .438.037.212.038.412.088L8.9 9.55q-.425-.525-.662-1.175Q8 7.725 8 7q0-1.65 1.175-2.825Q10.35 3 12 3q1.65 0 2.825 1.175Q16 5.35 16 7q0 .725-.25 1.375t-.675 1.175l4.075 5.575q.2-.05.412-.088Q19.775 15 20 15q1.25 0 2.125.875T23 18q0 1.25-.875 2.125T20 21q-1.25 0-2.125-.875T17 18q0-.475.138-.913.137-.437.387-.787l-4.05-5.575q-.125.05-.237.075-.113.025-.238.075v4.3q.875.3 1.438 1.075Q15 17.025 15 18q0 1.25-.875 2.125T12 21q-1.25 0-2.125-.875T9 18q0-.975.562-1.738.563-.762 1.438-1.087v-4.3q-.125-.05-.238-.075-.112-.025-.237-.075L6.475 16.3q.25.35.388.787Q7 17.525 7 18q0 1.25-.875 2.125T4 21Zm0-2q.425 0 .713-.288Q5 18.425 5 18t-.287-.712Q4.425 17 4 17t-.712.288Q3 17.575 3 18t.288.712Q3.575 19 4 19Zm8-12Zm0 12q.425 0 .713-.288Q13 18.425 13 18t-.287-.712Q12.425 17 12 17t-.712.288Q11 17.575 11 18t.288.712Q11.575 19 12 19Zm8 0q.425 0 .712-.288Q21 18.425 21 18t-.288-.712Q20.425 17 20 17t-.712.288Q19 17.575 19 18t.288.712Q19.575 19 20 19ZM4 18Zm8 0Zm8 0Zm-8-9q.825 0 1.413-.588Q14 7.825 14 7t-.587-1.412Q12.825 5 12 5q-.825 0-1.412.588Q10 6.175 10 7t.588 1.412Q11.175 9 12 9Z" />
  </svg>
);
export const Subscriptions = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
    <path d="M4 22q-.825 0-1.412-.587Q2 20.825 2 20V10q0-.825.588-1.413Q3.175 8 4 8h16q.825 0 1.413.587Q22 9.175 22 10v10q0 .825-.587 1.413Q20.825 22 20 22Zm0-2h16V10H4v10Zm6-1 6-4-6-4ZM4 7V5h16v2Zm3-3V2h10v2ZM4 20V10v10Z" />
  </svg>
);
export const Download = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="#1CC695">
    <path d="M6 20q-.825 0-1.412-.587Q4 18.825 4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413Q18.825 20 18 20Zm6-4-5-5 1.4-1.45 2.6 2.6V4h2v8.15l2.6-2.6L17 11Z" />
  </svg>
);
export const LinkToPage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="#1CC695">
    <path d="M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h7v2H5v14h14v-7h2v7q0 .825-.587 1.413Q19.825 21 19 21Zm4.7-5.3-1.4-1.4L17.6 5H14V3h7v7h-2V6.4Z" />
  </svg>
);
export const Reset: React.FC<Props> = ({ onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    width="24"
    onClick={onClick}
    className="inline rounded-full cursor-pointer bg-robinizeGreen-light"
    fill="white"
  >
    <path d="M11 20.95q-3.025-.375-5.012-2.638Q4 16.05 4 13q0-1.65.65-3.163Q5.3 8.325 6.5 7.2l1.425 1.425q-.95.85-1.437 1.975Q6 11.725 6 13q0 2.2 1.4 3.887 1.4 1.688 3.6 2.063Zm2 0v-2q2.175-.4 3.587-2.075Q18 15.2 18 13q0-2.5-1.75-4.25T12 7h-.075l1.1 1.1-1.4 1.4-3.5-3.5 3.5-3.5 1.4 1.4-1.1 1.1H12q3.35 0 5.675 2.325Q20 9.65 20 13q0 3.025-1.987 5.288Q16.025 20.55 13 20.95Z" />
  </svg>
);
