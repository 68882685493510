import { Routes, Route, Navigate } from "react-router-dom";
//Get user auth state from redux
import { useAppDispatch, useAppSelector } from "./app/hooks";
//
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Pages
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Users from "./pages/Users";
import User from "./pages/User";
import Reports from "./pages/Reports";
import Articles from "./pages/Articles";
import Invoices from "./pages/payments/Invoices";
import Plans from "./pages/payments/Plans";
import Subscriptions from "./pages/payments/Subscriptions";
import Navigation from "./components/SidebarNavigation/Navigation";
import Header from "./components/Header";
import { Modal } from "./components/Modal";

import { logout, sessionDialog } from "./features/auth/authSlice";

function App() {
  const { sessionDialogModal, user: getUser } = useAppSelector(
    (state) => state.auth
  );
  const { isSidebarOpen } = useAppSelector((state) => state.sidebar);

  const dispatch = useAppDispatch();

  // Check login status
  const user = localStorage.getItem("user");

  return (
    <>
      <div
        className="relative z-10 grid min-h-[101vh] mx-auto transition-all duration-500 bg-white auto-rows-min"
        style={{
          display: "grid",
          gridTemplateColumns: `${isSidebarOpen ? "14rem" : "70px"} 11fr`,
          gridTemplateRows: "7vh 11fr",
        }}
      >
        <Modal
          isOpen={sessionDialogModal}
          onClick={() => {
            dispatch(sessionDialog(false));
            //dispatch(logout());
          }}
        />
        {user && <Navigation />}
        {user && <Header />}
        <main
          className={`h-full w-full overflow-x-hidden duration-500 text-robonizeBlack-light p-6 ml-auto`}
          style={{ gridColumn: "2" }}
        >
          <Routes>
            <Route path="/auth/login" element={<Login />} />
            <Route
              path="/"
              element={
                user ? <Dashboard /> : <Navigate replace to={"/auth/login"} />
              }
            />
            <Route
              path="/users"
              element={
                user ? <Users /> : <Navigate replace to={"/auth/login"} />
              }
            />
            <Route
              path="/users/:id"
              element={
                user ? <User /> : <Navigate replace to={"/auth/login"} />
              }
            />
            <Route
              path="/reports"
              element={
                user ? <Reports /> : <Navigate replace to={"/auth/login"} />
              }
            />
            <Route
              path="/articles"
              element={
                user ? <Articles /> : <Navigate replace to={"/auth/login"} />
              }
            />
            <Route
              path="/invoices"
              element={
                user ? <Invoices /> : <Navigate replace to={"/auth/login"} />
              }
            />
            <Route
              path="/plans"
              element={
                user ? <Plans /> : <Navigate replace to={"/auth/login"} />
              }
            />
            <Route
              path="/subscriptions"
              element={
                user ? (
                  <Subscriptions />
                ) : (
                  <Navigate replace to={"/auth/login"} />
                )
              }
            />
          </Routes>
        </main>
      </div>
      <ToastContainer transition={Slide} />
    </>
  );
}

export default App;
