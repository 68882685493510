import { createApi } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../reducers";
import { axiosBaseQuery } from "../../axiosBaseQuery";

export const plansApi = createApi({
  reducerPath: "plansApi",
  baseQuery: (args, api) => {
    const state = api.getState() as RootState;

    const token = state.auth.access;

    let headers: Record<string, string> = {};
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    return axiosBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL,
    })({ ...args, headers });
  },
  endpoints: (builder) => ({
    getPlans: builder.query({
      query: () => ({
        url: "payments/subscriptions/",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetPlansQuery } = plansApi;
