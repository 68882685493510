import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Menu from "./Menu";
import { CircleArrow } from "../../assets/icons";
import { toggleSidebar } from "../../features/sidebar/sidebarSlice";

function Navigation() {
  const dispatch = useAppDispatch();
  const { isSidebarOpen } = useAppSelector((state) => state.sidebar);

  return (
    <nav
      className="flex gap-6 h-full z-10"
      style={{ gridColumn: "1", gridRow: "1/3" }}
    >
      <div
        className={`h-full w-full bg-grey-200 duration-600 text-fill-robinizeBlack-light px-4`}
      >
        <div
          className={`group relativ p-2 flex mt-3 hover:bg-grey-300 rounded-full ${
            isSidebarOpen
              ? "justify-end transition-all"
              : "justify-center transition-all"
          }`}
          onClick={() => dispatch(toggleSidebar(!isSidebarOpen))}
        >
          <CircleArrow rotate={isSidebarOpen ? false : true} />
          <p
            className={`absolute left-[4rem] ${
              isSidebarOpen ? "group-hover:left-[5rem]" : "group-hover:left-12"
            }  top-2 bg-white text-sm font-normal whitespace-pre text-robinizeBlack-light rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-[4rem] group-hover:duration-300 group-hover:w-fit`}
          >
            {isSidebarOpen ? "Hide menu" : "Expand menu"}
          </p>
        </div>
        <div className="mt-4 flex flex-col gap-4 relative">
          <Menu isSidebarOpen={isSidebarOpen} />
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
