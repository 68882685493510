import React, { useState, useEffect, ChangeEventHandler, useRef } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  getReports,
  reset,
  ReportsState,
} from "../features/reports/reportsSlice";
import Table from "../components/Table/Table";
import { ColumnDef } from "@tanstack/react-table";
import {
  ReportsDataInterface,
  ReportsColumns,
} from "../components/Table/Columns/Reports/ReportsColumns";
import TableLoadingSkeleton from "../components/Table/TableLoadingSkeleton";
////
import DatePicker from "react-datepicker";
import { format, isValid, parse } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { convertTimestamp } from "../common/utils";
import { Button } from "../components/Button";
import Spinner from "../components/Spinner";

function Reports() {
  const dispatch = useAppDispatch();

  const { reports, pageNumber, isLoading, isError, message } = useAppSelector(
    (state) => state.reports
  );

  useEffect(() => {
    if (isError) {
      setActiveURL("");
      setActiveFilter("");
      setActiveParams(null);
    }
    dispatch(getReports({ page: 1 }));

    return () => {
      dispatch(reset());
    };
  }, [isError, message, dispatch]);

  window.scrollTo({ top: 0, behavior: "smooth" });
  //ReportsColumns
  const ReportsCols = React.useMemo<ColumnDef<ReportsDataInterface>[]>(
    () => ReportsColumns,
    []
  );
  ////
  const languages = [
    {
      lang: "en",
      name: "English",
    },
    {
      lang: "sv",
      name: "Swedish",
    },
    {
      lang: "de",
      name: "German",
    },
    {
      lang: "nb",
      name: "Norweigan",
    },
    {
      lang: "fr",
      name: "French",
    },
    {
      lang: "it",
      name: "Italian",
    },
    {
      lang: "es",
      name: "Spanish",
    },
    {
      lang: "pt",
      name: "Portugese",
    },
    {
      lang: "nl",
      name: "Dutch",
    },
    {
      lang: "da",
      name: "Danish",
    },
  ];
  const countriesList = [
    {
      name: "Afghanistan",
    },
    {
      name: "Albania",
    },
    {
      name: "Algeria",
    },
    {
      name: "American Samoa",
    },
    {
      name: "Antigua and Barbuda",
    },
    {
      name: "Andorra",
    },
    {
      name: "Anguilla",
    },
    {
      name: "Angola",
    },
    {
      name: "Antarctica",
    },
    {
      name: "Aruba",
    },
    {
      name: "Argentina",
    },
    {
      name: "Armenia",
    },
    {
      name: "Australia",
    },
    {
      name: "Austria",
    },
    {
      name: "Azerbaijan",
    },
    {
      name: "Barbados",
    },
    {
      name: "Bangladesh",
    },
    {
      name: "Bahrain",
    },
    {
      name: "Bahamas",
    },
    {
      name: "Belgium",
    },
    {
      name: "Bermuda",
    },
    {
      name: "Belize",
    },
    {
      name: "Benin",
    },
    {
      name: "Belarus",
    },
    {
      name: "Bhutan",
    },
    {
      name: "Bouvet Island",
    },
    {
      name: "Bosnia and Herzegovina",
    },
    {
      name: "Botswana",
    },
    {
      name: "Bolivia",
    },
    {
      name: "British Indian Ocean Territory",
    },
    {
      name: "British Virgin Islands",
    },
    {
      name: "Brazil",
    },
    {
      name: "Brunei",
    },
    {
      name: "Burundi",
    },
    {
      name: "Burkina Faso",
    },
    {
      name: "Bulgaria",
    },
    {
      name: "Cambodia",
    },
    {
      name: "Canada",
    },
    {
      name: "Cape Verde",
    },
    {
      name: "Caribbean Netherlands",
    },
    {
      name: "Cayman Islands",
    },
    {
      name: "Cameroon",
    },
    {
      name: "Central African Republic",
    },
    {
      name: "Chad",
    },
    {
      name: "Chile",
    },
    {
      name: "China",
    },
    {
      name: "Christmas Island",
    },
    {
      name: "Costa Rica",
    },
    {
      name: "Cocos (Keeling) Islands",
    },
    {
      name: "Colombia",
    },
    {
      name: "Comoros",
    },
    {
      name: "Cook Islands",
    },
    {
      name: "Croatia",
    },
    {
      name: "Cuba",
    },
    {
      name: "Curaçao",
    },
    {
      name: "Cyprus",
    },
    {
      name: "Czechia",
    },
    {
      name: "Denmark",
    },
    {
      name: "Djibouti",
    },
    {
      name: "Dominican Republic",
    },
    {
      name: "Dominica",
    },
    {
      name: "DR Congo",
    },
    {
      name: "Ecuador",
    },
    {
      name: "Egypt",
    },
    {
      name: "El Salvador",
    },
    {
      name: "Equatorial Guinea",
    },
    {
      name: "Eritrea",
    },
    {
      name: "Estonia",
    },
    {
      name: "Eswatini",
    },
    {
      name: "Ethiopia",
    },
    {
      name: "Faroe Islands",
    },
    {
      name: "Falkland Islands",
    },
    {
      name: "Finland",
    },
    {
      name: "Fiji",
    },
    {
      name: "French Polynesia",
    },
    {
      name: "French Guiana",
    },
    {
      name: "France",
    },
    {
      name: "French Southern and Antarctic Lands",
    },
    {
      name: "Gambia",
    },
    {
      name: "Gabon",
    },
    {
      name: "Georgia",
    },
    {
      name: "Germany",
    },
    {
      name: "Ghana",
    },
    {
      name: "Gibraltar",
    },
    {
      name: "Greenland",
    },
    {
      name: "Greece",
    },
    {
      name: "Grenada",
    },
    {
      name: "Guernsey",
    },
    {
      name: "Guinea-Bissau",
    },
    {
      name: "Guam",
    },
    {
      name: "Guinea",
    },
    {
      name: "Guatemala",
    },
    {
      name: "Guyana",
    },
    {
      name: "Guadeloupe",
    },
    {
      name: "Haiti",
    },
    {
      name: "Heard Island and McDonald Islands",
    },
    {
      name: "Honduras",
    },
    {
      name: "Hong Kong",
    },
    {
      name: "Hungary",
    },
    {
      name: "Iceland",
    },
    {
      name: "India",
    },
    {
      name: "Indonesia",
    },
    {
      name: "Iraq",
    },
    {
      name: "Ireland",
    },
    {
      name: "Iran",
    },
    {
      name: "Isle of Man",
    },
    {
      name: "Israel",
    },
    {
      name: "Italy",
    },
    {
      name: "Ivory Coast",
    },
    {
      name: "Jamaica",
    },
    {
      name: "Japan",
    },
    {
      name: "Jersey",
    },
    {
      name: "Jordan",
    },
    {
      name: "Kazakhstan",
    },
    {
      name: "Kenya",
    },
    {
      name: "Kiribati",
    },
    {
      name: "Kosovo",
    },
    {
      name: "Kuwait",
    },
    {
      name: "Kyrgyzstan",
    },
    {
      name: "Latvia",
    },
    {
      name: "Laos",
    },
    {
      name: "Lebanon",
    },
    {
      name: "Lesotho",
    },
    {
      name: "Liechtenstein",
    },
    {
      name: "Lithuania",
    },
    {
      name: "Libya",
    },
    {
      name: "Liberia",
    },
    {
      name: "Luxembourg",
    },
    {
      name: "Mauritius",
    },
    {
      name: "Mayotte",
    },
    {
      name: "Malaysia",
    },
    {
      name: "Malawi",
    },
    {
      name: "Martinique",
    },
    {
      name: "Macau",
    },
    {
      name: "Mauritania",
    },
    {
      name: "Marshall Islands",
    },
    {
      name: "Mali",
    },
    {
      name: "Malta",
    },
    {
      name: "Maldives",
    },
    {
      name: "Madagascar",
    },
    {
      name: "Mexico",
    },
    {
      name: "Micronesia",
    },
    {
      name: "Montenegro",
    },
    {
      name: "Montserrat",
    },
    {
      name: "Mozambique",
    },
    {
      name: "Morocco",
    },
    {
      name: "Monaco",
    },
    {
      name: "Moldova",
    },
    {
      name: "Mongolia",
    },
    {
      name: "Myanmar",
    },
    {
      name: "Namibia",
    },
    {
      name: "Nauru",
    },
    {
      name: "Netherlands",
    },
    {
      name: "New Zealand",
    },
    {
      name: "Nepal",
    },
    {
      name: "New Caledonia",
    },
    {
      name: "Nigeria",
    },
    {
      name: "Niger",
    },
    {
      name: "Niue",
    },
    {
      name: "Nicaragua",
    },
    {
      name: "Norway",
    },
    {
      name: "Norfolk Island",
    },
    {
      name: "Northern Mariana Islands",
    },
    {
      name: "North Macedonia",
    },
    {
      name: "North Korea",
    },
    {
      name: "Oman",
    },
    {
      name: "Paraguay",
    },
    {
      name: "Panama",
    },
    {
      name: "Palau",
    },
    {
      name: "Pakistan",
    },
    {
      name: "Palestine",
    },
    {
      name: "Papua New Guinea",
    },
    {
      name: "Peru",
    },
    {
      name: "Philippines",
    },
    {
      name: "Pitcairn Islands",
    },
    {
      name: "Portugal",
    },
    {
      name: "Poland",
    },
    {
      name: "Puerto Rico",
    },
    {
      name: "Qatar",
    },
    {
      name: "Republic of the Congo",
    },
    {
      name: "Romania",
    },
    {
      name: "Russia",
    },
    {
      name: "Rwanda",
    },
    {
      name: "Réunion",
    },
    {
      name: "Saint Vincent and the Grenadines",
    },
    {
      name: "Saint Barthélemy",
    },
    {
      name: "Saint Pierre and Miquelon",
    },
    {
      name: "San Marino",
    },
    {
      name: "Saudi Arabia",
    },
    {
      name: "Saint Kitts and Nevis",
    },
    {
      name: "Saint Helena, Ascension and Tristan da Cunha",
    },
    {
      name: "Saint Lucia",
    },
    {
      name: "Saint Martin",
    },
    {
      name: "Samoa",
    },
    {
      name: "Senegal",
    },
    {
      name: "Serbia",
    },
    {
      name: "Seychelles",
    },
    {
      name: "Singapore",
    },
    {
      name: "Sierra Leone",
    },
    {
      name: "Sint Maarten",
    },
    {
      name: "Slovakia",
    },
    {
      name: "Slovenia",
    },
    {
      name: "South Korea",
    },
    {
      name: "South Georgia",
    },
    {
      name: "South Sudan",
    },
    {
      name: "Somalia",
    },
    {
      name: "Solomon Islands",
    },
    {
      name: "South Africa",
    },
    {
      name: "Spain",
    },
    {
      name: "Sri Lanka",
    },
    {
      name: "Sudan",
    },
    {
      name: "Suriname",
    },
    {
      name: "Svalbard and Jan Mayen",
    },
    {
      name: "Sweden",
    },
    {
      name: "Switzerland",
    },
    {
      name: "Syria",
    },
    {
      name: "São Tomé and Príncipe",
    },
    {
      name: "Tanzania",
    },
    {
      name: "Tajikistan",
    },
    {
      name: "Taiwan",
    },
    {
      name: "Thailand",
    },
    {
      name: "Timor-Leste",
    },
    {
      name: "Tonga",
    },
    {
      name: "Tokelau",
    },
    {
      name: "Togo",
    },
    {
      name: "Trinidad and Tobago",
    },
    {
      name: "Tuvalu",
    },
    {
      name: "Tunisia",
    },
    {
      name: "Turkey",
    },
    {
      name: "Turks and Caicos Islands",
    },
    {
      name: "Turkmenistan",
    },
    {
      name: "Uganda",
    },
    {
      name: "Ukraine",
    },
    {
      name: "United Kingdom",
    },
    {
      name: "United States Virgin Islands",
    },
    {
      name: "United States",
    },
    {
      name: "United Arab Emirates",
    },
    {
      name: "United States Minor Outlying Islands",
    },
    {
      name: "Uruguay",
    },
    {
      name: "Uzbekistan",
    },
    {
      name: "Vanuatu",
    },
    {
      name: "Vatican City",
    },
    {
      name: "Venezuela",
    },
    {
      name: "Vietnam",
    },
    {
      name: "Wallis and Futuna",
    },
    {
      name: "Western Sahara",
    },
    {
      name: "Yemen",
    },
    {
      name: "Zambia",
    },
    {
      name: "Zimbabwe",
    },
    {
      name: "Åland Islands",
    },
  ];
  const options = countriesList.map((country) => ({
    value: country.name,
    label: country.name,
  }));

  const readabilityOptions = [
    { option: "Very Easy" },
    { option: "Easy" },
    { option: "Fairly Easy" },
    { option: "Standard" },
    { option: "Fairly Difficult" },
    { option: "Difficult" },
    { option: "Very Confusing" },
  ];

  //const countriesList: CountryType[] = countriesRaw as any;
  //TS
  interface State {
    createdDate: Date | null;
    user_email: string;
    keyword: string;
    countrySelectValue: string;
    languageSelectValue: string;
    word_count: number | undefined;
    readability: string;
    ///
    activeFilter: string;
    activeURL: string;
    placeholder: string;
    error: string;
  }

  //Ref autocomplete
  const inputRef = useRef<HTMLInputElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);

  // Handle Tab key press to focus on the first item in the ul list
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Tab" &&
      customerEmail.length >= 3 &&
      reports?.results.length > 0
    ) {
      e.preventDefault();
      const firstChild = ulRef.current?.firstChild as HTMLLIElement | null;
      firstChild?.focus();
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    // If the click is outside the input and the ul, close the ul
    if (
      inputRef.current &&
      !inputRef.current.contains(e.target as Node) &&
      ulRef.current &&
      !ulRef.current.contains(e.target as Node)
    ) {
      setOpenUL(false);
      // set your state to close the ul (e.g., emptying reports or setting customerEmail length below 3)
      // setReports({ results: [] });
    }
  };
  const handleLiKeyDown = (
    email: string,
    e: React.KeyboardEvent<HTMLLIElement>
  ) => {
    if (e.key === "Enter") {
      setCustomerEmail(email);
      setOpenUL(false);
    }
  };

  useEffect(() => {
    // Attach global click listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup the listener when the component is unmounted
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //set params for dispatch action based on filter
  const [activeParams, setActiveParams] = useState<any>(null);

  //set active filter option
  const [activeFilter, setActiveFilter] =
    useState<State["activeFilter"]>("all");

  //set active filter url
  const [activeURL, setActiveURL] = useState<State["activeURL"]>("");

  //Input filter
  const [customerEmail, setCustomerEmail] = useState<State["user_email"]>("");
  const [openUL, setOpenUL] = useState<boolean>(true);
  //Input filter
  const [minWordCount, setMinWordCount] =
    useState<State["word_count"]>(undefined);
  //const [minError, setMinError] = useState<string>("");
  const [maxWordCount, setMaxWordCount] =
    useState<State["word_count"]>(undefined);
  const [maxError, setMaxError] = useState<string>("");
  //Input filter
  const [keyWord, setKeyWord] = useState<State["keyword"]>("");
  //Input placeholder
  const [placeholder, setPlaceholder] = useState<State["placeholder"]>("");
  //select values
  const [countrySelectValue, setCountrySelectValue] = useState<any>(options[0]);
  const [languageSelectValue, setLanguageSelectValue] = useState<
    State["languageSelectValue"] | undefined
  >(languages[0].lang);
  const [readabilitySelectValue, setReadabilitySelectValue] = useState<
    State["readability"] | undefined
  >(readabilityOptions[0].option);
  //error if search term is empty
  const [error, setError] = useState<State["error"]>("");

  //change role filter
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "") {
      dispatch(getReports({ page: 1 }));
      setActiveParams(null);
    }
    setActiveURL(e.target.value);
    setActiveFilter(
      e.target.options[e.target.selectedIndex].dataset.type || ""
    );
    setPlaceholder(
      e.target.options[e.target.selectedIndex].dataset.placeholder || ""
    );
    // setCustomerEmail("");
    // setStartDate(null);
    // setEndDate(null);
    // setLanguageSelectValue(undefined);
    // setCountrySelectValue(undefined);
    //dodati na listu preostale...
  };
  //Autocomplete
  function getUniqueEmails(reports: ReportsState["reports"]) {
    const uniqueEmailsSet = new Set();
    return reports?.results.filter((creator) => {
      const isUnique = !uniqueEmailsSet.has(creator.user_email);
      uniqueEmailsSet.add(creator.user_email);
      return isUnique;
    });
  }

  useEffect(() => {
    if (customerEmail.length >= 3) {
      setActiveParams({
        page: pageNumber,
        user_email: customerEmail,
        filterUrl: "get_by_user_email/",
      });
      dispatch(
        getReports({
          page: 1,
          user_email: customerEmail,
          filterUrl: "get_by_user_email/",
        })
      );
    }
  }, [customerEmail]);
  ///Date
  const [startDate, setStartDate] = useState<State["createdDate"]>(null);
  const [endDate, setEndDate] = useState<State["createdDate"]>(null);
  const [fromError, setFromError] = useState<string | null>("");
  const [toError, setToError] = useState<string | null>("");

  const handleStartChange = (date: Date | null) => {
    setStartDate(date);
    setFromError(null);
  };

  const handleEndChange = (date: Date | null) => {
    setEndDate(date);
    setToError(null);
  };
  const handleStartBlur: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value;

    const parsedDateSlash = parse(inputValue, "yyyy/MM/dd", new Date());
    const parsedDateDash = parse(inputValue, "yyyy-MM-dd", new Date());

    // If the date is invalid or the format doesn't match exactly
    if (
      (!isValid(parsedDateSlash) ||
        format(parsedDateSlash, "yyyy/MM/dd") !== inputValue) &&
      (!isValid(parsedDateDash) ||
        format(parsedDateDash, "yyyy-MM-dd") !== inputValue)
    ) {
      setFromError("Invalid date format. Please use YYYY-MM-DD.");
      setStartDate(null);
    } else {
      setFromError(null); // clear the error if the format is correct
    }
  };
  const handleEndBlur: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value;

    const parsedDateSlash = parse(inputValue, "yyyy/MM/dd", new Date());
    const parsedDateDash = parse(inputValue, "yyyy-MM-dd", new Date());

    // If the date is invalid or the format doesn't match exactly
    if (
      (!isValid(parsedDateSlash) ||
        format(parsedDateSlash, "yyyy/MM/dd") !== inputValue) &&
      (!isValid(parsedDateDash) ||
        format(parsedDateDash, "yyyy-MM-dd") !== inputValue)
    ) {
      setToError("Invalid date format. Please use YYYY-MM-DD.");
      setEndDate(null);
    } else {
      setToError(null); // clear the error if the format is correct
    }
  };
  const handleStartFocus: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFromError(null);
  };
  const handleEndFocus: ChangeEventHandler<HTMLInputElement> = (event) => {
    setToError(null);
  };

  interface SubscriptionParams {
    filterUrl: string;
    page: number;
    user_email?: string;
    created_start_date?: Date | string | null | undefined;
    created_end_date?: Date | string | null | undefined;
    keyword?: string;
    readability?: string;
    country?: string | undefined;
    language?: string;
    min_words?: number | null;
    max_words?: number | null;
  }
  //This function is called on submit and is used to dispatch action based on selected option
  //checks activeURL value and adds that vale to base url together with other params

  const dispatchAction = () => {
    let params: SubscriptionParams = { filterUrl: activeURL, page: pageNumber };

    switch (activeURL) {
      case "get_by_creation_date/":
        params.created_start_date = convertTimestamp(startDate) ?? undefined;
        params.created_end_date = convertTimestamp(endDate) ?? undefined;
        break;
      case "get_by_user_email/":
        if (!customerEmail) {
          return setError("Customer email is required");
        } else {
          params.user_email = customerEmail;
          break;
        }
      case "get_by_country/":
        params.country = countrySelectValue.value;
        break;
      case "get_by_language/":
        params.language = languageSelectValue;
        break;
      case "get_by_word_count/":
        params.min_words = minWordCount;
        params.max_words = maxWordCount;
        break;
      case "get_by_keyword/":
        params.keyword = keyWord;
        break;

      default:
        break;
    }
    setActiveParams(params);

    //Dispatch action
    return dispatch(getReports(params));
  };

  return (
    <section>
      <h1 className="font-semibold">REPORTS</h1>
      <hr className="my-4" />
      <div className="flex items-end justify-between ">
        <div className="grid grid-cols-[8fr,1fr,3fr] gap-4 items-center justify-between w-full mt-4">
          <div
            className={`w-full items-center ${
              activeFilter === "customerEmail" ? "flex" : "hidden"
            }`}
          >
            {/* Autocomplete input */}
            <div className="relative w-full">
              <input
                ref={inputRef}
                value={customerEmail}
                onChange={(e) => {
                  setCustomerEmail(e.target.value);
                  setError("");
                }}
                onKeyDown={handleKeyDown}
                onFocus={() => setOpenUL(true)}
                name="customerEmail"
                id="customerEmail"
                placeholder={placeholder}
                type="text"
                className={`block w-full px-4 py-3 mb-0 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:border-2 focus:border-robinizeGreen-light focus:bg-white ${
                  customerEmail === "" ? "border-red-500" : "border-grey-600"
                }`}
              />
              {isLoading ? (
                <ul className="absolute p-2 bg-white border-2 rounded-lg border-rounded border-grey-300 top-14 w-[12rem] h-[12rem] z-50">
                  <Spinner width={"3rem"} height={"3rem"} zIndex={"10"} />
                </ul>
              ) : (
                customerEmail.length >= 3 &&
                reports?.results.length > 0 &&
                openUL && (
                  <ul
                    ref={ulRef}
                    className="absolute p-2 bg-white border-2 rounded-lg border-rounded border-grey-300 top-14"
                  >
                    {getUniqueEmails(reports).map((creator) => (
                      <li
                        tabIndex={0}
                        className="p-2 mb-2 rounded-lg cursor-pointer bg-grey-100 border-rounded hover:bg-grey-200 focus-within:outline-none focus-within:ring focus-within:ring-robinizeGreen-light"
                        key={creator.id}
                        onClick={() => {
                          setCustomerEmail(creator.user_email);
                          setOpenUL(false);
                        }}
                        onKeyDown={(e) =>
                          handleLiKeyDown(creator.user_email, e)
                        }
                      >
                        {creator.user_email}
                      </li>
                    ))}
                  </ul>
                )
              )}
            </div>
          </div>
          <div
            className={`w-full items-center ${
              activeFilter === "keyword" ? "flex" : "hidden"
            }`}
          >
            {/* Keyword */}
            <div className="relative w-full">
              <input
                value={keyWord}
                onChange={(e) => {
                  setKeyWord(e.target.value);
                  setError("");
                }}
                name="keyword"
                id="keyword"
                placeholder={placeholder}
                type="text"
                className={`block w-full px-4 py-3 mb-0 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:border-2 focus:border-robinizeGreen-light focus:bg-white ${
                  keyWord === "" ? "border-red-500" : "border-grey-600"
                }`}
              />
            </div>
          </div>
          <div
            className={`w-full items-center ${
              activeFilter === "wordCount" ? "flex" : "hidden"
            }`}
          >
            {/* */}
            <div className="relative flex w-full gap-8">
              <div className="flex flex-column">
                <input
                  value={minWordCount}
                  onChange={(e) => {
                    // Only validate if it's a number
                    if (!/^\d*$/.test(e.target.value)) {
                      setMaxError("Only numeric values are allowed.");
                    } else if (
                      maxWordCount &&
                      parseInt(e.target.value) > maxWordCount
                    ) {
                      setMaxError(
                        "Maximum value cannot be less than minimum value."
                      );
                    } else {
                      setMaxError(""); // Clear the error if conditions are met
                    }

                    setMinWordCount(parseInt(e.target.value));
                  }}
                  name="minWordCount"
                  id="minWordCount"
                  placeholder="Min (number)"
                  type="number"
                  className={`block w-full px-4 py-3 mb-0 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:border-2 focus:border-robinizeGreen-light focus:bg-white ${
                    minWordCount === undefined
                      ? "border-red-500"
                      : "border-grey-600"
                  }`}
                />
              </div>
              <div className="flex flex-column">
                <input
                  value={maxWordCount}
                  onChange={(e) => {
                    if (!/^\d*$/.test(e.target.value)) {
                      setMaxError("Only numeric values are allowed.");
                    } else if (
                      minWordCount &&
                      parseInt(e.target.value) < minWordCount
                    ) {
                      setMaxError(
                        "Maximum value cannot be less than minimum value."
                      );
                    } else {
                      setMaxError(""); // Clear the error if conditions are met
                    }

                    setMaxWordCount(parseInt(e.target.value));
                  }}
                  name="maxWordCount"
                  id="maxWordCount"
                  placeholder="Max (number)"
                  type="number"
                  className={`block w-full px-4 py-3 mb-0 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:border-2 focus:border-robinizeGreen-light focus:bg-white ${
                    maxWordCount === undefined
                      ? "border-red-500"
                      : "border-grey-600"
                  }`}
                />
                {maxError && (
                  <div className="mt-2 text-red-500 absolute top-[2.3rem]">
                    {maxError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`w-full items-center ${
              activeFilter === "selectCountry" ? "flex" : "hidden"
            }`}
          >
            <div className={`w-full mr-4`}>
              <div className="">
                <Select
                  value={countrySelectValue}
                  onChange={(selectedOption) => {
                    setCountrySelectValue(selectedOption);
                  }}
                  options={options}
                  isSearchable={true}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </div>
          </div>
          <div
            className={`w-full items-center ${
              activeFilter === "selectLanguage" ? "flex" : "hidden"
            }`}
          >
            <div className={`w-full mr-4`}>
              <div className="">
                <select
                  className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 border rounded appearance-none border-grey-600 focus:outline-none focus:bg-white focus:border-gray-700"
                  value={languageSelectValue}
                  onChange={(e) => {
                    const selectedLanguage = languages.find(
                      (l) => l.name === e.target.value
                    );
                    setLanguageSelectValue(selectedLanguage?.name);
                  }}
                >
                  {languages.map((l) => {
                    return (
                      <option value={l.name} key={l.lang}>
                        {l.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div
            className={`w-full items-center ${
              activeFilter === "selectReadability" ? "flex" : "hidden"
            }`}
          >
            <div className={`w-full mr-4`}>
              <div className="">
                <select
                  className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 border rounded appearance-none border-grey-600 focus:outline-none focus:bg-white focus:border-gray-700"
                  value={readabilitySelectValue}
                  onChange={(e) => {
                    const selectedOption = readabilityOptions.find(
                      (o) => o.option === e.target.value
                    );
                    setReadabilitySelectValue(selectedOption?.option);
                  }}
                >
                  {readabilityOptions.map((o) => {
                    return (
                      <option value={o.option} key={o.option}>
                        {o.option}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div
            className={`w-full items-center ${
              activeFilter === "date" ? "flex" : "hidden"
            }`}
          >
            <div className="flex">
              <div className="mr-2 datepicker">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartChange}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Start date: YYYY-MM-DD"
                  maxDate={endDate ?? new Date()}
                  onBlur={handleStartBlur}
                  onFocus={handleStartFocus}
                  startDate={startDate}
                  endDate={endDate}
                />
                {fromError && (
                  <span className="block text-sm font-medium text-red-400">
                    {fromError}
                  </span>
                )}
              </div>

              <div className="mr-4 datepicker">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndChange}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="End date: YYYY-MM-DD"
                  maxDate={new Date()}
                  onBlur={handleEndBlur}
                  onFocus={handleEndFocus}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
                {toError && (
                  <span className="block text-sm font-medium text-red-400">
                    {toError}
                  </span>
                )}
              </div>
            </div>
          </div>
          <Button
            label="Submit"
            width={"8rem"}
            margin={"0 0 0 0"}
            onClick={() => dispatchAction()}
            disabled={
              (!customerEmail && activeFilter === "customerEmail") ||
              (!keyWord && activeFilter === "keyword") ||
              (!minWordCount && activeFilter === "wordCount") ||
              (!maxWordCount && activeFilter === "wordCount") ||
              (minWordCount !== undefined &&
                maxWordCount !== undefined &&
                minWordCount > maxWordCount &&
                activeFilter === "wordCount") ||
              (!startDate && activeFilter === "date") ||
              (!endDate && activeFilter === "date")
            }
            requestPending={isLoading}
            style={{
              gridColumnStart: 2,
              display: activeFilter === "all" ? "none" : "flex",
            }}
          />
          {/* Filter select */}
          <div className="col-start-3 mx-4">
            <div className="relative">
              <select
                className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 border rounded appearance-none border-grey-600 focus:outline-none focus:bg-white focus:border-gray-700"
                //value={activeFilter}
                value={activeURL}
                onChange={(e) => handleFilterChange(e)}
              >
                <option value="" data-placeholder="" data-type="all">
                  All
                </option>
                <option
                  value="get_by_creation_date/"
                  data-type="date"
                  data-placeholder="Created start date - Created end date"
                >
                  Created
                </option>
                <option
                  value="get_by_user_email/"
                  data-type="customerEmail"
                  data-placeholder="User email"
                >
                  Email
                </option>
                <option
                  value="get_by_country/"
                  data-type="selectCountry"
                  data-placeholder="Select country"
                >
                  Country
                </option>
                <option
                  value="get_by_language/"
                  data-type="selectLanguage"
                  data-placeholder="Select language"
                >
                  Language
                </option>

                <option
                  value="get_by_word_count/"
                  data-type="wordCount"
                  data-placeholder="Min (number) - max (number)"
                >
                  Word count
                </option>
                {/* <option
                  value="----------/"
                  data-type="selectReadability"
                  data-placeholder=""
                >
                  Readability
                </option> */}
                <option
                  value="get_by_keyword/"
                  data-type="keyword"
                  data-placeholder="Type keyword"
                >
                  Keyword
                </option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                <svg
                  className="w-4 h-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <TableLoadingSkeleton />
      ) : (
        <Table
          data={reports.results}
          columns={ReportsCols}
          showFilters={false}
          actionName={getReports}
          actionParams={activeParams}
          next={reports.links.next}
          previous={reports.links.previous}
          pageCount={reports.count}
        />
      )}
    </section>
  );
}

export default Reports;
