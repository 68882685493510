import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import type { RootState } from "./reducers";
import { authApi } from "./services/auth/auth";
import { plansApi } from "./services/plans/plans";
import { LtvApi } from "./services/lifetime_value/lifetime_value";
import { topUsersApi } from "./services/top_users/top_users";
import { usersFunnelApi } from "./services/users_funnel/users_funnel";
import { usersDateRegistration } from "./services/charts/date_registration/date_registration";
import { newSubscribersApi } from "./services/charts/new_subscribers/new_subscribers";
import { newSubscriptionsAmount } from "./services/charts/new_subscriptions_amount/new_subscriptions_amount";
import { percentageRegisteredUsers } from "./services/charts/percentage_registered_user/percentage_registered_user";
import { totalIncomePerMonth } from "./services/charts/total_income_per_month/total_income_per_month";

import { rtkQueryErrorLogger } from "./services/rtkQueryErrorLogger";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      plansApi.middleware,
      LtvApi.middleware,
      topUsersApi.middleware,
      usersFunnelApi.middleware,
      usersDateRegistration.middleware,
      newSubscribersApi.middleware,
      newSubscriptionsAmount.middleware,
      percentageRegisteredUsers.middleware,
      totalIncomePerMonth.middleware,
      rtkQueryErrorLogger
    ),
});

export type AppDispatch = typeof store.dispatch;
//export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
