import React from "react";
import { useGetUsersFunnelQuery } from "../../app/services/users_funnel/users_funnel";
import Spinner from "../Spinner";

interface UsersListProps {
  startDate?: Date | null | undefined | string;
  endDate?: Date | null | undefined | string;
  height?: string;
  width?: string;
}

const UsersFunnel: React.FC<UsersListProps> = ({
  startDate,
  endDate,
  height,
  width,
}) => {
  const { data, isLoading, isFetching, isError, error } =
    useGetUsersFunnelQuery({
      startDate,
      endDate,
    });

  return (
    <div className="grid grid-rows-[min-content_1fr] min-h-[100px]">
      <h1 className="py-4 text-lg font-medium">Users funnel</h1>
      <div className="grid relative grid-cols-4 grid-rows-2 min-h-[60px] text-center border-0 shadow-xl border-grey-100">
        {isLoading || isFetching ? (
          <Spinner height="50px" width="50px" />
        ) : (
          <>
            <div className="col-start-1 col-end-2 py-2 border-r-2 bg-grey-200 border-grey-300">
              <p className="inline py-1 font-semibold text-grey-700 ">
                Created accounts
              </p>
            </div>
            <div className="col-start-2 col-end-3 py-2 border-r-2 bg-grey-200 border-grey-300">
              <p className="inline py-1 font-semibold text-grey-700 ">
                Activated accounts
              </p>
            </div>
            <div className="col-start-3 col-end-4 py-2 border-r-2 bg-grey-200 border-grey-300">
              <p className="inline py-1 font-semibold text-grey-700 ">
                Created a report
              </p>
            </div>
            <div className="col-start-4 col-end-5 py-2 bg-grey-200">
              <p className="inline py-1 font-semibold text-grey-700 ">
                Purchased
              </p>
            </div>

            <div className="grid grid-cols-4 col-start-1 col-end-5">
              <div className="col-start-1 col-end-2 pt-2 border-r-2 border-grey-300">
                <p className="inline py-1 font-semibold text-grey-700 ">
                  {data.created_accounts}
                </p>
              </div>
              <div className="col-start-2 col-end-3 pt-2 border-r-2 border-grey-300">
                <p className="inline py-1 font-semibold text-grey-700 ">
                  {data.activated_accounts}
                </p>
              </div>
              <div className="col-start-3 col-end-4 pt-2 border-r-2 border-grey-300">
                <p className="inline py-1 font-semibold text-grey-700 ">
                  {data.created_report}
                </p>
              </div>
              <div className="col-start-4 col-end-5 pt-2">
                <p className="inline py-1 font-semibold text-grey-700 ">
                  {data.purchased}
                </p>
              </div>
            </div>
          </>
        )}
        {isError && error && (
          <div className="grid grid-cols-4 grid-rows-2 min-h-[60px] text-center border-0 shadow-xl border-grey-100">
            <p className="col-start-1 col-end-5">
              Couldn't fetch "Users funnel" data
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersFunnel;
