import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//
// import { RootState } from "../../app/store";
import { RootState } from "../../app/reducers";
//Import the user service
import usersService from "./usersService";

//State object
export interface UsersStateInterface {
  data: {
    count: number;
    links: {
      next: number | null;
      previous: number | null;
    };
    results: any[];
  };
  pageNumber: number;
  pageCount: number;
  loading: boolean;
  isLoading: boolean;
  isError: boolean;
  message: string | any;
}

const initialState: UsersStateInterface = {
  data: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    results: [],
  },
  pageNumber: 1,
  pageCount: 0,
  loading: false,
  isLoading: false,
  isError: false,
  message: "",
};

////
export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (
    payload: {
      filterUrl?: string;
      page: number | undefined;
      start_date?: Date | string | null;
      end_date?: Date | string | null;
      email?: string | null;
      name?: string | null;
      min?: number | null;
      max?: number | null;
      bussiness?: string | null;
      affiliate?: string | null;
      active?: string | null;
      user_plan?: string | null;
    },
    thunkAPI
  ) => {
    const {
      filterUrl,
      page,
      start_date,
      end_date,
      email,
      name,
      min,
      max,
      bussiness,
      affiliate,
      active,
      user_plan,
    } = payload;

    try {
      const response = await usersService.getUsers(
        {
          params: {
            page: page,
            start_date: start_date,
            end_date: end_date,
            email: email,
            name: name,
            min: min,
            max: max,
            bussiness: bussiness,
            affiliate: affiliate,
            active: active,
            user_plan: user_plan,
          },
        },
        filterUrl
      );
      return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Get all users with filter_users
export const getAllUsersFilter = createAsyncThunk(
  "users/getUsersFilter",
  async (
    payload: {
      page: number | null;
      statusFilter?: number | string | null;
      roleFilter?: string | null;
      sort_by_remaining_reports?: string | null;
    },
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;

      const { page, statusFilter, roleFilter, sort_by_remaining_reports } =
        payload;

      const response = await usersService.getAllUsersFilter(payload, {
        params: {
          page: page,
          status: statusFilter || null,
          role: roleFilter || null,
          "sort by remaining reports": sort_by_remaining_reports || null,
        },
      });

      return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Get all users with users_search
// export const getAllUsersSearch = createAsyncThunk(
//   "users/getUsersSearch",
//   async (
//     payload: {
//       page: number | null;
//       email?: string | null;
//       name?: string | null;
//       user_plan?: string | null;
//     },
//     thunkAPI
//   ) => {
//     try {
//       const state = thunkAPI.getState() as RootState;

//       const { page, email, name, user_plan } = payload;

//       const response = await usersService.getAllUsersSearch(payload, {
//         params: {
//           page: page,
//           email: email || null,
//           name: name || null,
//           user_plan: user_plan || null,
//         },
//       });
//       return response.data;
//     } catch (error: any) {
//       const message =
//         (error.response.data &&
//           error.response.data.data &&
//           error.response.data.data.message) ||
//         error.response.data.detail ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

//Create store slice
export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateUsersState: (state, action) => {
      //state.user = action.payload;
    },
    updateStatus: (state, action) => {
      //state.user.is_active = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //getAllUsers with filter_users
    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.results = action.payload.results;
      state.pageCount = Math.ceil(action.payload.count / 10);
      state.data.count = action.payload.count;
      state.data.links.next = action.payload?.links.next;
      state.data.links.previous = action.payload.links.previous;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
    builder.addCase(getAllUsersFilter.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllUsersFilter.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.results = action.payload.results;
      state.pageCount = Math.ceil(action.payload.count / 10);
      state.data.count = action.payload.count;
      state.data.links.next = action.payload.links.next;
      state.data.links.previous = action.payload.links.previous;
      //state.pageNumber = action.payload.results.pageNumber;
    });
    builder.addCase(getAllUsersFilter.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
  },
});

export const { updateUsersState, updateStatus, reset } = usersSlice.actions;

export default usersSlice.reducer;
