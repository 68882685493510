import axios from "axios";

let api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: false,
});

axios.defaults.withCredentials = false;

export default api;
