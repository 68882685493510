import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import subscriptionsService from "./subscriptionsService";

export interface SubscriptionsState {
  subscriptions: {
    count: number;
    links: {
      next: number | null;
      previous: number | null;
    };
    results: any[];
  };
  pageNumber: number;
  pageCount: number;
  isLoading: boolean;
  isError: boolean;
  message: string | any;
}
const initialState: SubscriptionsState = {
  subscriptions: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    results: [],
  },
  pageNumber: 1,
  pageCount: 0,
  isLoading: false,
  isError: false,
  message: "",
};

//Get users subscriptions
export const getSubscriptions = createAsyncThunk(
  "subscriptions/getSubscriptions",
  async (
    payload: {
      filterUrl?: string;
      page: number | undefined;
      customer_id?: string;
      status?: string;
      canceled_start_date?: Date | string | null;
      canceled_end_date?: Date | string | null;
      created_start_date?: Date | string | null;
      created_end_date?: Date | string | null;
      start_date?: Date | string | null;
      end_date?: Date | string | null;
    },
    thunkAPI
  ) => {
    const {
      filterUrl,
      page,
      canceled_start_date,
      canceled_end_date,
      created_start_date,
      created_end_date,
      customer_id,
      status,
      start_date,
      end_date,
    } = payload;
    try {
      const response = await subscriptionsService.getSubscriptions(
        {
          params: {
            page: page,
            canceled_start_date: canceled_start_date,
            canceled_end_date: canceled_end_date,
            created_start_date: created_start_date,
            created_end_date: created_end_date,
            customer_id: customer_id,
            status: status,
            start_date: start_date,
            end_date: end_date,
          },
        },
        filterUrl
      );
      return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Create store slice
export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //Get subscriptions
    builder.addCase(getSubscriptions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubscriptions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.subscriptions = action.payload;
      state.subscriptions.count = Math.ceil(action.payload.count / 10);
    });
    builder.addCase(getSubscriptions.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
  },
});

export const { reset } = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
