import { ColumnDef } from "@tanstack/react-table";
import { convertTimestamp } from "../../../../common/utils";

export interface PlansDataInterface {
  created_at: string;
  name: string;
  amount: number;
  report_limit: number;
  subscription_type: string;
  premium_support: boolean;
  is_enterprise: boolean;
  status: number;
  ///
  active_customers: number;
  historic_customers: number;
}

export const PlansColumns: ColumnDef<PlansDataInterface>[] = [
  {
    accessorFn: (row) => `${row.created_at}`,
    id: "created_at",
    header: "Created at",
    cell: (props) => {
      return (
        <p className="my-1 whitespace-nowrap">
          {convertTimestamp(props.row.original.created_at)}
        </p>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => `${row.name}`,
    id: "name",
    header: "Name",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => row.amount,
    id: "amount",
    header: "Amount",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => row.report_limit,
    id: "report_limit",
    header: "Report Limit",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.subscription_type}`,
    id: "subscription_type",
    header: "Subscription type",
    cell: (info) => info.getValue(),
  },

  {
    accessorFn: (row) => `${row.premium_support}`,
    id: "premium_support",
    header: "Premium support",
    cell: (props) => (
      <span className="block py-3">
        {props.row.original.premium_support === true ? "Yes" : "No"}
      </span>
    ),
  },
  {
    accessorFn: (row) => row.is_enterprise,
    id: "is_enterprise",
    header: "Enterprise",
    cell: (props) => (
      <span className="block py-3">
        {props.row.original.is_enterprise === true ? "Yes" : "No"}
      </span>
    ),
  },
  {
    accessorFn: (row) => `${row.status}`,
    id: "status",
    header: "Active",
    cell: (props) => (
      <span className="block py-3">
        {props.row.original.status === 1 ? "Yes" : "No"}
      </span>
    ),
  },
  {
    accessorFn: (row) => `${row.active_customers}`,
    id: "active_customers",
    header: "Active customers",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.historic_customers}`,
    id: "historic_customers",
    header: "Historic customers",
    cell: (info) => info.getValue(),
  },
];
