import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getArticles, reset } from "../features/articles/articlesSlice";
import Table from "../components/Table/Table";
import { ColumnDef } from "@tanstack/react-table";
import {
  ArticlesDataInterface,
  ArticlesColumns,
} from "../components/Table/Columns/Articles/ArticlesColumns";
import Loader from "../components/Loader";
import TableLoadingSkeleton from "../components/Table/TableLoadingSkeleton";

function Articles() {
  const dispatch = useAppDispatch();

  const { articles, isLoading, isError, message } = useAppSelector(
    (state) => state.articles
  );

  useEffect(() => {
    dispatch(getArticles({ page: 1 }));

    return () => {
      dispatch(reset());
    };
  }, [isError, message, dispatch]);

  window.scrollTo({ top: 0, behavior: "smooth" });
  //ArticlesColumns
  const ArticlesCols = React.useMemo<ColumnDef<ArticlesDataInterface>[]>(
    () => ArticlesColumns,
    []
  );
  return (
    <section>
      <h1 className="font-semibold">ARTICLES</h1>
      <hr className="my-4" />
      {isLoading ? (
        // <Loader loading={true} />
        <TableLoadingSkeleton />
      ) : (
        <Table
          data={articles.results}
          columns={ArticlesCols}
          showFilters={false}
          actionName={getArticles}
          next={articles.links.next}
          previous={articles.links.previous}
          pageCount={articles.count}
        />
      )}
    </section>
  );
}

export default Articles;
