import { ColumnDef } from "@tanstack/react-table";
import { convertTimestamp } from "../../../../common/utils";

export interface ArticlesDataInterface {
  created_at: string;
  name: string;
  keyword: string;

  avg_readability_grade: string;

  word_count: number;

  content_grade: number;
}
export const ArticlesColumns: ColumnDef<ArticlesDataInterface>[] = [
  {
    accessorFn: (row) => row.created_at,
    id: "created_at",
    cell: (props) => {
      return (
        <p className="my-1 whitespace-nowrap">
          {convertTimestamp(props.row.original.created_at)}
        </p>
      );
    },
    header: "Created at",
  },
  {
    accessorFn: (row) => row.name,
    id: "name",
    cell: (info) => info.getValue(),
    header: "Article name",
  },
  {
    accessorFn: (row) => row.keyword,
    id: "keyword",
    cell: (info) => info.getValue(),
    header: "Report keyword",
  },
  {
    accessorFn: (row) => `${row.avg_readability_grade}`,
    id: "avg_readability_grade",
    header: "Readability",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.word_count}`,
    id: "word_count",
    header: "Word count",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.content_grade}`,
    id: "content_grade",
    header: "Content Grade",
    cell: (props) => {
      if (props.row.original.content_grade > 7) {
        return (
          <p className="my-1 text-center">
            <span className="inline-block w-[6rem] px-4 py-2 rounded-lg bg-opacity-20 bg-robinizeGreen-light">
              <span className="font-bold text-robinizeGreen-dark">
                {props.row.original.content_grade}/10
              </span>
            </span>
          </p>
        );
      } else if (
        props.row.original.content_grade > 3 &&
        props.row.original.content_grade <= 7
      ) {
        return (
          <p className="my-1 text-center">
            <span className="inline-block w-[6rem] px-4 py-2 rounded-lg bg-opacity-30 bg-yellow-400">
              <span className="font-bold text-yellow-700">
                {props.row.original.content_grade}/10
              </span>
            </span>
          </p>
        );
      } else {
        return (
          <p className="my-1 text-center">
            <span className="inline-block w-[6rem] px-4 py-2 rounded-lg bg-opacity-20 bg-robinizeOrange-light">
              <span className="font-bold text-robinizeOrange-dark">
                {props.row.original.content_grade}/10
              </span>
            </span>
          </p>
        );
      }
    },
  },
];
