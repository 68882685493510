import api from "../../api/api";

const articlesUrl = "admin_articles/";

//Get articles
const getArticles = async (options: { params: { page: number } }) => {
  const response = await api.get(`${articlesUrl}`, options);
  return response;
};

const articlesService = {
  getArticles,
};

export default articlesService;
