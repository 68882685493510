import React from "react";
// import { toast } from "react-toastify";

import Table from "../../components/Table/Table";
import { ColumnDef } from "@tanstack/react-table";
import {
  PlansDataInterface,
  PlansColumns,
} from "../../components/Table/Columns/Plans/PlansColumns";
import { useGetPlansQuery } from "../../app/services/plans/plans";
import TableLoadingSkeleton from "../../components/Table/TableLoadingSkeleton";

function Plans() {
  const { data: plans, isLoading, isError, error } = useGetPlansQuery("plans");

  //PlansColumns
  const PlansCols = React.useMemo<ColumnDef<PlansDataInterface>[]>(
    () => PlansColumns,
    []
  );

  if (isError) {
    //toast.error(error);
  }

  return (
    <section>
      <h1 className="font-semibold">PLANS</h1>
      <hr className="my-4" />
      {isLoading ? (
        // <Loader loading={true} />
        <TableLoadingSkeleton />
      ) : (
        <Table
          data={plans}
          columns={PlansCols}
          showFilters={false}
          //actionName={getReports}
          next={null}
          previous={null}
          pageCount={null}
        />
      )}
    </section>
  );
}

export default Plans;
