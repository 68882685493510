import { User } from "./User";
import { convertTimestamp } from "../../common/utils";

interface UserItemProps {
  user: User;
  onClick: (user: User) => void;
}

const UserItem: React.FC<UserItemProps> = ({ user, onClick }) => {
  return (
    // <div className="grid grid-cols-4 p-2 mb-2 font-semibold text-grey-700 min-h-[60px] leading-tight text-center border-0 shadow-xl bg-grey-200 border-grey-100">/div>
    <div
      className="grid items-center grid-cols-4 p-2 mb-2 mr-2 text-sm font-semibold text-center text-white break-words transition duration-300 ease-in-out border-2 rounded-md shadow-md cursor-pointer last:mb-[30px] xl:text-base border-robinizeGreen-light/40 bg-robinizeGreen hover:bg-robinizeGreen-light hover:shadow-sm shadow-robinizeGreen/40"
      onClick={() => onClick(user)}
    >
      <p className="inline">{user.email}</p>
      <p className="inline">{user.created_reports_total}</p>
      <p className="inline">{user.written_words_total}</p>
      <p className="inline">{user.ltv_total_income}</p>
    </div>
  );
};

export default UserItem;
