import { ColumnDef } from "@tanstack/react-table";
import { convertTimestamp } from "../../../../common/utils";

export interface SubscriptionsDataInterface {
  created: string;
  status: string;
  current_period_start: string;
  current_period_end: string;
  collection_method: string;
  plan_id: string;
  plan_name: string;
  customer_email: string;
}

export const SubscriptionsColumns: ColumnDef<SubscriptionsDataInterface>[] = [
  {
    accessorFn: (row) => row.created,
    id: "created",
    cell: (info) => convertTimestamp(info.getValue()),
    header: "Created at",
  },
  {
    accessorFn: (row) => `${row.status}`,
    id: "status",
    header: "Status",
    cell: (props) => {
      if (props.row.original.status === "active") {
        return (
          <p className="my-1 text-center">
            <span className="inline-block w-[8rem] px-4 py-2 rounded-lg bg-opacity-20 bg-robinizeGreen-light">
              <span className="font-bold text-robinizeGreen-dark">
                {props.row.original.status}
              </span>
            </span>
          </p>
        );
      } else if (props.row.original.status === "past_due") {
        return (
          <p className="my-1 text-center">
            <span className="inline-block w-[8rem] px-4 py-2 rounded-lg bg-opacity-40 bg-grey-800">
              <span className="font-bold text-white">
                {props.row.original.status}
              </span>
            </span>
          </p>
        );
      } else if (props.row.original.status === "canceled") {
        return (
          <p className="my-1 text-center">
            <span className="inline-block w-[8rem] px-4 py-2 rounded-lg bg-opacity-20 bg-robinizeOrange-light">
              <span className="font-bold text-robinizeOrange-dark">
                {props.row.original.status}
              </span>
            </span>
          </p>
        );
      } else {
        return (
          <p className="my-1 text-center">
            <span className="inline-block px-4 py-2 rounded-lg bg-opacity-20 bg-robinizeOrange-light">
              <span className="font-bold text-robinizeOrange-dark">
                {props.row.original.status}
              </span>
            </span>
          </p>
        );
      }
    },
  },
  {
    accessorFn: (row) => `${row.current_period_start}`,
    id: "current_period_start",
    header: "Current period start",
    cell: (info) => convertTimestamp(info.getValue()),
  },
  {
    accessorFn: (row) => `${row.current_period_end}`,
    id: "current_period_end",
    header: "Current period end",
    cell: (info) => convertTimestamp(info.getValue()),
  },
  {
    accessorFn: (row) => `${row.collection_method}`,
    id: "collection_method",
    header: "Collection method",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.plan_name}`,
    id: "plan_name",
    header: "Plan",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.customer_email}`,
    id: "customer_email",
    header: "Email",
    cell: (info) => info.getValue(),
  },
];
