import { ColumnDef } from "@tanstack/react-table";

export interface UserSubscriptionInterface {
  created: string;
  status: string;
  current_period_start: string;
  current_period_end: string;
  collection_method: string;
  plan_id: string;
}

export const UserSubscriptions: ColumnDef<UserSubscriptionInterface>[] = [
  {
    accessorFn: (row) => row.created,
    id: "created",
    cell: (info) => info.getValue(),
    header: "Created at",
  },
  {
    accessorFn: (row) => `${row.status}`,
    id: "status",
    header: "Status",
    cell: (props) => (
      <p className="my-1 text-center">
        <span className="px-4 py-2 text-white rounded-lg bg-robinizeGreen">
          {props.row.original.status}
        </span>
      </p>
    ),
  },
  {
    accessorFn: (row) => `${row.current_period_start}`,
    id: "current_period_start",
    header: "Current period start",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.current_period_end}`,
    id: "current_period_end",
    header: "Current period end",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.collection_method}`,
    id: "collection_method",
    header: "Collection method",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.plan_id}`,
    id: "plan_id",
    header: "Plan",
    cell: (info) => info.getValue(),
  },
];
