import React, { useState } from "react";

interface Props {
  loading: boolean;
}

const Loading: React.FC<Props> = ({ loading }) => {
  const [dotCount, setDotCount] = useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setDotCount((dotCount) => (dotCount + 1) % 4);
    }, 350);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="flex justify-center h-full">
      {loading && (
        <div className="flex text-center text-robinizeOrange text-[8rem]">
          <span>{".".repeat(dotCount)}</span>
        </div>
      )}
    </div>
  );
};

export default Loading;
