import api from "../../api/api";

const invoicesBaseUrl = "payments/admin_invoice/";

//Get invoices
const getInvoices = async (
  options: {
    params: {
      page: number | null;
      customer_email?: string;
      customer_id?: string;
      subscription_id?: string;
    };
  },
  filterUrl?: string
) => {
  if (filterUrl === undefined) {
    filterUrl = "";
  }
  const response = await api.get(`${invoicesBaseUrl}${filterUrl}`, options);
  return response;
};

const invoicesService = {
  getInvoices,
};

export default invoicesService;
