import api from "../../api/api";

const subscriptionsBaseUrl = "payments/admin_subscriptions/";

//Get subscriptions
const getSubscriptions = async (
  options: {
    params: {
      page: number | undefined;
      canceled_start_date?: Date | string | null;
      canceled_end_date?: Date | string | null;
      created_start_date?: Date | string | null;
      created_end_date?: Date | string | null;
      start_date?: Date | string | null;
      end_date?: Date | string | null;
      customer_id?: string;
      status?: string;
    };
  },
  filterUrl?: string
) => {
  if (filterUrl === undefined) {
    filterUrl = "";
  }
  const response = await api.get(
    `${subscriptionsBaseUrl}${filterUrl}`,
    options
  );
  return response;
};

const subscriptionsService = {
  getSubscriptions,
};

export default subscriptionsService;
