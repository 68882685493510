import React, { useEffect, useState, useRef, ChangeEventHandler } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  getUsers,
  reset,
  UsersStateInterface,
} from "../features/users/usersSlice";
import { ColumnDef } from "@tanstack/react-table";
import Table from "../components/Table/Table";
import TableLoadingSkeleton from "../components/Table/TableLoadingSkeleton";
import { UsersColumns } from "../components/Table/Columns/UsersColumns/UsersColumns";
import { UserType } from "../features/user/userSlice";
import { Button } from "../components/Button";
import { Reset } from "../assets/icons";

import DatePicker from "react-datepicker";
import { format, isValid, parse } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { convertTimestamp } from "../common/utils";
import Spinner from "../components/Spinner";

function Users() {
  const dispatch = useAppDispatch();

  //Getting user data from local storage
  let user = localStorage.getItem("user");
  //Parse user data
  if (typeof user === "string") {
    user = JSON.parse(user);
  }

  const {
    data: usersData,
    pageNumber,
    pageCount,
    isLoading,
    isError,
    message,
  } = useAppSelector((state) => state.users);

  useEffect(() => {
    if (isError) {
      setActiveURL("");
      setActiveFilter("all");
      setActiveParams({ page: 1, filterUrl: "users_filter" });
    }
    dispatch(getUsers({ page: 1, filterUrl: "users_filter" }));

    return () => {
      dispatch(reset());
    };
  }, [isError, message, dispatch]);

  window.scrollTo({ top: 0, behavior: "smooth" });

  //   //Table data and columns
  const data: UserType[] = React.useMemo(
    () => usersData.results,
    [usersData.results]
  );

  const columns = React.useMemo<ColumnDef<UserType>[]>(() => UsersColumns, []);

  //TS
  interface State {
    createdDate: Date | null;
    email: string;
    user: string;
    affiliate: string;
    plan: string;
    languageSelectValue: string;
    word_count: number | undefined;
    activeFilter: string;
    activeURL: string;
    placeholder: string;
    error: string;
  }

  //Ref autocomplete
  const inputRef = useRef<HTMLInputElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);

  // Handle Tab key press to focus on the first item in the ul list
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Tab" &&
      customerEmail.length >= 3 &&
      usersData?.results.length > 0
    ) {
      e.preventDefault();
      const firstChild = ulRef.current?.firstChild as HTMLLIElement | null;
      firstChild?.focus();
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    // If the click is outside the input and the ul, close the ul
    if (
      inputRef.current &&
      !inputRef.current.contains(e.target as Node) &&
      ulRef.current &&
      !ulRef.current.contains(e.target as Node)
    ) {
      setOpenUL(false);
    }
  };
  const handleLiKeyDown = (
    email: string,
    e: React.KeyboardEvent<HTMLLIElement>
  ) => {
    if (e.key === "Enter") {
      setCustomerEmail(email);
      setOpenUL(false);
    }
  };

  useEffect(() => {
    // Attach global click listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup the listener when the component is unmounted
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //set params for dispatch action based on filter
  const [activeParams, setActiveParams] = useState<any>({
    page: 1,
    filterUrl: "users_filter",
  });

  //set active filter option
  const [activeFilter, setActiveFilter] =
    useState<State["activeFilter"]>("all");

  //set active filter url
  const [activeURL, setActiveURL] = useState<State["activeURL"]>("");

  //Input filter
  const [customerEmail, setCustomerEmail] = useState<State["email"]>("");
  const [openUL, setOpenUL] = useState<boolean>(true);
  //Input filter
  const [minWordCount, setMinWordCount] =
    useState<State["word_count"]>(undefined);
  //const [minError, setMinError] = useState<string>("");
  const [maxWordCount, setMaxWordCount] =
    useState<State["word_count"]>(undefined);
  const [maxError, setMaxError] = useState<string>("");
  //Input filter
  const [userName, setUserName] = useState<State["user"]>("");
  //Input placeholder
  const [placeholder, setPlaceholder] = useState<State["placeholder"]>("");
  // //select values
  const [affiliate, setAffiliate] = useState<State["affiliate"] | undefined>();
  const [planName, setPlanName] = useState<State["plan"] | undefined>();

  //error if search term is empty
  const [error, setError] = useState<State["error"]>("");

  //change role filter
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "") {
      dispatch(getUsers({ page: 1, filterUrl: "users_filter" }));
      setActiveParams(null);
    } else if (e.target.value === "affiliates") {
      dispatch(getUsers({ page: 1, filterUrl: "admin_active_affiliates" }));
      setActiveParams({ page: 1, filterUrl: "admin_active_affiliates" });
    }
    setActiveURL(e.target.options[e.target.selectedIndex].dataset.type || "");
    setActiveFilter(e.target.value);

    setPlaceholder(
      e.target.options[e.target.selectedIndex].dataset.placeholder || ""
    );
    setCustomerEmail("");
    setUserName("");
    setStartDate(null);
    setEndDate(null);
    setMinWordCount(undefined);
    setMaxWordCount(undefined);
    setAffiliate(undefined);
    setPlanName("");
  };
  //Autocomplete
  function getUniqueEmails(usersData: UsersStateInterface["data"]) {
    const uniqueEmailsSet = new Set();
    return usersData?.results.filter((creator) => {
      const isUnique = !uniqueEmailsSet.has(creator.email);
      uniqueEmailsSet.add(creator.email);
      return isUnique;
    });
  }
  useEffect(() => {
    if (customerEmail.length >= 3) {
      setActiveParams({
        page: pageNumber,
        email: customerEmail,
        filterUrl: "users_search",
      });
      dispatch(
        getUsers({
          page: 1,
          email: customerEmail,
          filterUrl: "users_search",
        })
      );
    }
  }, [customerEmail]);

  ///Date
  const [startDate, setStartDate] = useState<State["createdDate"]>(null);
  const [endDate, setEndDate] = useState<State["createdDate"]>(null);
  const [fromError, setFromError] = useState<string | null>("");
  const [toError, setToError] = useState<string | null>("");

  const handleStartChange = (date: Date | null) => {
    setStartDate(date);
    setFromError(null);
  };

  const handleEndChange = (date: Date | null) => {
    setEndDate(date);
    setToError(null);
  };
  const handleStartBlur: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value;

    const parsedDateSlash = parse(inputValue, "yyyy/MM/dd", new Date());
    const parsedDateDash = parse(inputValue, "yyyy-MM-dd", new Date());

    // If the date is invalid or the format doesn't match exactly
    if (
      (!isValid(parsedDateSlash) ||
        format(parsedDateSlash, "yyyy/MM/dd") !== inputValue) &&
      (!isValid(parsedDateDash) ||
        format(parsedDateDash, "yyyy-MM-dd") !== inputValue)
    ) {
      setFromError("Invalid date format. Please use YYYY-MM-DD.");
      setStartDate(null);
    } else {
      setFromError(null); // clear the error if the format is correct
    }
  };
  const handleEndBlur: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value;

    const parsedDateSlash = parse(inputValue, "yyyy/MM/dd", new Date());
    const parsedDateDash = parse(inputValue, "yyyy-MM-dd", new Date());

    // If the date is invalid or the format doesn't match exactly
    if (
      (!isValid(parsedDateSlash) ||
        format(parsedDateSlash, "yyyy/MM/dd") !== inputValue) &&
      (!isValid(parsedDateDash) ||
        format(parsedDateDash, "yyyy-MM-dd") !== inputValue)
    ) {
      setToError("Invalid date format. Please use YYYY-MM-DD.");
      setEndDate(null);
    } else {
      setToError(null); // clear the error if the format is correct
    }
  };
  const handleStartFocus: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFromError(null);
  };
  const handleEndFocus: ChangeEventHandler<HTMLInputElement> = (event) => {
    setToError(null);
  };

  interface UsersParams {
    filterUrl: string;
    page: number;
    email?: string;
    start_date?: Date | string | null | undefined;
    end_date?: Date | string | null | undefined;
    name?: string | null;
    min?: number | null;
    max?: number | null;
    bussiness?: string | null;
    affiliate?: string | null;
    active?: string | null;
    user_plan?: string | null;
  }
  //This function is called on submit and is used to dispatch action based on selected option
  //checks activeURL value and adds that vale to base url together with other params

  const dispatchAction = () => {
    let params: UsersParams = { filterUrl: activeURL, page: pageNumber };

    switch (activeFilter) {
      case "date":
        params.start_date = convertTimestamp(startDate) ?? undefined;
        params.end_date = convertTimestamp(endDate) ?? undefined;
        break;
      case "customerEmail":
        if (!customerEmail) {
          return setError("Customer email is required");
        } else {
          params.email = customerEmail;
          break;
        }
      case "userName":
        params.name = userName;
        break;
      case "affiliates":
        params.affiliate = "true";
        break;
      case "wordCount":
        params.min = minWordCount;
        params.max = maxWordCount;
        break;
      case "plan":
        params.user_plan = planName;
        break;

      default:
        break;
    }
    setActiveParams(params);

    //Dispatch action
    return dispatch(getUsers(params));
  };

  return (
    <section>
      <h1 className="font-semibold">USERS</h1>
      <hr className="my-4" />
      <div className="flex items-end justify-between ">
        <div className="grid grid-cols-[8fr,1fr,3fr] gap-4 items-center justify-between w-full mt-4">
          <div
            className={`w-full items-center ${
              activeFilter === "customerEmail" ? "flex" : "hidden"
            }`}
          >
            {/* Autocomplete input */}
            <div className="relative w-full">
              <input
                ref={inputRef}
                value={customerEmail}
                onChange={(e) => {
                  setCustomerEmail(e.target.value);
                  setError("");
                }}
                onKeyDown={handleKeyDown}
                onFocus={() => setOpenUL(true)}
                name="customerEmail"
                id="customerEmail"
                placeholder={placeholder}
                type="text"
                className={`block w-full px-4 py-3 mb-0 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:border-2 focus:border-robinizeGreen-light focus:bg-white ${
                  customerEmail === "" ? "border-red-500" : "border-grey-600"
                }`}
              />
              {isLoading ? (
                <ul className="absolute p-2 bg-white border-2 rounded-lg border-rounded border-grey-300 top-14 w-[12rem] h-[12rem] z-50">
                  <Spinner width={"3rem"} height={"3rem"} zIndex={"10"} />
                </ul>
              ) : (
                customerEmail.length >= 3 &&
                usersData?.results.length > 0 &&
                openUL && (
                  <ul
                    ref={ulRef}
                    className="absolute p-2 bg-white border-2 rounded-lg border-rounded border-grey-300 top-14"
                  >
                    {getUniqueEmails(usersData).map((creator) => (
                      <li
                        tabIndex={0}
                        className="p-2 mb-2 rounded-lg cursor-pointer bg-grey-100 border-rounded hover:bg-grey-200 focus-within:outline-none focus-within:ring focus-within:ring-robinizeGreen-light"
                        key={creator.id}
                        onClick={() => {
                          setCustomerEmail(creator.email);
                          setOpenUL(false);
                        }}
                        onKeyDown={(e) => handleLiKeyDown(creator.email, e)}
                      >
                        {creator.email}
                      </li>
                    ))}
                  </ul>
                )
              )}
            </div>
          </div>
          <div
            className={`w-full items-center ${
              activeFilter === "userName" ? "flex" : "hidden"
            }`}
          >
            {/* User name */}
            <div className="relative w-full">
              <input
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                  setError("");
                }}
                name="userName"
                id="userName"
                placeholder={placeholder}
                type="text"
                className={`block w-full px-4 py-3 mb-0 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:border-2 focus:border-robinizeGreen-light focus:bg-white ${
                  userName === "" ? "border-red-500" : "border-grey-600"
                }`}
              />
            </div>
          </div>
          <div
            className={`w-full items-center ${
              activeFilter === "wordCount" ? "flex" : "hidden"
            }`}
          >
            {/* */}
            <div className="relative flex w-full gap-8">
              <div className="flex flex-column">
                <input
                  value={minWordCount}
                  onChange={(e) => {
                    // Only validate if it's a number
                    if (!/^\d*$/.test(e.target.value)) {
                      setMaxError("Only numeric values are allowed.");
                    } else if (
                      maxWordCount &&
                      parseInt(e.target.value) > maxWordCount
                    ) {
                      setMaxError(
                        "Maximum value cannot be less than minimum value."
                      );
                    } else {
                      setMaxError(""); // Clear the error if conditions are met
                    }

                    setMinWordCount(parseInt(e.target.value));
                  }}
                  name="minWordCount"
                  id="minWordCount"
                  placeholder="Min (number)"
                  type="number"
                  className={`block w-full px-4 py-3 mb-0 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:border-2 focus:border-robinizeGreen-light focus:bg-white ${
                    minWordCount === undefined
                      ? "border-red-500"
                      : "border-grey-600"
                  }`}
                />
              </div>
              <div className="flex flex-column">
                <input
                  value={maxWordCount}
                  onChange={(e) => {
                    if (!/^\d*$/.test(e.target.value)) {
                      setMaxError("Only numeric values are allowed.");
                    } else if (
                      minWordCount &&
                      parseInt(e.target.value) < minWordCount
                    ) {
                      setMaxError(
                        "Maximum value cannot be less than minimum value."
                      );
                    } else {
                      setMaxError(""); // Clear the error if conditions are met
                    }

                    setMaxWordCount(parseInt(e.target.value));
                  }}
                  name="maxWordCount"
                  id="maxWordCount"
                  placeholder="Max (number)"
                  type="number"
                  className={`block w-full px-4 py-3 mb-0 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:border-2 focus:border-robinizeGreen-light focus:bg-white ${
                    maxWordCount === undefined
                      ? "border-red-500"
                      : "border-grey-600"
                  }`}
                />
                {maxError && (
                  <div className="mt-2 text-red-500 absolute top-[2.3rem]">
                    {maxError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`w-full items-center ${
              activeFilter === "affiliates" ? "flex" : "hidden"
            }`}
          >
            <div className={`w-full mr-4`}>
              <div className="">
                <select
                  className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 border rounded appearance-none border-grey-600 focus:outline-none focus:bg-white focus:border-gray-700"
                  value={affiliate}
                  onChange={(e) => {
                    setAffiliate(e.target.value);
                  }}
                >
                  <option value="true" key="true">
                    Active
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
            className={`w-full items-center ${
              activeFilter === "plan" ? "flex" : "hidden"
            }`}
          >
            <div className={`w-full mr-4`}>
              <div className="">
                <select
                  className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 border rounded appearance-none border-grey-600 focus:outline-none focus:bg-white focus:border-gray-700"
                  value={planName}
                  onChange={(e) => {
                    setPlanName(e.target.value);
                  }}
                >
                  <option value="" disabled defaultValue="" hidden>
                    Select plan
                  </option>
                  <option value="free" key="free">
                    Free
                  </option>
                  <option value="individual monthly" key="individualM">
                    Individual Monthly
                  </option>
                  <option value="individual annual" key="individualA">
                    Individual Annual
                  </option>
                  <option value="team monthly" key="teamM">
                    Team Monthly
                  </option>
                  <option value="team annual" key="teamA">
                    Team Annual
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
            className={`w-full items-center ${
              activeFilter === "date" ? "flex" : "hidden"
            }`}
          >
            <div className="flex">
              <div className="mr-2 datepicker">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartChange}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Start date: YYYY-MM-DD"
                  maxDate={endDate ?? new Date()}
                  onBlur={handleStartBlur}
                  onFocus={handleStartFocus}
                  startDate={startDate}
                  endDate={endDate}
                />
                {fromError && (
                  <span className="block text-sm font-medium text-red-400">
                    {fromError}
                  </span>
                )}
              </div>

              <div className="mr-4 datepicker">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndChange}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="End date: YYYY-MM-DD"
                  maxDate={new Date()}
                  onBlur={handleEndBlur}
                  onFocus={handleEndFocus}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
                {toError && (
                  <span className="block text-sm font-medium text-red-400">
                    {toError}
                  </span>
                )}
              </div>
            </div>
          </div>
          <Button
            label="Submit"
            width={"8rem"}
            margin={"0 0 0 0"}
            onClick={() => dispatchAction()}
            disabled={
              (!customerEmail && activeFilter === "customerEmail") ||
              (!userName && activeFilter === "userName") ||
              (!minWordCount && activeFilter === "wordCount") ||
              (!maxWordCount && activeFilter === "wordCount") ||
              (minWordCount !== undefined &&
                maxWordCount !== undefined &&
                minWordCount > maxWordCount &&
                activeFilter === "wordCount") ||
              (!startDate && activeFilter === "date") ||
              (!endDate && activeFilter === "date")
            }
            requestPending={isLoading}
            style={{
              gridColumnStart: 2,
              display: activeFilter === "all" ? "none" : "flex",
            }}
          />
          {/* Filter select */}
          <div className="col-start-3 mx-4">
            <div className="relative">
              <select
                className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 border rounded appearance-none border-grey-600 focus:outline-none focus:bg-white focus:border-gray-700"
                value={activeFilter}
                onChange={(e) => handleFilterChange(e)}
              >
                <option value="" data-placeholder="" data-type="all">
                  All
                </option>
                <option
                  data-type="users_date_registration"
                  value="date"
                  data-placeholder="Created start date - Created end date"
                >
                  Created
                </option>
                <option
                  data-type="users_search"
                  value="customerEmail"
                  data-placeholder="User email"
                >
                  Email
                </option>
                <option
                  data-type="users_search"
                  value="userName"
                  data-placeholder="User name"
                >
                  Name
                </option>
                <option
                  data-type="users_generated_reports"
                  value="wordCount"
                  data-placeholder="Min (number) - max (number)"
                >
                  Generated reports
                </option>

                <option
                  data-type="admin_active_affiliates"
                  value="affiliates"
                  data-placeholder="affiliates"
                >
                  Affiliate
                </option>
                <option
                  data-type="users_search"
                  value="plan"
                  data-placeholder="User plan"
                >
                  Plan
                </option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                <svg
                  className="w-4 h-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <TableLoadingSkeleton />
      ) : (
        <Table
          data={data}
          columns={columns}
          actionName={getUsers}
          actionParams={activeParams}
          next={usersData.links?.next}
          previous={usersData.links?.previous}
          pageCount={pageCount}
          showFilters={false}
          isRowClickable={true}
        />
      )}
    </section>
  );
}

export default Users;
