import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/reducers";
import articlesService from "./articlesService";

export interface ArticlesState {
  articles: {
    count: number;
    links: {
      next: number | null;
      previous: number | null;
    };
    results: any[];
  };
  pageNumber: number;
  pageCount: number;
  isLoading: boolean;
  isError: boolean;
  message: string | any;
}
const initialState: ArticlesState = {
  articles: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    results: [],
  },
  pageNumber: 1,
  pageCount: 0,
  isLoading: false,
  isError: false,
  message: "",
};

//Get users articles
export const getArticles = createAsyncThunk(
  "articles/getArticles",
  async (payload: { page: number }, thunkAPI) => {
    const { page } = payload;
    try {
      const state = thunkAPI.getState() as RootState;

      const response = await articlesService.getArticles({
        params: {
          page: page,
        },
      });
      return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Create store slice
export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //Get articles
    builder.addCase(getArticles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getArticles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.articles = action.payload;
      state.articles.count = Math.ceil(action.payload.count / 10);
    });
    builder.addCase(getArticles.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
  },
});

export const { reset } = articlesSlice.actions;

export default articlesSlice.reducer;
