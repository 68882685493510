import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware, AnyAction } from "@reduxjs/toolkit";
//
import { sessionExp } from "../../features/auth/authSlice";
//Toastify
import { toast } from "react-toastify";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI<any, any>) => (next) => (action: AnyAction) => {
    if (action.payload?.status === 404) {
      toast.warn(action.payload?.message);
    }
    if (
      action.type === "users/getUsers/fulfilled" &&
      action.payload?.results.length === 0
    ) {
      toast.warn("No users found!");
    }
    if (
      action.type === "reports/getReports/fulfilled" &&
      action.payload?.results.length === 0
    ) {
      toast.warn("No users found!");
    }

    if (action.type === "user/updateUser/fulfilled") {
      toast.success("User updated successfully!");
    }

    if (isRejectedWithValue(action) && typeof action.payload === "string") {
      toast.error(action.payload);
    }

    if (action.payload?.status === 403) {
      api.dispatch(sessionExp()); // Dispatch the session expiration action
      //toast.warn("Your session has expired. Please log in again.");
    }

    return next(action);
  };
