import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "./authService";
import api from "../../api/api";
import { localStorageUser } from "./authService";

export interface AuthUser {
  password: string;
  email: string;
}
//Getting user data from local storage
let user = localStorage.getItem("user");
//Parse user data
if (typeof user === "string") {
  user = JSON.parse(user);
}
let access;
let refresh;

if (user && typeof user === "object") {
  let castUser = user as localStorageUser;
  access = castUser.access;
  refresh = castUser.refresh;
}

interface User {
  id: string | null;
  email: string | null;
  name: string | null;
  avatar: string | null;
  is_active: boolean | null;
}

export interface AuthState {
  user: User;
  isLoggedIn: boolean;
  isLoading: boolean;
  isError: boolean;
  errors: boolean;
  message: string | any;
  access?: string | null;
  refresh?: string | null;
  token?: string | null;
  sessionDialogModal: boolean;
}

//Initial auth state object
const initialState: AuthState = {
  user: {
    id: null,
    email: null,
    name: null,
    avatar: null,
    is_active: null,
  },
  access: access ? access : null,
  // refresh: refresh ? refresh : null,
  //access: null,
  refresh: null,
  token: null,
  isLoggedIn: false,
  isLoading: false,
  isError: false,
  message: "",
  errors: false,
  sessionDialogModal: false,
};
// Login user
export const login = createAsyncThunk(
  "/auth/adminlogin",
  async (user: AuthUser, thunkAPI) => {
    try {
      const response = await authService.login(user);
      //Set user data to local storage
      const localStorageUser = {
        access: response.data.access,
        refresh: response.data.refresh,
      };

      localStorage.setItem("user", JSON.stringify(localStorageUser));

      api.defaults.headers["Authorization"] = `Bearer ${response.data.access}`;

      return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Get me
export const getMe = createAsyncThunk("user/getMe", async (_, thunkAPI) => {
  try {
    const response = await authService.getMe();
    return response.data;
  } catch (error: any) {
    const message =
      (error.response.data &&
        error.response.data.data &&
        error.response.data.data.message) ||
      error.response.data.detail ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
// Logout user
export const logout = createAsyncThunk("auth/logout", async () => {
  authService.logout();
});
//When user session expires show session dialog modal and logout user
export const sessionExp = createAsyncThunk(
  "auth/session",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(sessionDialog(true));
    return thunkAPI.dispatch(logout());
  }
);
//When user session expires save new access token
export const saveAccessToken = createAsyncThunk(
  "auth/saveAccessToken",
  async ({ accessToken }: { accessToken: string }) => {
    //Getting user data from local storage
    let user = localStorage.getItem("user");
    //Parse user data
    if (typeof user === "string") {
      user = JSON.parse(user);
    }

    if (user && typeof user === "object") {
      let castUser = user as localStorageUser;
      castUser.access = accessToken;
    }
    localStorage.setItem("user", JSON.stringify(user));

    return accessToken;
  }
);
//Slice for auth state
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    updateCredentials: (state, action) => {
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
    },
    updateStatus: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    // reset: () => initialState,
    reset: (state) => {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.isError = false;
      state.errors = false;
      state.message = "";
      state.user = {
        id: null,
        email: null,
        name: null,
        avatar: null,
        is_active: null,
      };
      state.access = null;
      state.refresh = null;
    },
    sessionDialog: (state, action) => {
      state.sessionDialogModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.user = action.payload.user;
        state.access = action.payload.access;
        state.token = action.payload.token;
        state.refresh = action.payload.refresh;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        //???
        state.isLoggedIn = false;
        state.message = action.payload;
        state.user = {
          id: null,
          email: null,
          name: null,
          avatar: null,
          is_active: null,
        };
        state.access = null;
        state.refresh = null;
      });
    //Get me
    builder.addCase(getMe.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isLoggedIn = true;
      state.user = action.payload;
    });
    builder
      .addCase(getMe.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      //Logout
      .addCase(logout.fulfilled, (state) => {
        state.user = {
          id: null,
          email: null,
          name: null,
          avatar: null,
          is_active: null,
        };
        state.access = null;
        state.refresh = null;
        state.isLoggedIn = false;
        state.isError = false;
        state.message = "";
      });
    //Session expired
    builder.addCase(saveAccessToken.fulfilled, (state, action) => {
      state.access = action.payload;
    });
  },
});

export const { reset, updateStatus, sessionDialog } = authSlice.actions;

export default authSlice.reducer;
