interface Props {
  color?: string;
  width?: string;
  height?: string;
  zIndex?: string;
}
const Spinner: React.FC<Props> = ({
  color = "robinizeGreen",
  width = "16rem",
  height = "16rem",
  zIndex = "-10",
}) => {
  return (
    <div
      className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center"
      style={{ zIndex: zIndex }}
    >
      <div
        className={`border-8 rounded-full animate-spin border-x-transparent border-y-robinizeGreen`}
        style={{ width: width, height: height, zIndex: zIndex }}
      ></div>
    </div>
  );
};

export default Spinner;
