import React from "react";
import { useGetPercentageRegisteredUsersQuery } from "../../../app/services/charts/percentage_registered_user/percentage_registered_user";
import {
  ResponsiveContainer,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";

import Spinner from "../../Spinner";

interface ChartProps {
  startDate?: Date | null | undefined | string;
  endDate?: Date | null | undefined | string;
  width?: number;
  height?: number;
}

const RegisteredUsersPercentage: React.FC<ChartProps> = ({
  startDate,
  endDate,
  height,
  width,
}) => {
  const { data, isLoading, isFetching, isError, error } =
    useGetPercentageRegisteredUsersQuery({ startDate, endDate });

  const COLORS = ["#33cca0", "#fb8262"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="#fff"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }; ///PieChart data end

  const dataPie = [
    {
      name: "Total users registered out of date range",
      value: 100 - data?.percentage.toFixed(2),
    },
    {
      name: "Users registered in a given date range",
      value: parseInt(data?.percentage.toFixed(2)),
    },
  ];

  const wrapperHeight = height ? height + 50 : 350;

  return (
    <div className="relative mt-8">
      <h1 className="py-4 text-lg font-medium">
        Users registered in percentage
      </h1>
      <div
        className="py-8 border-2 shadow-xl border-grey-100"
        style={{ height: wrapperHeight }}
      >
        {isLoading || isFetching ? (
          <Spinner height="100px" width="100px" />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={dataPie}
                cx="50%"
                cy="50%"
                outerRadius={80}
                labelLine={false}
                label={renderCustomizedLabel}
                dataKey="value"
              >
                {dataPie.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend layout="vertical" />
            </PieChart>
          </ResponsiveContainer>
        )}
        {isError && error && (
          <div className="grid grid-cols-4 grid-rows-2 min-h-[60px] text-center border-0 shadow-xl border-grey-100">
            <p className="col-start-1 col-end-5">Couldn't fetch data</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisteredUsersPercentage;
