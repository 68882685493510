import api from "../../api/api";

const reportssBaseUrl = "/reports_backoffice/";

//Get reports
const getReports = async (
  options: {
    params: {
      page: number | undefined;
      created_start_date?: Date | string | null;
      created_end_date?: Date | string | null;
      user_email?: string | null;
      keyword?: string | null;
      country?: string | undefined;
      language?: string | null;
      min_words?: number | null;
      max_words?: number | null;
    };
  },
  filterUrl?: string
) => {
  if (filterUrl === undefined) {
    filterUrl = "";
  }

  const response = await api.get(`${reportssBaseUrl}${filterUrl}`, options);

  return response;
};

const reportsService = {
  getReports,
};

export default reportsService;

// import api from "../../api/api";

// const reportsUrl = "admin_reports/";
// const getCreatorsUrl = "/reports_backoffice/get_by_user_email/";
// const getCreatorUrl = "/reports_backoffice/";
// const getCreatorByEmail = "/reports_backoffice/get_by_user_email/";

// //Get reports
// const getReports = async (options: { params: { page: number } }) => {
//   const response = await api.get(`${reportsUrl}`, options);
//   return response;
// };
// //Get creators
// const getCreators = async (options: {
//   params: { page: number; user_email: string };
// }) => {
//   const response = await api.get(`${getCreatorsUrl}`, options);

//   return response;
// };

// const getReportByEmail = async (options: {
//   params: { page: number; user_email: string };
// }) => {
//   const response = await api.get(`${getCreatorByEmail}`, options);
//   return response;
// };
// //Get creator
// const getCreator = async (id: string | undefined) => {
//   const response = await api.get(`${getCreatorUrl}${id}`);
//   return response;
// };

// const reportsService = {
//   getReports,
//   getCreators,
//   getCreator,
//   getReportByEmail,
// };

// export default reportsService;
