import React, { HTMLProps, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { CSVLink } from "react-csv";
//import useAppSelector from "../hooks/useAppSelector";

import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";

import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";
import { useAppDispatch } from "../../app/hooks";
import { is } from "date-fns/locale";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  showFilters?: boolean;
  next: number | null;
  previous: number | null;
  pageCount: number | null;
  //add action type as a prop
  actionName?: any; //Create a type for this
  actionParams?: any; //Create a type for this
  isRowClickable?: boolean;
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);
  // Store the itemRank info
  addMeta({
    itemRank,
  });
  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;
  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }
  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};
export interface User {
  id: number;
  email: string;
}

function TableComponent<T extends object>({
  data,
  columns,
  showFilters = true,
  isRowClickable = false,
  next,
  previous,
  pageCount,
  actionName,
  actionParams,
}: ReactTableProps<T>) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //Handle click on user and navigate to user details page
  const handleUserClick = (user: User) => {
    navigate(`/users/${user.id}`);
  };

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [inputError, setInputError] = useState(false);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
      rowSelection,
    },

    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onRowSelectionChange: setRowSelection,
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  function getSelected(
    selected: { [key: number]: boolean },
    data: string[] | any[]
  ) {
    return Object.keys(selected).map((key) => {
      return data.find((_, index) => index === Number(key));
    });
  }
  const selected = getSelected(rowSelection, data);

  //Those variables are used to debounce the search input on "Go to page"
  let timeoutId: any;
  let keyStroke: number = 0;

  return (
    <div className="pt-4 w-[100%]">
      <div className="h-2" />
      <div className="w-full overflow-x-scroll">
        <table className="w-full border border-grey-400">
          <thead className="bg-grey-200">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      className="p-2 border border-grey-300"
                      key={header.id}
                      colSpan={header.colSpan}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: " 🔼",
                              desc: " 🔽",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {showFilters && header.column.getCanFilter() ? (
                            <div className="mt-2">
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="border border-grey-400">
            {isRowClickable
              ? table.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      key={row.id}
                      className="text-center cursor-pointer border-bodd:bg-white even:bg-grey-100 hover:bg-grey-200"
                      onClick={() => handleUserClick(row.original)}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id} className="p-2">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              : table.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      key={row.id}
                      className="text-center border-bodd:bg-white even:bg-grey-100 hover:bg-grey-200"
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id} className="p-2">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
      <div className="h-2" />
      <div className="flex items-center gap-2 mt-4">
        <button
          className={`flex justify-center justify-self-center p-2 text-white h-min bg-robinizeGreen  hover:bg-robinizeGreen-dark hover:scale-105 tracking-widest focus:outline-none shadow-md hover:shadow-sm shadow-robinizeGreen/40 dark:shadow-lg dark:shadow-robinizeGreen/80 font-semibold rounded-xl text-md  text-center transition-all ease-in-out duration-300 disabled:opacity-50`}
          onClick={() => dispatch(actionName({ ...actionParams, page: 1 }))}
          disabled={previous === null}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            fill="white"
          >
            <path d="M10.925 18.45 4.475 12l6.45-6.45 1.85 1.85-4.6 4.6 4.6 4.6Zm6.75 0L11.225 12l6.45-6.45 1.85 1.85L14.95 12l4.575 4.6Z" />
          </svg>
        </button>
        <button
          className={`flex justify-center justify-self-center p-2 text-white h-min bg-robinizeGreen  hover:bg-robinizeGreen-dark hover:scale-105 tracking-widest focus:outline-none shadow-md hover:shadow-sm shadow-robinizeGreen/40 dark:shadow-lg dark:shadow-robinizeGreen/80 font-semibold rounded-xl text-md  text-center transition-all ease-in-out duration-300 disabled:opacity-50`}
          disabled={previous === null}
          onClick={() =>
            dispatch(actionName({ ...actionParams, page: previous }))
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            fill="white"
          >
            <path d="M14 18.45 7.55 12 14 5.55l1.85 1.85-4.6 4.6 4.6 4.6Z" />
          </svg>
        </button>
        <button
          className={`flex justify-center justify-self-center p-2 text-white h-min bg-robinizeGreen  hover:bg-robinizeGreen-dark hover:scale-105 tracking-widest focus:outline-none shadow-md hover:shadow-sm shadow-robinizeGreen/40 dark:shadow-lg dark:shadow-robinizeGreen/80 font-semibold rounded-xl text-md  text-center transition-all ease-in-out duration-300 disabled:opacity-50`}
          disabled={next === null}
          onClick={() => dispatch(actionName({ ...actionParams, page: next }))}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            fill="white"
          >
            <path d="M9.4 18.45 7.55 16.6l4.6-4.6-4.6-4.6L9.4 5.55 15.85 12Z" />
          </svg>
        </button>
        <button
          className={`flex justify-center justify-self-center p-2 text-white h-min bg-robinizeGreen  hover:bg-robinizeGreen-dark hover:scale-105 tracking-widest focus:outline-none shadow-md hover:shadow-sm shadow-robinizeGreen/40 dark:shadow-lg dark:shadow-robinizeGreen/80 font-semibold rounded-xl text-md  text-center transition-all ease-in-out duration-300 disabled:opacity-50`}
          disabled={next === null}
          onClick={() =>
            dispatch(actionName({ ...actionParams, page: pageCount }))
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            fill="white"
          >
            <path d="m6.325 18.45-1.85-1.85L9.05 12 4.475 7.4l1.85-1.85 6.45 6.45Zm6.75 0-1.85-1.85 4.6-4.6-4.6-4.6 1.85-1.85 6.45 6.45Z" />
          </svg>
        </button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {next !== null ? next - 1 : previous !== null ? previous + 1 : 1} of{" "}
            {pageCount || 1}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={
              next !== null ? next - 1 : previous !== null ? previous + 1 : 1
            }
            onChange={(e) => {
              keyStroke += 1;
              clearTimeout(timeoutId);
              timeoutId = setTimeout(() => {
                if (keyStroke > 0) {
                  const page = e.target.value ? Number(e.target.value) : 1;
                  if (pageCount !== null && page > pageCount) {
                    setInputError(true);
                    return;
                  }
                  dispatch(actionName({ ...actionParams, page: page }));
                  keyStroke = 0;
                }
              }, 1500);
            }}
            className={`w-16 p-1 border rounded focus:outline-none focus:border-2  ${
              inputError
                ? "focus:border-red-500"
                : "focus:border-robinizeGreen-light"
            }`}
          />
        </span>
      </div>
      <br />
      {/* {Object.keys(rowSelection).length > 0 && (
        <div>
          <span className="mr-6">
            {Object.keys(rowSelection).length} of{" "}
            {table.getPreFilteredRowModel().rows.length} Total Rows Selected
          </span>
          <CSVLink className="p-2 border rounded" data={selected}>
            Download CSV
          </CSVLink>
        </div>
      )} */}
    </div>
  );
}

function Filter({
  column,
  table,
}: {
  column: Column<any, unknown>;
  table: Table<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  return typeof firstValue === "number" ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[0] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ""
          }`}
          className="w-24 border rounded shadow"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[1] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ""
          }`}
          className="w-24 border rounded shadow placeholder:pl-4"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + "list"}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="border rounded shadow w-36 placeholder:pl-4"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  );
}
// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
//
// export function IndeterminateCheckbox({
//   indeterminate,
//   className = "",
//   ...rest
// }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
//   const ref = React.useRef<HTMLInputElement>(null!);

//   React.useEffect(() => {
//     if (typeof indeterminate === "boolean") {
//       ref.current.indeterminate = !rest.checked && indeterminate;
//     }
//   }, [ref, indeterminate]);

//   return (
//     <input
//       type="checkbox"
//       ref={ref}
//       className={className + " cursor-pointer"}
//       {...rest}
//     />
//   );
// }

export default TableComponent;
