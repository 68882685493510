import { createSlice } from "@reduxjs/toolkit";

//State object
interface TabsState {
  selectedTab: number;
}
const initialState: TabsState = {
  selectedTab: 0,
};

export const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
});

export const { setActiveTab } = tabsSlice.actions;
export default tabsSlice.reducer;
