import { ColumnDef } from "@tanstack/react-table";

export interface UserReportInterface {
  keyword: string;
  country: string;
  language: string;
  status: string;
}
export const UserReports: ColumnDef<UserReportInterface>[] = [
  {
    accessorFn: (row) => row.keyword,
    id: "keyword",
    cell: (info) => info.getValue(),
    header: "Report keyword",
  },
  {
    accessorFn: (row) => `${row.country}`,
    id: "country",
    header: "Country",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.language}`,
    id: "language",
    header: "Language",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.status}`,
    id: "status",
    header: "Status",
    cell: (props) => {
      if (props.row.original.status === "Completed") {
        return (
          <p className="my-1 text-center">
            <span className="inline-block w-[8rem] px-4 py-2 rounded-lg bg-opacity-20 bg-robinizeGreen-light">
              <span className="font-bold text-robinizeGreen-dark">
                {props.row.original.status}
              </span>
            </span>
          </p>
        );
      } else {
        return (
          <p className="my-1 text-center">
            <span className="inline-block w-[8rem] px-4 py-2 rounded-lg bg-opacity-20 bg-robinizeOrange-light">
              <span className="font-bold text-robinizeOrange-dark">
                {props.row.original.status}
              </span>
            </span>
          </p>
        );
      }
    },
  },
];
