import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
//Toastify
// import { toast } from "react-toastify";
import { logout, reset } from "../features/auth/authSlice";
//icons
import { Logout } from "../assets/icons";

import { getMe } from "../features/auth/authSlice";

import { useGetAuthUserDetailsQuery } from "../app/services/auth/auth";

function Header() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, isError, message } = useAppSelector((state) => state.auth);
  const { user: userMe } = useAppSelector((state) => state.user);

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
  };

  //const { data: user, isError } = useGetAuthUserDetailsQuery("authUserDetails");

  isError && dispatch(logout());

  //On load dispatch getMe action
  useEffect(() => {
    if (isError) {
      //toast.error(message);
      //onLogout();
    }
    dispatch(getMe());
    // }, [isError, message, dispatch]);
  }, [isError, dispatch]);

  if (!user?.id) {
    return null;
  }

  return (
    <header
      className={`flex bg-robinizeGreen text-robonizeBlack-light h-full w-full duration-500 px-4 ml-auto items-center
      drop-shadow-[0_5px_10px_rgba(175,247,218,1)]
      `}
      style={{ gridColumn: "2" }}
    >
      <div className="flex w-full">
        <div className="inline-block text-xl font-bold text-white">
          <Link to="/">Robinize</Link>
        </div>
        {user && (
          <div className="inline-block ml-auto ">
            <Link className="pr-5 text-white" to={`users/${user?.id}`}>
              {user.name}
            </Link>
            <span className="relative inline group">
              <span className="absolute w-0 px-0 py-0 overflow-hidden text-sm font-normal whitespace-pre bg-white rounded-md -left-5 top-7 text-robinizeBlack-light drop-shadow-lg group-hover:px-2 group-hover:py-1 group-hover:-left-10 group-hover:duration-300 group-hover:w-fit ">
                Logout
              </span>
              <Logout onClick={onLogout} rotate={false} />
            </span>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
