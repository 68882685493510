import { AuthUser } from "./authSlice";
import api from "../../api/api";

export interface localStorageUser {
  access: string;
  refresh: string;
}

const authUrl = "auth/";

// Login user
const login = async (userData: AuthUser) => {
  const response = await api.post("/auth/adminlogin", userData);
  return response;
};
//Get auth/users/me
const getMe = async () => {
  const response = await api.get(`${authUrl}users/me`);
  return response;

  // Simulate an error
  //throw new Error("An error occurred");
};
// Update auth headers
const updateAuthHeaders = () => {
  //Getting user data from local storage
  let user = localStorage.getItem("user");

  if (typeof user === "string") {
    user = JSON.parse(user);
  }
  let access;

  if (user && typeof user === "object") {
    let castUser = user as localStorageUser;
    access = castUser.access;
  }
  //let access = user && (user as unknown as { access: string }).access;
  api.defaults.headers["Authorization"] = `Bearer ${access}`;
};
// Logout user
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("access");
};

const authService = {
  logout,
  login,
  getMe,
  updateAuthHeaders,
};

export default authService;
