import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import { login, reset } from "../features/auth/authSlice";
import Spinner from "../components/Spinner";
import Logo from "../assets/robinize-logo.webp";

interface UserData {
  password: string;
  email: string;
}

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isError, isLoading, isLoggedIn, message } = useAppSelector(
    (state) => state.auth
  );

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);

  useEffect(() => {
    if (isError) {
      dispatch(reset());
    }

    if (isLoggedIn) {
      navigate("/");
    }
  }, [isError, isLoggedIn, message]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "email") {
      setIsValidEmail(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));
    }

    if (name === "password") {
      setIsValidPassword(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value)
      );
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const userData: UserData = {
      email,
      password,
    };

    dispatch(login(userData));
  };

  if (isLoading) {
    return <Spinner />;
  }

  const isSubmitDisabled = !isValidEmail || !isValidPassword;

  return (
    <>
      <div className="absolute w-1/3 p-6 m-auto -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-2xl 2xl:w-1/4 top-1/2 left-1/2 ring-1 ring-grey-100">
        <img
          src={Logo}
          className="w-4/5 pt-4 pb-10 m-auto"
          alt="Company logo"
        />
        <form onSubmit={onSubmit}>
          <label
            htmlFor="input-group-1"
            className="block mb-2 text-sm font-medium text-left text-r-black dark:text-white"
          >
            E-mail:
          </label>
          <div className="relative mb-6 transition-colors duration-300 ease-in-out focus-within:text-robinizeGreen">
            <input
              type="email"
              name="email"
              value={email}
              onChange={onChange}
              id="input-group-1"
              className="bg-grey-100 border border-r-black-light text-sm rounded-lg block w-full pl-3 p-2.5 dark:border-grey-600 dark:placeholder-grey-400 focus:text-grey-500 dark:focus:ring-robinizeGreen  dark:focus:border-robinizeGreen focus:outline-none focus:border-robinizeGreen focus:ring-1 focus:ring-robinizeGreen transition ease-in-out duration-300"
              placeholder="Enter your email"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 fill-current"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
            </div>
          </div>

          <label
            htmlFor="input-group-2"
            className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white"
          >
            Password:
          </label>
          <div className="relative mb-6 transition-colors duration-300 ease-in-out focus-within:text-robinizeGreen">
            <input
              id="input-group-2"
              type="password"
              name="password"
              value={password}
              onChange={onChange}
              className="bg-grey-100 border border-grey-300 text-grey-900 text-sm rounded-lg block w-full pl-3 p-2.5 dark:border-grey-600 dark:placeholder-grey-400 focus:outline-none focus:border-robinizeGreen focus:ring-1 focus:ring-robinizeGreen transition ease-in-out duration-300"
              placeholder="Enter your password"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="fill-current w-7 h-7"
                viewBox="0 0 30 60"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14 36q-5 0-8.5-3.5T2 24q0-5 3.5-8.5T14 12q3.9 0 6.575 1.875 2.675 1.875 3.975 4.975H46v10.3h-5.3V36h-9.3v-6.85h-6.85q-1.3 3.1-3.975 4.975Q17.9 36 14 36Zm0-3q3.55 0 5.825-2.35 2.275-2.35 2.675-4.5h12.1V33h3.1v-6.85H43v-4.3H22.5q-.4-2.15-2.675-4.5T14 15q-3.75 0-6.375 2.625T5 24q0 3.75 2.625 6.375T14 33Zm0-5.6q1.45 0 2.425-.975.975-.975.975-2.425 0-1.45-.975-2.425Q15.45 20.6 14 20.6q-1.45 0-2.425.975Q10.6 22.55 10.6 24q0 1.45.975 2.425.975.975 2.425.975Zm0-3.4Z" />
              </svg>
            </div>
          </div>
          <button
            type="submit"
            className={`text-white w-full bg-robinizeOrange hover:bg-robinizeOrange-dark tracking-widest focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-md hover:shadow-sm shadow-robinizeOrange/40 dark:shadow-lg dark:shadow-robinizeOrange/80 font-semibold rounded-xl text-md px-5 py-2.5 text-center mr-2 mt-6 mb-3 transition ease-in-out duration-300 ${
              isSubmitDisabled ? "opacity-60 cursor-not-allowed" : ""
            }`}
            disabled={isSubmitDisabled}
          >
            LOGIN
          </button>
        </form>
      </div>
    </>
  );
}

export default Login;
