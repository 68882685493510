import UserForm from "../Form/FormStructure/UserForm";
import { UserType } from "../../features/user/userSlice";
import Loading from "../Loader";
import { convertTimestamp } from "../../common/utils";

const UserInfo = (props: {
  id: string | undefined;
  data: UserType;
  loading: boolean;
}) => {
  const { id, data, loading } = props;

  return loading ? (
    <Loading loading={true} />
  ) : (
    //flex container with two columns
    <div className="flex flex-row justify-center h-full">
      {/* first column */}
      <div className="w-1/2">
        <div className="flex flex-row items-center justify-center mb-6">
          <img className="w-[20rem]" src={data.avatar} alt="user" />
        </div>
        <p className="text-center">Registered at:</p>
        <p className="mb-6 font-bold text-center">
          {convertTimestamp(data.date_registered)}
        </p>
        <p className="text-center">Subscription:</p>
        <p className="mb-6 font-bold text-center">{data.user_plan.name}</p>
        <p className="text-center">Reports remaining</p>
        <p className="font-bold text-center">{data.remaining_reports}</p>
      </div>
      {/* second column */}
      <div className="w-1/2">
        <UserForm id={id} data={data} />
      </div>
    </div>
  );
};
export default UserInfo;
