import { ColumnDef } from "@tanstack/react-table";
import { Download, LinkToPage } from "../../../../assets/icons";

export interface UserInvoiceInterface {
  created: string;
  status: string;
  billing_reason: string;
  collection_method: string;
  amount_due: string;
  amount_paid: string;
  currency: string;
  customer_email: string;
  hosted_invoice_url: string;
  invoice_pdf: string;
}

export const UserInvoices: ColumnDef<UserInvoiceInterface>[] = [
  {
    accessorFn: (row) => row.created,
    id: "created",
    cell: (info) => info.getValue(),
    header: "Created at",
  },
  {
    accessorFn: (row) => `${row.status}`,
    id: "status",
    header: "Status",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.billing_reason}`,
    id: "billing_reason",
    header: "Billing reason",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.collection_method}`,
    id: "collection_method",
    header: "Collection method",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.amount_due}`,
    id: "amount_due",
    header: "Amount due",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.amount_paid}`,
    id: "amount_paid",
    header: "Amount paid",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.currency}`,
    id: "currency",
    header: "Currency",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.customer_email}`,
    id: "customer_email",
    header: "Customer email",
    cell: (info) => info.getValue(),
  },
  {
    id: "actions",
    header: "Actions",
    cell: (props) => (
      <div className="flex flex-col items-center">
        <a
          className="my-2"
          href={props.row.original.hosted_invoice_url}
          target="_blank"
          rel="noreferrer"
        >
          <LinkToPage />
        </a>
        <a
          className="my-2"
          href={props.row.original.invoice_pdf}
          target="_blank"
          rel="noreferrer"
        >
          <Download />
        </a>
      </div>
    ),
  },
];
