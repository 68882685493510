import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/reducers";
import reportsService from "./reportsService";

export interface ReportsState {
  reports: {
    count: number;
    links: {
      next: number | null;
      previous: number | null;
    };
    results: any[];
  };
  pageNumber: number;
  pageCount: number;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  message: string | any;
}
const initialState: ReportsState = {
  reports: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    results: [],
  },
  pageNumber: 1,
  pageCount: 0,
  isLoading: false,
  isFetching: false,
  isError: false,
  message: "",
};

//Get users reports
// export const getReports = createAsyncThunk(
//   "reports/getReports",
//   async (payload: { page: number }, thunkAPI) => {
//     const { page } = payload;
//     try {
//       const response = await reportsService.getReports({
//         params: {
//           page: page,
//         },
//       });
//       return response.data;
//     } catch (error: any) {
//       const message =
//         (error.response.data &&
//           error.response.data.data &&
//           error.response.data.data.message) ||
//         error.response.data.detail ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );
//////////////////////
export const getReports = createAsyncThunk(
  "reports/getReports",
  async (
    payload: {
      filterUrl?: string;
      page: number | undefined;
      created_start_date?: Date | string | null;
      created_end_date?: Date | string | null;
      user_email?: string | null;
      keyword?: string | null;
      country?: string | undefined;
      language?: string | null;
      min_words?: number | null;
      max_words?: number | null;
    },
    thunkAPI
  ) => {
    const {
      filterUrl,
      page,
      created_start_date,
      created_end_date,
      user_email,
      keyword,
      country,
      language,
      min_words,
      max_words,
    } = payload;

    try {
      const response = await reportsService.getReports(
        {
          params: {
            page: page,
            created_start_date: created_start_date,
            created_end_date: created_end_date,
            user_email: user_email,
            keyword: keyword,
            country: country,
            language: language,
            min_words: min_words,
            max_words: max_words,
          },
        },
        filterUrl
      );
      return response.data;
    } catch (error: any) {
      const message =
        (error.response.data &&
          error.response.data.data &&
          error.response.data.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Create store slice
export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //Get reports
    builder.addCase(getReports.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getReports.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.reports = action.payload;
      state.reports.count = Math.ceil(action.payload.count / 10);
    });
    builder.addCase(getReports.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
  },
});

export const { reset } = reportsSlice.actions;

export default reportsSlice.reducer;
