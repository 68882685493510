import { Link } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { UserType } from "../../../../features/user/userSlice";
import { convertTimestamp } from "../../../../common/utils";

export const UsersColumns: ColumnDef<UserType>[] = [
  {
    accessorFn: (row) => `${row.id}`,
    id: "id",
    header: "ID",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => `${row.date_registered}`,
    id: "date_registered",
    header: "Registered At",
    cell: (info) => convertTimestamp(info.getValue()),
  },
  {
    accessorFn: (row) => `${row.name}`,
    id: "name",
    header: "Name",
    cell: (info) => info.getValue(),
    // cell: (props) => (
    //   <Link to={`/users/${props.row.original.id}`}>
    //     {props.row.original.name}
    //   </Link>
    // ),
  },
  {
    accessorFn: (row) => row.email,
    id: "email",
    cell: (info) => info.getValue(),
    header: "Email",
  },
  {
    accessorFn: (row) => row.user_plan?.description,
    id: "user_plan.description",
    cell: (info) => info.getValue(),
    header: "Plan",
  },
  // {
  //   accessorFn: (row) => `${row.generatedReports}`,
  //   id: "generatedReports",
  //   header: "Generated reports",
  //   cell: (info) => info.getValue(),
  // },
  {
    accessorFn: (row) => `${row.remaining_reports}`,
    id: "remaining_reports",
    header: "Remaining reports",
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => `${row.is_business}`,
    id: "is_business",
    header: "Business",
    cell: (info) => (info.getValue() === "true" ? "Yes" : "No"),
  },
  {
    accessorFn: (row) => `${row.is_affiliate}`,
    id: "is_affiliate",
    header: "Affiliate",
    cell: (info) => (info.getValue() === "true" ? "Yes" : "No"),
  },
  {
    accessorFn: (row) => `${row.is_active}`,
    id: "is_active",
    header: "Active",
    cell: (props) => (
      <span className="block py-3">
        {props.row.original.is_active === true ? "Yes" : "No"}
      </span>
    ),
  },
];
