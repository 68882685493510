import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
//Tabs
import Tabs from "../components/Tabs/Tabs";
import Tab from "../components/Tabs/Tab";
//Table
import Table from "../components/Table/Table";
//User Form
import UserProfile from "../components/User/UserProfile";
////Actions
import {
  getUser,
  getUserArticles,
  getUserReports,
  getCustomerInvoices,
  getCustomerSubscriptions,
  resetMessage,
} from "../features/user/userSlice";
//import UserReport columns and interface
import {
  UserReports,
  UserReportInterface,
} from "../components/Table/Columns/UserColumns/UserReports";
//import UserArticles columns and interface
import {
  UserArticles,
  UserArticleInterface,
} from "../components/Table/Columns/UserColumns/UserArticles";
//import UserInvoices columns and interface
import {
  UserInvoices,
  UserInvoiceInterface,
} from "../components/Table/Columns/UserColumns/UserInvoices";
//import UserSubscriptions columns and interface
import {
  UserSubscriptions,
  UserSubscriptionInterface,
} from "../components/Table/Columns/UserColumns/UserSubscriptions";
//Import coulumn interface
import { ColumnDef } from "@tanstack/react-table";
//setActiveTab action from tabsSlice
import { setActiveTab } from "../features/tabs/tabsSlice";
import Loader from "../components/Loader";

const User = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams();

  let userId: number;

  if (id) {
    userId = parseInt(id);
  }

  const {
    user,
    userReports,
    userArticles,
    customerInvoices,
    customerSubscriptions,
    isLoading,
    isError,
    isSuccess,
    message,
  } = useAppSelector((state) => state.user);

  const { selectedTab } = useAppSelector((state) => state.tabs);

  useEffect(() => {
    if (userId !== user.id) {
      dispatch(getUser(id));
    }
    if (selectedTab !== 0) {
      dispatch(setActiveTab(0));
    }

    return () => {
      dispatch(resetMessage());
    };
  }, [isError, isSuccess, message, dispatch]);

  //userReportsColumns
  const userReportsColumns = React.useMemo<ColumnDef<UserReportInterface>[]>(
    () => UserReports,
    []
  );
  //userArticlesColumns
  const userArticlesColumns = React.useMemo<ColumnDef<UserArticleInterface>[]>(
    () => UserArticles,
    []
  );
  //userInvoicesColumns
  const userInvoicesColumns = React.useMemo<ColumnDef<UserInvoiceInterface>[]>(
    () => UserInvoices,
    []
  );
  //userSubscriptionsColumns
  const userSubscriptionsColumns = React.useMemo<
    ColumnDef<UserSubscriptionInterface>[]
  >(() => UserSubscriptions, []);

  const userTabs = [
    {
      title: "Contact info",
      content: <UserProfile id={id} data={user} loading={isLoading} />,
    },
    {
      title: "Reports",
      onClick: () => dispatch(getUserReports({ id: id, page: 1 })),
      content: (
        <Table
          data={userReports.results}
          columns={userReportsColumns}
          showFilters={true}
          actionParams={{ id: id }}
          actionName={getUserReports}
          next={userReports.links.next}
          previous={userReports.links.previous}
          pageCount={userReports.count}
        />
      ),
    },
    {
      title: "Articles",
      onClick: () => dispatch(getUserArticles({ id: id, page: 1 })),
      content: (
        <Table
          data={userArticles.results}
          columns={userArticlesColumns}
          showFilters={true}
          actionParams={{ id: id }}
          actionName={getUserArticles}
          next={userArticles.links.next}
          previous={userArticles.links.previous}
          pageCount={userArticles.count}
        />
      ),
    },
    {
      title: "Invoices",
      onClick: () => dispatch(getCustomerInvoices({ id: id, page: 1 })),
      content: (
        <Table
          data={customerInvoices.results}
          columns={userInvoicesColumns}
          showFilters={true}
          actionParams={{ id: id }}
          actionName={getCustomerInvoices}
          next={customerInvoices.links.next}
          previous={customerInvoices.links.previous}
          pageCount={customerInvoices.count}
        />
      ),
    },
    {
      title: "Subscriptions",
      onClick: () => dispatch(getCustomerSubscriptions({ id: id, page: 1 })),
      content: (
        <Table
          data={customerSubscriptions.results}
          columns={userSubscriptionsColumns}
          showFilters={true}
          actionParams={{ id: id }}
          actionName={getCustomerSubscriptions}
          next={customerSubscriptions.links.next}
          previous={customerSubscriptions.links.previous}
          pageCount={customerSubscriptions.count}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs>
        {userTabs.map((tab, index) => (
          <Tab
            key={index}
            title={tab.title}
            // @ts-ignore
            onClick={tab.onClick}
          >
            {isLoading ? <Loader loading={true} /> : tab.content}
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

export default User;
